.block-header {
    background: #D2E3F5 !important;
}

.platform-888 .block-header {
    /*line-height: 5vw;*/
    background: #D2E3F5 !important;
}

.platform-999 .block-header {
    background: #FFBBBB !important;
    color: #21397B;
}

.platform-999.GREEN .block-header {
    background: #CCF1BC !important;
}

.draw-header-bg {
    background: #d2e3f578 !important;
}

.border-b-1 {
    border-bottom: 1px solid #80808038;
}

.social-top-MHSB168 {
    height: 30px;
    width: 40px;
}

/*
    Different Style of Platform
*/

/* Top header */
.header-BCT168 {
    background: linear-gradient(180deg, #CAE0FF, #fff) !important;
    color: #21397B !important;
}

.header-888 {
    background: linear-gradient(180deg, #CAD7FF, #ffffff) !important;
    color: #21397B !important;
}

.header-999 {
    background: linear-gradient(90deg, rgba(255, 0, 0, 0.5) 0%, rgba(196, 48, 34, 1) 100%) !important;
    color: #21397B !important;
}

.header-999.GREEN {
    background: linear-gradient(90deg, rgba(137,209,105,1) 50%, rgba(50,160,1,1) 100%) !important;
    color: #21397B !important;
}

.header-KPW {
    background: #014df8 !important;
    color: white !important;
}

.header-MHSB168 {
    background: linear-gradient(180deg, #CAE0FF, #fff) !important;
    color: #21397B !important;
}

.header-MHSB168 > label {
    color: black !important;
}

/* Navation menu */
.new-keno-menu-BCT168 ul {
    color: #1a006b !important;
    background: #567DCA !important;
}


.new-keno-menu-888 ul {
    color: #1a006b !important;
    background: #567DCA !important;
}

.new-keno-menu-999 ul {
    background: #DA0000 !important;
}

.new-keno-menu-999.GREEN ul {
    background: #32A001 !important;
}

.platform-type-P99 .new-keno-menu-888 ul {
    background: #239600 !important;
}

.menu-BCT168 ul li a.active, .menu-BCT168 ul li a:hover {
    color: #1a006b !important;
    background: #567DCA !important;
}

.menu-888 ul li a.active, .menu-888 ul li a:hover {
    color: #1a006b !important;
    background: #567DCA !important;
}

.platform-type-P99 .menu-888 ul li a.active, .platform-type-P99 .menu-888 ul li a:hover {
    background: #239600 !important;
}

.new-keno-menu-KPW ul {
    color: #1a006b !important;
    background: #f1b417 !important
}

.menu-KPW ul li a.active, .menu-KPW ul li a:hover {
    color: #1a006b !important;
    background: #f1b417 !important
}

.new-keno-menu-MHSB168 ul {
    color: white !important;
    background: #293780 !important;
}

.new-keno-menu-MHSB168 ul li a {
    color: white !important;
    text-transform: uppercase;
}

.new-keno-menu-MHSB168 ul li a.active, .new-keno-menu-MHSB168 ul li a:hover {
    color: #fee507 !important;
    text-transform: uppercase;
}

.menu-MHSB168 ul li a.active, .menu-MHSB168 ul li a:hover {
    color: white !important;
    background: #293780 !important;
}


/* ***** Lotto 5 ***** */

.lotto5-header-KPW {
    background: #014df8 !important;
    color: white !important
}

.lotto5-header-KPW > p {
    color: white !important
}

.lotto5-header-BCT168 {
    background: #3E579D !important;
    color: white !important
}

.lotto5-header-888 {
    background: #3E579D !important;
    color: #fff !important
}

.lotto5-header-999 {
    background: #FFBBBB !important;
    color: #21397B !important
}

.lotto5-header-999.GREEN {
    background: #CCF1BC !important;
}

.lotto5-header-draw-999 {
    background: #A80000 !important;
    color: #fff !important
}

.lotto5-header-draw-999.GREEN {
    background: #28640E !important;
}

.platform-type-P99 .lotto5-header-888 {
    background: #106B29 !important;
}

.lotto5-header-888 p {
    color: #fff !important
}

.lotto5-header-999 p {
    color: #fff !important
}

.lotto5-header-BCT168 > p {
    color: white !important
}

.lotto5-header-MHSB168 {
    background: #3E579D !important;
    color: white !important
}

.lotto5-header-MHSB168 > p {
    color: white !important
}

/* Loading */
.lotto5-mix .loading-gif-KPW {
    background: #3E579D !important;
}

.loading-gif-KPW {
    background: linear-gradient(to bottom, #d50000 7%, #ff1b1b 35%, #d50000 76%);
}


.loading-gif-BCT168 {
    background: #3E579D !important;
}

.loading-gif-888 {
    background: #3E579D !important;
}

.loading-gif-999 {
    background: #A80000 !important;
}

.loading-gif-999.GREEN {
    background: #28640E !important;
}

.platform-type-P99 .loading-gif-888 {
    background: #106B29 !important;
}

.loading-gif-MHSB168 {
    background: #3E579D !important;
}

/* Bet type */
.lotto5 .box-bet-KPW-0 .type-rage, .type-rage, .lotto5 .box-bet-KPW-1 .type-rage, .lotto5 .box-bet-KPW-UNDER .UNDER {
    background-color: #034df8 !important;
    color: #fff !important;
}


.lotto5 .box-bet-KPW-2 .type-rage {
    background-color: #00c853 !important;
    color: #fff !important;
}

.lotto5 .box-bet-KPW-3 .type-rage, .lotto5 .box-bet-KPW-4 .type-rage, .lotto5 .box-bet-KPW-OVER .OVER {
    background-color: #ff0017 !important;
    color: #fff !important;
}

.lotto5 .box-bet-BCT168-UNDER .UNDER, .lotto5 .box-bet-888-UNDER .UNDER {
    background: linear-gradient(to bottom, #3D00F4, #6532E7, #3D00F4);
    color: #fff !important;
}


.lotto5 .box-bet-BCT168-OVER .OVER, .lotto5 .box-bet-888-OVER .OVER {
    background: linear-gradient(to bottom, #990000 0%, #df1e1e 50%, #990000);
    color: #fff !important;
}

.lotto5 .box-bet-BCT168-ODD .ODD {
    background: linear-gradient(to bottom, #F16522 10%, #f1a522 50%, #F16522);
    color: #fff !important;
}

.text-ODD {
    color: #F16522 !important;
}

.text-EVEN {
    color: #FAAF40 !important;
}

.lotto5 .box-bet-BCT168-EVEN .EVEN {
    background: linear-gradient(to bottom, #FAAF40 10%, #f1d922 50%, #FAAF40);
    color: #fff !important;
}

.lotto5 .box-bet-KPW-ODD .ODD {
    background-color: #F16522 !important;
    color: #fff !important;
}

.lotto5 .box-bet-KPW-EVEN .EVEN {
    background-color: #FAAF40 !important;
    color: #fff !important;
}

.lotto5 .box-bet-MHSB168-ODD .ODD {
    background: linear-gradient(to bottom, #F16522 10%, #f1a522 50%, #F16522);
    color: #fff !important;
}

.lotto5 .box-bet-MHSB168-EVEN .EVEN {
    background: linear-gradient(to bottom, #FAAF40 10%, #f1d922 50%, #FAAF40);
    color: #fff !important;
}


.lotto5 .box-bet-BCT168-0 .type-rage, .type-rage, .lotto5 .box-bet-BCT168-1 .type-rage {
    background-color: #fff !important;
    color: #CB382C !important;
}

.lotto5 .box-bet-BCT168-3 .type-rage, .lotto5 .box-bet-BCT168-4 .type-rage {
    background-color: #fff !important;
    color: #CB382C !important;
}


.lotto5 .box-bet-MHSB168-UNDER .UNDER {
    background: linear-gradient(to bottom, #3D00F4, #6532E7, #3D00F4);
    color: #fff !important;
}

.lotto5 .box-bet-MHSB168-OVER .OVER {
    background: linear-gradient(to bottom, #990000 0%, #df1e1e 50%, #990000);
    color: #fff !important;
}


.lotto5 .box-bet-MHSB168-0 .type-rage, .type-rage, .lotto5 .box-bet-MHSB168-1 .type-rage, .lotto5 .box-bet-MHSB168-3 .type-rage, .lotto5 .box-bet-MHSB168-4 .type-rage {
    background-color: #fff !important;
    color: #FA0000 !important;
}


/* bet value */

.bet-color-KPW {
    background: yellow !important;
}

.bet-color-BCT168, .bet-color-MHSB168, .bet-color-888, .bet-color-999 {
    background: #fff !important;
    border-top: 1px solid;
}

.lotto5 .box-bet-0 .type-rage, .lotto5 .box-bet-1 .type-rage, .lotto5 .statistic-ball-KPW-TWO, .lotto5 .statistic-ball-KPW-ONE, .lotto5 .box-bet-UNDER .UNDER {
    background-color: #2962ff !important;
    color: #fff !important;
}

.lotto5 .box-bet-2 .type-rage, .lotto5 .statistic-ball-KPW-THREE {
    background-color: #00c853 !important;
    color: #fff !important;
}

.lotto5 .box-bet-3 .type-rage, .lotto5 .box-bet-4 .type-rage, .lotto5 .statistic-ball-KPW-FOUR, .lotto5 .statistic-ball-KPW-FIVE, .lotto5 .box-bet-OVER .OVER {
    background-color: #dd2233 !important;
    color: #fff !important;

}

/*last game result 1*/
.left-bet-type-KPW-ONE, .left-bet-type-KPW-TWO {
    background-color: #2962ff !important;
}

/*last game result 3*/
.left-bet-type-KPW-THREE {
    background-color: #00c853 !important;
}

/*last game result 4*/
.left-bet-type-KPW-FOUR, .left-bet-type-KPW-FIVE {
    background-color: #F00F2B !important;
}

/* Render number */
.show-logo-render-KPW {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    display: block;
    background: #034df8 !important;
}

.show-logo-render-KPW img {
    width: 135px;
    height: 132px;
    margin: 18px auto;
}

.show-logo-render-BCT168 {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    display: block;
    border-top: 0.06vw solid #567DCA !important;
    /*background: #0501f1 !important;*/
}

.show-logo-render-BCT168 img {
    width: 135px;
    height: 132px;
    margin: 18px auto;
}

.show-logo-render-MHSB168 {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    display: block;
    background: #0501f1 !important;
}

.show-logo-render-MHSB168 img {
    width: 135px;
    height: 132px;
    margin: 18px auto;
}

/* ***** Lotto 6-36 ***** */
.header-lotto6-BCT168 {
    background: #0900a7 !important;
    color: white !important;
}

.header-lotto6-KPW {
    background: #014df8 !important;
    color: white !important;
}

.header-lotto6-MHSB168 {
    background: #0900a7 !important;
    color: white !important;
}

/* Header blog right */
.header-right-KPW {
    background: #014df8 !important;
}

.header-right-KPW > span {
    color: white !important;
}

/* Betting logo */
.bet-bg-logo-BCT168 {
    background-color: #3200CB !important;
}

.bet-bg-logo-MHSB168 {
    background-color: #3200CB !important;
}

.bet-bg-logo-KPW {
    background-color: #034df8 !important;
}

.card-bg-color-UNDER-BCT168 {
    background: #3200CB !important;
}

.card-bg-color-UNDER-KPW {
    background: #034df8 !important;
}

.bg-count-down-KPW {
    background: #df0404 !important
}

.bg-count-down-BCT168 {
    background: #df0404 !important
}

/* Bet value */
.slot-value-card-BCT168 {
    background: linear-gradient(-45deg, #ffff00, #ffff00, #f9cc2f);
}

/* .slot-value-card-BCT168>p {
    color:black !important;
} */

.slot-value-card-MHSB168 {
    background: linear-gradient(-45deg, #ffff00, #ffff00, #f9cc2f);
}

.slot-value-card-MHSB168 > p {
    color: black !important;
}

.slot-value-card-KPW {
    background: #034df8 !important;
}

.slot-value-card-KPW > p {
    color: white !important;
}

.slot-value-card-KPW > .bet-value {
    color: #fee507 !important;
}

.bg-blue-l {
    background: #034df8 !important;
}

.main-color {
    color: #567DCA !important;
}


