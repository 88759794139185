.main-footer {
    margin-top: 200px;
    font-family: BattambangRegular, RobotoRegular;
    background: transparent;
}

.footer {
    width: 100%;
    height: auto;
    text-align: center;
    margin: 0 auto;
    background: none;
    display: block;
    /* border-top: 4px solid #888; */
}

.copyright, .info-footer {
    max-width: 100%;
    width: 100%;
    background: #dce1e5;
    border-top: 1px solid #879ed4;
    position: fixed;
    bottom: 0;
    text-align: right;
    line-height: 25px;
    height: 25px;
    font-size: 12px;
    color: #4778b3;
    font-weight: bold;
    padding: 0px 15px;
}

.bg-footer-999 {
    background: #DA0000 !important;
    color: white;
    border-top: 0;
}

.bg-footer-999.GREEN {
    background: #32A001 !important;
}