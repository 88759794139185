.nav {
    min-width: 1200px;
    background: #D2E3F5;
}

.nav-888 {
    min-width: 1200px;
    background: #D2E3F5;
}

.nav-999 {
    min-width: 1200px;
    background: #FFBBBB;
}

.nav-999.GREEN {
    background: #CCF1BC;
}

.menu ul li {
    padding-top: 10px;
    padding-bottom: 10px;
}

.menu ul li a {
    /* padding: 5px 15px 4px 15px; */
    padding: 8.5px 15px;
    font-size: 14px;
    font-family: BattambangRegular, RobotoRegular;
    color: #293780;
}

.menu ul li a:hover {
    color: #fff !important;
    background: #567DCA;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.menu ul li a.active {
    color: #fff !important;
    background: #567DCA;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.nav-999 .menu ul li a.active, .nav-999 .menu ul li a:hover {
    background: #DA0000;
}

.nav-999.GREEN .menu ul li a.active, .nav-999.GREEN .menu ul li a:hover {
    background: #32A001;
}
