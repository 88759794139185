.header {
    display: inline-flex !important;
    margin-bottom: 0;
    background: linear-gradient(-45deg, #060071, #02a4df, #060071);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    /* color: #fff; */
}

.header-lotto6 {
    margin-bottom: 0;
    background: linear-gradient(-45deg, #060071, #02a4df, #060071);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    /* color: #fff; */
}


.platform-888 .header {
    display: inline-flex !important;
    margin-bottom: 0;
    background: #3E579D;
    color: white;
}

.platform-type-P99 .header {
    background: #106B29;
}

.lotto6-mix .header-lotto6 {
    margin-bottom: 0;
    background: red;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    /* color: #fff; */
}

.lotto6-mix.bet-mix .header-lotto6 {
    padding: 1.2vw !important;
}

/*.lotto6-mix .header-lotto6 span {*/
/*    line-height: 50px !important;*/
/*}*/

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.h-120 {
    min-width: 1200px;
    padding: 10px 0;
}

.logo {
    width: 132px;
    display: inline-block;
}

.logo img {
    height: 80px;
    max-width: 80px;
    padding-top: 0;
    display: block;
    margin-left: 15px;
}

#lotto90 .logo img {
    max-width: 95px;
    height: 95px;
    margin-left: 5px;
}

.header-social {
    padding-left: 7px;
}

.header-right {
    float: right;
    width: auto;
    margin-top: -38px;
    margin-bottom: auto;
}

.header-topbar {
    width: calc(100% - 147px);
    max-width: calc(100% - 147px);
}

.header-topbar-content {
    height: 40px;
}

.left-top-bar {
    width: 50%;
    float: left;
    text-align: left;
}

.left-top-bar label {
    line-height: 35px;
    /* font-size: 14px; */
    /* color: #fff; */
    font-family: BattambangRegular, RobotoRegular;
}


.right-top-bar {
    width: 50%;
    float: right;
    text-align: right;
}

.auto-print {
    display: inline-block;
    line-height: 35px;
    margin-right: 15px;
    font-weight: 500;
}

.auto-print label {
    font-size: 12px;
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
}

.marquee {
    width: calc(100% - 200px);
    font-family: BattambangRegular, RobotoRegular;
    color: #224;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #D2E3F5;
    border-radius: 13px;
}

.marquee p {
    font-family: BattambangRegular, RobotoRegular;
    color: #224;
    display: inline-block;
    font-size: 13px;
    padding-left: 100%;
    animation: marquee 20s linear infinite;
    line-height: 35px;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.account {
    margin-right: 15px;
}

.account img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 3px solid #f2b036;
}

/* Style The Dropdown Button */
.dropbtn {
    /* margin-top: 5px; */
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    visibility: hidden;
    position: absolute;
    margin-top: 5px;
    background: rgba(100, 100, 255, 0.3);
    transition: all 0.5s linear;
    opacity: 0;
    z-index: 1;
    padding: 5px;
    margin-left: -5px;
    border-radius: 5px;
}

/* Links inside the dropdown */
.dropbtn-item {
    color: black;
    text-decoration: none;
    display: block;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
}


.new-keno-menu {
    margin-top: 0;
}

.new-keno-menu ul {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #6bd3ff;
    text-align: left;
    border-radius: 0;
}

.new-keno-menu ul li {
    display: inline-block;
    list-style-type: none;
    /*position: relative;*/
    z-index: 10;
    padding-bottom: 8px;
    padding-top: 8px;
}

.new-keno-menu ul li a {
    display: block;
    padding: 1px 20px;
    font-size: 15px !important;
    text-decoration: none;
    color: #fff;
    line-height: 30px;
    text-align: center;
    font-family: BattambangRegular, RobotoRegular;
    text-transform: uppercase;
}

.new-keno-menu ul li a.active, .new-keno-menu ul li a:hover {
    color: #FEDC00;
    text-transform: uppercase;
}

.nav {
    width: 100%;
    height: auto;
    text-align: center;
    margin: 0 auto;
}

.menu ul {
    width: 100%;
    height: auto;
    text-align: center;
    margin: 0 auto;
    display: flex;
}

.menu ul li {
    display: inline-block;
    /* padding: 15px 0; */
    /* flex: 1; */
}

.menu ul li a {
    /* padding: 15px 15px; */
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
}

.bet-mix .header-lotto5 {
    background-color: #3E579D !important;
}

.bet-mix .header-lotto12 {
    color: black !important;
    background-color: yellow !important;
    line-height: 50px;
}

.header-lotto12 .header-label {
    color: black !important;
}

.bet-mix .header-lotto12 span {
    line-height: 50px;
}

.bet-mix .header-div-title > span {
    /*line-height: 50px;*/
    font-size: 2vw !important;
}

/*platform-999*/
.platform-999 .header {
    background: #A80000;
}

.platform-999.GREEN .header {
    background: #28640E;
}

.header-999 {
    display: inline-flex !important;
    margin-bottom: 0;
    background: linear-gradient(90deg, rgba(255, 0, 0, 0.5) 0%, rgba(196, 48, 34, 1) 100%);
    color: white;
}

.image-header {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
}

.platform-999 .right-top-bar {
    margin-right: 15px;
}

.h-header {
    height: 90px;
}

.logo-999 {
    width: 100px;
    display: inline-block;
    min-width: 200px;
}

.logo-999 img {
    height: 80px;
    max-width: 80px;
    padding-top: 0;
    display: block;
    margin-left: 12px;
    z-index: 1;
}

.label-999 {
    font-family: Arial, serif !important;
    position: absolute;
    background: #FFBBBB;
    left: 76px;
    border-radius: 10px;
    color: #21397B;
    font-size: 20px;
    font-weight: 700;
    padding-right: 1rem !important;
}

.label-999.GREEN {
    background: #32A00126;
    color: #245B21;
    border: 1px solid #32A00126
}

.welcome-header {
    z-index: 1;
    font-size: 34px;
    font-family: KhmerOSKoulen, serif !important;
    color: #FA0000;
    text-shadow: rgb(255, 255, 255) 3px 0 0, rgb(255, 255, 255) 2.83487px 0.981584px 0, rgb(255, 255, 255) 2.35766px 1.85511px 0, rgb(255, 255, 255) 1.62091px 2.52441px 0, rgb(255, 255, 255) 0.705713px 2.91581px 0, rgb(255, 255, 255) -0.287171px 2.98622px 0, rgb(255, 255, 255) -1.24844px 2.72789px 0, rgb(255, 255, 255) -2.07227px 2.16926px 0, rgb(255, 255, 255) -2.66798px 1.37182px 0, rgb(255, 255, 255) -2.96998px 0.42336px 0, rgb(255, 255, 255) -2.94502px -0.571704px 0, rgb(255, 255, 255) -2.59586px -1.50383px 0, rgb(255, 255, 255) -1.96093px -2.27041px 0, rgb(255, 255, 255) -1.11013px -2.78704px 0, rgb(255, 255, 255) -0.137119px -2.99686px 0, rgb(255, 255, 255) 0.850987px -2.87677px 0, rgb(255, 255, 255) 1.74541px -2.43999px 0, rgb(255, 255, 255) 2.44769px -1.73459px 0, rgb(255, 255, 255) 2.88051px -0.838247px 0;
}

.top-right-999 {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    min-width: 200px;
}

.top-right-999 .img-rounded {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
}

.logout-999 .btn-logout {
    width: 120px;
    line-height: 34px;
    font-size: 15px;
}

.logout-999 .bg-999 {
    background: #F87D00;
}

.logout-999 .bg-999.GREEN {
    background: #FA0000;
}