.bet-pricing-card {
    height: 311.2px;
    max-height: 312px;
    border: 1px solid #000;
    cursor: pointer;
}

.slot-prefix-card {
    height: 65%;
    background-color: #e7e8ea;
}

.slot-prefix-card > h1 {
    line-height: 205px;
    font-size: 8em;
    color: #db0b06;
    font-family: BattambangRegular, RobotoRegular;
}


.card-bg-color-UNDER {
    background: #3200cb !important;
}

.card-bg-color-OVER {
    background: #df0202 !important;
}


.card-bg-color-UNDER > h1, .card-bg-color-OVER > h1 {
    color: white;
    font-size: 4em !important;
}

.slot-value-card {
    height: 35%;
    background: linear-gradient(-45deg, #54b4fe, #54d1ff, #5ac3ff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    color: #fff;
    border-top: 2px solid #000;
}

.range-number {
    line-height: 3em;
    font-weight: 800;
    color: #000;
    font-size: 1.5em;
}

.bet-value {
    margin-top: -33px;
    font-size: 3em;
    color: #2621a1;
    font-weight: 800;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.OU-UNDER {
    transition: all 5s linear;
    animation: box_animation_range 5s;
    background: #3200cb;
}

@keyframes box_animation_under_win {
    from {
        background: linear-gradient(to bottom, #ac4747 0%, #990000 50%, #613e3e 100%);
        opacity: 1;
    }
    to {
        background: linear-gradient(to bottom, #1f0000 0%, #380101 50%, #2a0000 100%);
        opacity: 0.8;
    }
}

.OU-OVER {
    transition: all 5s linear;
    animation: box_animation_range 5s;
    background: #db0b06;
}

@keyframes box_animation_over_win {
    from {
        background: linear-gradient(to bottom, #343270 0%, #1f1d9b 50%, #202050 100%);
        opacity: 1;
    }
    to {
        background: linear-gradient(to bottom, #38369b 0%, #020077 50%, #282869 100%);
        opacity: 0.8;
    }
}

.OE-EVEN {
    transition: all 5s linear;
    animation: box_animation_range 5s;
    background: #fff;
}

@keyframes box_animation_even_win {
    from {
        background: linear-gradient(to bottom, #d57346 0%, #F16522 50%, #e74c04 100%);
        opacity: 1;
    }
    to {
        background: linear-gradient(to bottom, #da8f6b 0%, #c9531a 50%, #F16522 100%);
        opacity: 0.8;
    }
}

.OE-ODD {
    transition: all 5s linear;
    animation: box_animation_range 5s;
    background: #fff;
}


@keyframes box_animation_odd_win {
    from {
        background: linear-gradient(to bottom, #FAAF40 0%, #fa9600 50%, #cc7f08 100%);
        opacity: 1;
    }
    to {
        background: linear-gradient(to bottom, #c28639 0%, #d78805 50%, #FAAF40 100%);
        opacity: 0.8;
    }
}

.win-ONE, .win-ONE > h1, .win-TWO, .win-TWO > h1,
.win-THREE, .win-THREE > h1, .win-FOUR, .win-FOUR > h1,
.win-FIVE, .win-FIVE > h1, .win-SIX, .win-SIX > h1,
.win-TWO_THREE, .win-TWO_THREE > h1,
.win-THREE_FOUR, .win-THREE_FOUR > h1,
.win-FOUR_FIVE, .win-FOUR_FIVE > h1 {
    transition: all 5s linear;
    animation: box_animation_range 5s;
    background: #ffffff;
}

.win-bg-animate {
    transition: all 1s linear;
    animation: box_animation_range_v2 1s;
    background: #f3bc37;
}

.win-animate-border {
    transition: all 3s linear;
    animation: border_animation_range 3s;
    opacity: 1;
}


.win36-ONE, .win36-ONE > h1, .win36-TWO, .win36-TWO > h1,
.win36-THREE, .win36-THREE > h1, .win36-FOUR, .win36-FOUR > h1,
.win36-FIVE, .win36-FIVE > h1, .win36-SIX, .win36-SIX > h1,
.win36-TWO_THREE, .win36-TWO_THREE > h1,
.win36-THREE_FOUR, .win36-THREE_FOUR > h1,
.win36-FOUR_FIVE, .win36-FOUR_FIVE > h1 {
    transition: all 5s linear;
    animation: box_animation_range 5s;
    background: #e7e8ea;
}

/* Lotto12 */
.lotto12-bet-pricing-card {
    height: auto;
    border: 1px solid #fff;
}


.lotto12-bet-pricing-card:hover {
    height: auto;
    border: 1px solid rgb(190, 0, 0);
}

.lotto12-bg {
    background: #fff;
    border: 1px solid #dfd9d9;
}

.h-random-card {
    height: 400px !important;
}

@keyframes box_animation_range {
    0% {
        background: linear-gradient(to bottom, #550000 0%, #990000 50%, #550000 100%);
        color: #fff;
    }
    25% {
        background: linear-gradient(to bottom, #ad0101 0%, #e71313 50%, #ad0101 100%);
        color: #fff;
    }
    50% {
        background: linear-gradient(to bottom, #550000 0%, #990000 50%, #550000 100%);
        color: #fff;
    }
    75% {
        background: linear-gradient(to bottom, #ad0101 0%, #e71313 50%, #ad0101 100%);
        color: #fff;
    }
    100% {
        background: linear-gradient(to bottom, #550000 0%, #990000 50%, #550000 100%);
        color: #fff;
    }
}


@keyframes box_animation_range_v2 {
    0% {
        background: #f3bc37;
    }
    25% {
        background: #ffffff;
    }
    50% {
        background: #f3bc37;
    }
    75% {
        background: #ffffff;
    }
    100% {
        background: #f3bc37;
    }
}


@keyframes border_animation_range {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;

    }
    50% {
        opacity: 1;

    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
