/* History Style 1 */

.statistic-ball-, .statistic-ball-OVER, .statistic-ball-UNDER, .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX {
    margin: 0 auto !important;
    width: 35px;
    height: 35px;
    border-radius: 35px;
}

.statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX {
    line-height: 35px !important;
}


.statistic-ball-OVER {
    background: #FA0000;

}

.statistic-ball-UNDER {
    background: #003CFF;
}

.lotto12-statistic-ball-OVER {
    margin: 2px 2px 2px 7px !important;
    width: 30px;
    height: 30px;
    border-radius: 35px;
    border: 7px solid radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgb(250, 34, 167) 95%, rgb(243, 57, 144) 45%, rgba(255, 0, 0, 0) 95%);
    background: radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgb(243, 57, 144) 45%, rgb(250, 34, 167) 95%, rgba(255, 0, 0, 0) 95%);

}

.lotto12-mix .lotto12-statistic-ball-OVER {
    margin: 0 auto !important;
    width: 30px;
    height: 30px;
    border-radius: 35px;
    border: 7px solid radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgb(250, 34, 167) 95%, rgb(243, 57, 144) 45%, rgba(255, 0, 0, 0) 95%);
    background: radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgb(243, 57, 144) 45%, rgb(250, 34, 167) 95%, rgba(255, 0, 0, 0) 95%);

}

.lotto12-statistic-ball-UNDER {
    margin: 0 auto !important;
    width: 30px;
    height: 30px;
    border-radius: 35px;
    border: 7px solid radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgb(2, 145, 241) 95%, rgb(2, 193, 241) 45%, rgba(0, 0, 255, 0) 95%);
    background: radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgb(2, 193, 241) 45%, rgb(2, 145, 241) 95%, rgba(0, 0, 255, 0) 95%);
}

.lotto12-mix .lotto12-statistic-ball-UNDER {
    margin: 0 auto !important;
    width: 30px;
    height: 30px;
    border-radius: 35px;
    border: 7px solid radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgb(2, 145, 241) 95%, rgb(2, 193, 241) 45%, rgba(0, 0, 255, 0) 95%);
    background: radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgb(2, 193, 241) 45%, rgb(2, 145, 241) 95%, rgba(0, 0, 255, 0) 95%);
}

.lotto12-countdown-number {
    background: linear-gradient(-45deg, #b40101, #df0202, #a20018);
    /*height: 50px;*/
}

.lotto12-countdown-number > span {
    font-size: 33px;
}

.lotto12-new-result {
    background: #3E579D !important;
    /*display: table !important;*/
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.platform-999 .lotto12-new-result {
    background: #A80000 !important;
}

.platform-999.GREEN .lotto12-new-result {
    background: #28640E !important;
}

.platform-type-P99 .lotto12-new-result {
    background: #106B29 !important;
}

.lotto12-new-result .draw-code,
.lotto12-new-result .datetime {
    display: table-cell;
    vertical-align: middle;
}

.lotto12-new-result .draw-code {
    text-align: left;
}

.lotto12-new-result .datetime {
    text-align: right;
}

.lotto12-new-result > span {
    color: white !important;
    /*line-height: 50px;*/
}

.statistic-ball-ONE {
    background: #DBAF46 !important;
    color: #fff;
    font-size: 1em;
}

.statistic-ball-TWO {
    background: #456ADD !important;
    color: #fff;
    font-size: 1em;
}

.statistic-ball-THREE {
    background: #245B21 !important;
    color: #fff;
    font-size: 1em;
}

.statistic-ball-FOUR {
    background: #521814 !important;
    color: #fff;
    font-size: 1em;
}

.statistic-ball-FIVE {
    background: #C43022 !important;
    color: #fff;
    font-size: 1em;
}

.statistic-ball-SIX {
    background: #F00F2B;
    color: #fff;
    font-size: 1em;
}

/* History Style 2 */

.lotto6-statistic-ball-OVER {
    margin: 2px 2px 2px 3px !important;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    border: 9px solid radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgba(156, 0, 0, 1) 45%, rgba(156, 0, 0, 1) 95%, rgba(255, 0, 0, 0) 95%);
    background: radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgb(236 0 35) 45%, rgb(236 0 35) 95%, rgba(255, 0, 0, 0) 95%);

}

.lotto6-statistic-ball-UNDER {
    margin: 2px 2px 2px 3px !important;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    border: 9px solid radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgba(0, 0, 142) 45%, rgba(0, 0, 142, 1) 95%, rgba(0, 0, 255, 0) 95%);
    background: radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgb(21 2 241) 45%, rgb(21 2 241) 95%, rgba(0, 0, 255, 0) 95%);
}

.lotto6-statistic-ball- {
    width: 55px;
    height: 55px;
}

.lotto6-statistic-ball-ONE {
    margin: 2px 2px 2px 3px !important;
    background: #0021a1;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    line-height: 53px !important;
    color: #fff;
    font-size: 1em;
}

.lotto6-statistic-ball-TWO {
    margin: 2px 2px 2px 3px !important;
    background: #008B28;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    line-height: 53px !important;
    color: #fff;
    font-size: 1em;
}

.lotto6-statistic-ball-THREE {
    margin: 2px 2px 2px 3px !important;
    background: #FFC344;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    line-height: 53px !important;
    color: #fff;
    font-size: 1em;
}

.lotto6-statistic-ball-FOUR {
    margin: 2px 2px 2px 3px !important;
    background: #720E1B;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    line-height: 53px !important;
    color: #fff;
    font-size: 1em;
}

.lotto6-statistic-ball-FIVE {
    margin: 2px 2px 2px 3px !important;
    background: #ff6882;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    line-height: 53px !important;
    color: #fff;
    font-size: 1em;
}

.lotto6-statistic-ball-SIX {
    margin: 2px 2px 2px 3px !important;
    background: #F00F2B;
    width: 55px;
    height: 55px;
    border-radius: 35px;
    line-height: 53px !important;
    color: #fff;
    font-size: 1em;
}

.grid-bet-history {
    width: 100px;
    height: 100px;
    border: 1px solid #000;
}

.table-rotate-270 {
    transform: rotate(270deg) scaleX(-1);
}

.bet-grid-container {
    display: contents;
    grid-template-columns: auto auto auto;
}

.bet-container-item {
    text-align: center;
    font-size: 30px;
}

.bet-item {
    line-height: 2.5em;
    width: 69px;
    height: 73px;
    border: 1px solid #000;
    font-size: 1em;
    font-weight: 600;
    background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
    padding: 6px;
}

.range-line {
    width: 60px;
}
