.error-color-validation {
    width: 100%;
    padding: 5px 15px;
    background: #dd3355;
    color: #fff;
    border-radius: 2px;
}

.error-color-validation button {
    text-align: right;
}