
.wt-100 {
    width: 100%;
}

.wt-90 {
    width: 90%;
}

.wt-20 {
    width: 20%;
    margin: 5px;
    padding: 5px;
}

.wt-45 {
    width: 30%;
    margin: 5px;
    padding: 5px;
}

.wt-60 {
    width: 60%;
}

.model-item {
    background-color: #0b00a7;
    border-radius: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
}

.model-item img {
    border-radius: 10px;
    padding-bottom: 10px;
}

.playnow {
    color: white;
    font-weight: bold;
    font-size: 18px;
    line-height: 3em;
}

.info-item {
    border: 1px solid gray;
    border-radius: 10px;
    background-color: #fff;
}

.info-item img {
    width: 150px;
}

#authorized {
    width: 100%;
    background-color: #0035AF;
    padding: 15px;
    position: fixed;
    bottom: 0;
}

#authorized img {
    width: 100px;
}

#authorized .auth {
    font-size: 24px;
    color: white;
}

.data-not-found {
    width: 70%;
    opacity: 0.5;
}

.bet-mix .data-not-found {
    width: 50%;
    opacity: 0.5;
}

.wrapper-home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100% !important;
    padding: 0 20vw;
}

.wrap-home-item {
    padding-top: 2.2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-item {
    width: 11vw;
}

.home-play {
    font-size: 1.2vw;
    margin-top: .8vw;
    text-align: center;
    background-color: #0052FF;
    border-radius: 20vw;
    color: white;
    font-weight: bold;
    padding: .5vw 0;
}

.m-home-play {
    margin: 0 1.2vw;
}