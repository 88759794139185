.lo90-v1-wap {
    display: flex;
    /*justify-content: center;*/
    width: 100%;
}

.lo90-v1-wap .logo img {
    margin-left: unset !important;
    width: 5vw !important;
    max-width: 5vw !important;
    height: 5vw !important;
}

.lo90-v1-wap .date-sec, .lo90-v1-wap .ticket-sec {
    color: white;
    font-size: 1.1vw !important;
    font-weight: bold;
}

.lo90-v1-wap .time-sec {
    background: unset;
    font-size: 2.6vw;
    font-family: 'Arial Black', sans-serif !important;
    line-height: 0.5;
    padding: 0.6vw;
    margin-top: 0.4vw;
    color: black;
}

.draw-timer-wrap {
    background: #FFEBD9;
    height: 5vw;
    width: 14vw;
    border-radius: .5vw;
}


.draw-timer-wrap .ticket-sec, .draw-timer-wrap .date-sec {
    color: black;
}

.lo90-v1-l-side {
    width: 25vw;
}

.lo90-v1-r-side {
    width: 25vw;
}

.lo90-v1-mid-side {
    width: 50vw;
}

.lo90-v1-drawing-board {
    padding: 1vw;
    height: 23vw;
    background-repeat: no-repeat;
    background-size: contain;
}

.lo90-v1-drawing {
    width: 100%;
    position: relative;
    margin-top: 1vw;
}

.lo90-v1-drawing img {
    width: 100%;
    position: absolute;
    right: 0;
}

.draw-number {
    display: flex;
    position: absolute;
    z-index: 99;
    top: 2.4vw;
    left: 0.2vw;
}

.number-render {
    font-size: 3vw;
    padding: 0 2.7vw;
    font-weight: bold;
}

.lo90-v1-wap .draw-history .lotto90-post-A,
.lo90-v1-wap .draw-history .lotto90-post-B,
.lo90-v1-wap .draw-history .lotto90-post-C,
.lo90-v1-wap .draw-history .lotto90-post-D,
.lo90-v1-wap .draw-history .lotto90-post-E {
    font-size: 1.5vw;
}

.lo90-v1-wap .lotto90-post-A {
    color: #FA0000;
    font-weight: bold;
    font-family: "Arial Black", sans-serif !important;
}

.lo90-v1-wap .lotto90-post-B {
    color: #32A001;
    font-weight: bold;
    font-family: "Arial Black", sans-serif !important;
}

.lo90-v1-wap .lotto90-post-C {
    color: #F27700;
    font-weight: bold;
    font-family: "Arial Black", sans-serif !important;
}

.lo90-v1-wap .lotto90-post-D {
    color: #02AFF1;
    font-weight: bold;
    font-family: "Arial Black", sans-serif !important;
}

.lo90-v1-wap .lotto90-post-E {
    color: #FD59A8;
    font-weight: bold;
    font-family: "Arial Black", sans-serif !important;

}

.lo90-v1-wap .num-circle-ticket-A,
.lo90-v1-wap .num-circle-ticket-B,
.lo90-v1-wap .num-circle-ticket-C,
.lo90-v1-wap .num-circle-ticket-D,
.lo90-v1-wap .num-circle-ticket-E {
    width: 2.9vw;
    height: 2.9vw;
    line-height: 3vw;
    text-align: center;
    font-size: 1.7vw;
    color: #fff;
    border-radius: 50%;
    margin: 0 0.77vw;
    font-weight: bold;
}

.lo90-v1-wap .num-circle-ticket-A {
    background-color: #FA0000;
}

.lo90-v1-wap .num-circle-ticket-B {
    background-color: #32A001;
}

.lo90-v1-wap .num-circle-ticket-C {
    background-color: #F27700;
}

.lo90-v1-wap .num-circle-ticket-D {
    background-color: #02AFF1;
}

.lo90-v1-wap .num-circle-ticket-E {
    background-color: #FD59A8;
}

.lo90-v1-wap .pr-vw {
    padding-right: 2vw;
}

.lo90-v1-wap .font-moul {
    font-family: 'Moul', cursive !important;
    white-space: nowrap;
}

.lo90-v1-wap .lotto-name {
    color: white !important;
    font-weight: unset !important;
    font-size: 2vw;
}

.post-circle-lo90 {
    height: 3vw;
    width: 3vw;
    border-radius: 50%;
    background-color: #DCE1E6;
    font-weight: bold;
    font-size: 2vw;
    text-align: center;
    color: #21397B;
    transition: all .2s ease-out;
    font-family: Arial, serif;
}

.post-circle-lo90:hover {
    transform: scale(1.1);
}

.post-circle-lo90.active {
    background-color: #21397B;
    color: white;
}

.lo90-v1-wap .num-circle-lo90v1 {
    margin: 0.2vw 0.6vw;
    width: 2vw;
    height: 2vw;
    font-size: 1.3vw;
    line-height: unset;
}

.lo90-v1-wap .table-wrapper {
    background-color: #F2F2F2;
}

.lo90-v1-wap .post-wrapper {
    padding: 0.5vw 8vw;
    margin-top: unset;
    margin-bottom: unset;
}

.lo90-v1-wap .right-side .row {
    margin-right: 0.5vw;
}

.lo90-v1-wap .p-vw {
    padding: 0.2vw 1vw !important;
}

.recent-result {
    background-color: #D2E3F5;
}

.platform-999 .recent-result {
    background-color: #FFBBBB;
}

.platform-999.GREEN .recent-result {
    background-color: #CCF1BC;
}

.lo90-v1-wap .time-sec-title {
    margin: 1.4vw 0;
    font-size: 1.6vw;
    font-weight: bold;
    color: black;
}

.lo90-v1-wap .recent-result {
    padding-left: 1.2vw;
}

.p-2-vw {
    padding: 0 0.4vw !important;
}

#lotto90-v1 .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #a79521;
}

.lo90-v1-wap .f-22 {
    font-size: 1vw !important;
}

.lo90-v1-wap .f-15 {
    font-size: 1vw !important;
}

.lo90-v1-wap .kh-font-bold > b {
    font-size: 1vw;
}

.lo90-v1-wap .tb-number {
    padding: 0.5vw 0;
}

.top-0{
    top: 3vw!important;
    left: 6vw!important;
}

.draw-time-top{
    top: 2vw!important;
}

.r-0{
    right: 0!important;
    top: 0;
    bottom: 0;
    background: rgba(1,2,3, 0.2);
}

.l-0{
    left: 0!important;
}

.t-vw{
    top: 16vw!important;
}

.lo90-v1-wap .d-board-Fullscreen .lotto90-bg img{
width: 55vw;
}

.lo90-v1-wap .d-board-Fullscreen .table-wrapper{
    width: 51vw;
    margin: 3vw auto;
    /*margin-top: 1vw;*/
    background-color: transparent!important;
}

.lo90-v1-wap .d-board-Fullscreen .table-wrapper .num-circle-lo90v1{
    color: white!important;
}

.r-vw{
    right: 22vw!important;
}

.lo90-v1-wap .d-board-Fullscreen .draw-number{
    left: 5.8vw!important;
}

.lo90-v1-wap .d-board-Fullscreen .number-render{
    font-size: 4vw!important;
    padding: 0 2.8vw!important;
}

.lo90-v1-wap .d-board-Fullscreen .f-res{
    color: white;
}

.lo90-v1-wap .d-board-Fullscreen .recent-result{
    background-color: transparent;
}

.lo90-v1-wap .d-board-Fullscreen .right-side{
    border: none!important;
    margin-top: 2vw;
}

.lo90-v1-wap .d-board-Fullscreen .logo img {
    width: 8vw!important;
    height: 8vw!important;
    max-width: 8vw!important;
}

.lo90-v1-wap .draw-timer-wrap {
    margin-right: 2vw!important;
}