.btn-confirm-bet-now {
    margin-top: 10px;
    color: #fff;
    background: #24C121;
    width: 94%;
    height: 100%;
    border-radius: 4px;
    cursor: pointer;
    font-family: BattambangRegular, RobotoRegular;
    margin-bottom: 15px;
    padding-bottom: 14px;
    padding-top: 14px;
}

.main-screen-iframe .btn-confirm-bet-now {
    padding-bottom: .5vw;
    padding-top: .5vw;
}

.btn-confirm-bet-now:hover {
    background: #139259;
}

.table-current-bet {
    background: transparent;
}

.table-current-bet thead th {
    font-size: 15px;
    font-family: BattambangRegular, RobotoRegular;
}

.table-current-bet td, .table-current-bet th {
    border-top: 1px solid #dee2e6 !important;
    color: #000;
    font-size: 15px;
}

.table-current-bet tbody td {
    font-size: 15px;
    padding: 5px;
    line-height: 30px;
    font-family: BattambangRegular, RobotoRegular;
}

#lotto-mix .table-current-bet tbody td {
    padding: 2px !important;
}

.left-bet-type-UNDER {
    background-color: rgb(21, 0, 255);
    padding: 0 .2vw;
    border-radius: .3vw;
    color: #fff;
}

.left-bet-type-OVER {
    background-color: #CB372B;
    padding: 0 .2vw;
    border-radius: .3vw;
    color: #fff;
    margin-right: 9px;
}

.left-bet-type-ODD {
    background-color: #F16522;
    padding: 0 .2vw;
    border-radius: .3vw;
    color: #fff;
}

.left-bet-type-EVEN {
    background-color: #FAAF40;
    padding: 0 .2vw;
    border-radius: .3vw;
    color: #fff;
}


.main-screen-iframe .left-bet-type-OVER {
    margin-right: 0 !important;
}

.left-bet-type-12-UNDER {
    background-color: #18C9FF;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-12-OVER {
    background-color: #FE5BA9;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
    margin-right: 9px;
}

.left-bet-type-ONE {
    background-color: #DBAF46 !important;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-TWO {
    background-color: #456ADD !important;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-THREE {
    background-color: #245B21 !important;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
    text-transform: lowercase !important;
}


.left-bet-type-FOUR {
    background-color: #521814 !important;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-FIVE {
    background-color: #C43022 !important;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-SIX {
    background-color: #F00F2B;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-RED {
    background-color: #F00F2B;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-GREEN {
    background-color: #00b60f;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-BLUE {
    background-color: #0300b6;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}


.left-bet-type-TWO_THREE {
    background-color: #6bd3ff;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-THREE_FOUR {
    background-color: #ffb414;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}

.left-bet-type-FOUR_FIVE {
    background-color: #f50000;
    padding: 0px 6px;
    border-radius: .4vw;
    color: #fff;
}
