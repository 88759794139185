.card-count-down-logo {
    height: 500px;
    max-height: 279px;
    cursor: pointer;
}

.bg-count-down {
    margin-bottom: 0;
    background: linear-gradient(-45deg, #710000, #df0202, #a20018);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    color: #fff;
    cursor: pointer;
}

.bet-bg-logo {
    background-color: #3200CB;
}

.last-result-betting {
    font-size: 20px !important;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Count down time */
.lotto6-show-logo-render {
    text-align: center;
    width: 100%;
    /*height: 118px;*/
    margin: 0 auto;
    display: block;
}

.lotto6-hide-logo-render {
    display: none;
}

.lotto6-hide-number-draw {
    box-shadow: none;
}

.lotto6-show-logo-render img {
    width: 10vw !important;
    height: 10vw !important;
    /*margin: 15px auto;*/
}

.lotto6-show-number-draw {
    text-align: center;
    font-size: 6vw !important;
    line-height: unset !important;
    font-weight: bold;
    color: #fff;
    width: 10vw !important;
    height: 10vw !important;
    /*margin: 13px auto;*/
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%);
    box-shadow: 0px 2px 10px #8d0026;
}


.lotto6-total-result-render {
    width: 100%;
    height: 40px;
    line-height: 187px;
    font-weight: bold;
    margin: 0 auto;
    font-size: 70px;
}

.lotto6-total-result-render-count {
    color: rgb(255, 255, 255) !important;
    font-size: 6vw !important;
    line-height: unset !important;
}

.lotto6-mix-total-result-render-count {
    color: rgb(255, 255, 255) !important;
    font-size: 11vw !important;
    line-height: unset !important;
}

.lotto6-total-result-render-count-warning {
    color: #fce700 !important;
    font-size: 6vw !important;
    line-height: unset !important;
}

.lotto6-mix-total-result-render-count-warning {
    color: #fce700 !important;
    font-size: 11vw !important;
    line-height: unset !important;
}

.lotto6-total-result-render-count-total {
    font-size: 6vw !important;
    line-height: unset !important;
}

.lotto6-mix-total-result-render-count-total {
    font-size: 11vw !important;
    line-height: unset !important;
}

.lotto6-mix-total-result-render-count-total {
    font-size: 11vw !important;
    line-height: unset !important;
}

.lotto6-result-time-countup-render {
    width: 13%;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    border-radius: 2px;
    background: #ad0026;
    margin: 2px;
    position: absolute;
}

/* Grid number of betting */
.lotto6-content-result {
    width: 100%;
    overflow: hidden;
    display: block;
}


.lotto6-content-result table td {
    border: 2px solid #080808;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
    overflow: hidden;
    color: #000;
}

.lotto6-content-result table td span {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: unset !important;
    color: #000;
    font-size: 2vw !important;
    height: 4.1vw !important;
    font-family: BattambangRegular, RobotoRegular;
    font-weight: 600;
    cursor: pointer;

}

.lotto6-mix .lotto6-content-result table td span {
    padding: 1.34vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 4vw !important;
    height: unset !important;
    font-family: BattambangRegular, RobotoRegular;
    font-weight: 600;
    cursor: pointer;
}

.lotto6-mix .lotto6-v2-slot-prefix-card > h1 {
    font-size: 5vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.2vw !important;
}

.lotto6-mix .lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-type-ONE,
.lotto6-mix .lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-type-SIX {
    font-size: 9vw !important;
    height: 18.15vw !important;
}


.lotto6-mix .lotto6-show-logo-render {
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;

}

.lotto6-mix .lotto6-show-logo-render img {
    width: 22vw !important;
    height: 22vw !important;
}

.lotto6-mix .lotto6-mix-show-number-draw {
    color: #fff;
    font-size: 14vw !important;
    line-height: 22vw !important;
    width: 22vw !important;
    height: 22vw !important;
    border-radius: 50%;
    display: block;
    font-weight: bold;
    background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%);
    box-shadow: 0 2px 10px #8d0026;
}

.lotto6-mix .lotto6-v2-card-count-down-logo {
    height: 51% !important;
}

.lotto6-mix .lotto6-mix .lotto6-show-logo-render {
    height: 50% !important;
}

.lotto6-mix .lotto6-range-number {
    font-size: 3vw !important;
    font-weight: 800;
    color: #000;
}

.lotto6-mix .lotto6-bet-value {
    font-size: 3vw !important;
    color: #2621a1 !important;
    line-height: unset !important;
    font-weight: 800;
}

.lotto6-mix .lotto6-v2-bet-value {
    font-size: 3vw !important;
}

.lotto6-mix .total-result-render {
    line-height: unset !important;
}

.lotto6-mix .lotto6-v2-card-bg-color-UNDER h1 {
    background: #3200cb;
}

.lotto6-mix .lotto6-v2-card-bg-color-OVER h1 {
    background: #df0202;
}

.lotto6-mix .lotto6-v2-card-bg-color-UNDER h1,
.lotto6-mix .lotto6-v2-card-bg-color-OVER h1,
.lotto6-mix .lotto6-v2-card-bg-color-EVEN h1,
.lotto6-mix .lotto6-v2-card-bg-color-ODD h1 {
    color: #fff !important;
    font-size: 4vw !important;
    font-weight: bold;
}


.lotto6-mix .lotto6-history-result table td span {
    font-size: 3vw !important;
    width: 5vw !important;
    height: 5vw !important;
}

.lotto6-mix .lotto6-v2-slot-value-card .lotto6-range-number,
.lotto6-mix .lotto6-v2-slot-value-card .lotto6-bet-value {
    padding: 0 !important;
    margin: 0 !important;
}

/* End of grid number of betting */

/* Betting history table */
.lotto6-history-result {
    width: 100%;
    float: left;
}

.lotto6-history-result table td {
    height: 53px;
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    overflow: hidden;
    color: #000;
    background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
    border: .06vw solid #567DCA;
}

.lotto6-history-result table tbody tr td label {
    font-family: BattambangRegular, RobotoRegular;
}


.lotto6-history-result table td span {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: unset !important;
    /* color: #000; */
    width: 2vw !important;
    height: 2vw !important;
    font-size: 1.3vw !important;
    font-weight: bold;
}


.lotto6-history-result table td label {
    padding: 0;
    margin: 0;
    display: block;
    line-height: 30px;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    font-family: BattambangRegular, RobotoRegular;
}

.lotto6-history-result table td label.title {
    color: #111;
    background: linear-gradient(to bottom, #c5c5c5 0%, #e7e7e7 50%, #c5c5c5 100%);
    font-family: BattambangRegular, RobotoRegular;
}

/* End of betting history table */


/* Six36 V2 -- Count down time */
.lotto6-v2-show-logo-render {
    text-align: center;
    width: 100%;
    height: 118px;
    margin: 0 auto;
    display: block;
}

.lotto6-v2-hide-logo-render {
    display: none;
}

.lotto6-v2-hide-number-draw {
    box-shadow: none;
}

.lotto6-v2-show-logo-render img {
    width: 178px;
    margin: 11px auto;
}

.lotto6-v2-show-number-draw {
    text-align: center;
    font-size: 106px;
    line-height: 176px;
    font-weight: bold;
    color: #fff;
    width: 171px;
    height: 164px;
    margin: 13px auto;
    border-radius: 130px;
    display: block;
    background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%);
    box-shadow: 0px 2px 10px #8d0026;
}

.lotto6-v2-total-result-render {
    width: 100%;
    height: 40px;
    line-height: 187px;
    font-weight: bold;
    margin: 0 auto;
    font-size: 70px;
}

.lotto6-v2-total-result-render-count {
    color: rgb(255, 255, 255) !important;
    font-size: 100px;
    line-height: 215px;
}

.lotto6-v2-total-result-render-count-warning {
    color: #fce700 !important;
    font-size: 100px;
    line-height: 215px;
}

.lotto6-v2-total-result-render-count-total {
    font-size: 100px;
    line-height: 215px;
}

.lotto6-v2-result-time-countup-render {
    width: 2vw !important;
    font-size: 1.5vw !important;
    color: #fff;
    font-weight: bold;
    border-radius: .5vw;
    background: #ad0026;
    left: 0;
    top: 0;
    position: absolute;
}

.lotto6-v2-card-count-down-logo {
    height: 12.96vw !important;
    max-height: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.lotto6-mix .lotto6-history-result table td, .lotto12-mix .lotto6-history-result table td {
    height: 5vw !important;
    width: 5vw !important;
    padding: 0.23vw;
    text-align: center;
    vertical-align: middle;
    font-size: 3vw !important;
    color: #000;
    background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
}

.f-vw {
    font-size: 1vw !important;
}

.lotto6-mix .lotto6-total-result-render-count {
    font-size: 15vw !important;
}

.lotto6-mix .header-div-title span {
    font-size: 2vw !important;
}

.lotto12-mix .lotto12-history-result table td span {
    width: 5vw !important;
    height: 5vw !important;
}

.lotto6-header-mix {
    background-color: #3E579D !important;
}

.lotto12-mix .header-label {
    font-size: 3vw !important;
}

.lotto12-mix .lotto12-new-result .draw-code {
    font-size: 3vw !important;
}

.lotto12-mix .lotto12-new-result .datetime {
    font-size: 3vw !important;
    font-weight: bold;
}

.lotto12-mix .header-history-light .last-result-betting {
    font-size: 3vw !important;
}

.lotto12-mix .lotto12-bet-label-color {
    font-size: 3vw !important;
    height: 21.56vw;
    line-height: 4.5 !important;
}

.lotto12-mix .lotto12-bet-label {
    font-size: 3.5vw !important;
    height: 10.6vw;
    line-height: 1.9 !important;
}

.lotto12-mix .rebate-rate {
    font-size: 2.5vw !important;
}

.lotto12-mix .lotto12-countdown-number {
    height: unset !important;
    padding-left: unset !important;
}

.lotto12-history-result table td span {
    font-size: 1vw !important;
    font-weight: 900;
}

.lotto5-mix .type-rage-mix {
    line-height: 10vw !important;
}

.lotto12-mix .last-result-betting {
    line-height: unset !important;
}

.lotto12-mix .lotto12-new-result {
    height: unset !important;
}

.lotto6-mix .lotto6-mix-bet-value {
    line-height: .5vw !important;
    font-size: 3vw !important;
}

.lotto12-mix .h-random-card {
    width: 90%;
    margin: 0 auto;
    height: 52.5vw !important;
}

.lotto5-mix .lotto5-th-mix {
    line-height: unset !important;
    padding: 0.5vw !important;
}

.lotto12-mix .lotto12-history-result table td span {
    font-size: 2.3vw !important;
    line-height: 5vw !important;
}

.lotto12-mix .header-div-title {
    padding: 0.6vw !important;
}

.lotto12-mix.bet-mix .header-div-title {
    padding: 1.5vw !important;
    font-size: 3vw !important;
}

.lotto12-mix .img-responsive-vw {
    width: 13vw !important;
}

.lotto12-mix .lotto12-new-result > span {
    line-height: unset !important;
}

.lotto12-mix .header-label {
    line-height: unset !important;
}

.lotto12-mix #card-inner-result, .result-back {
    width: 22vw !important;
}

.lotto12-mix .flip-card .img-responsive {
    width: 93% !important;
}

.lotto12-mix .img-responsive-vw {
    width: 100% !important;
    height: 14vw !important;
}

.lotto12-mix .f-32 {
    font-size: 4.5vw !important;
}

.pt-vw {
    padding-top: 1vw !important;
}

.mt-vw {
    margin-top: 1vw;
}

.lotto12-mix .draw-code img {
    width: 5vw !important;
    height: 5vw !important;
}

.lotto12-mix .card-size {
    margin: unset !important;
}

.lotto5-bet-mix .total-result-render-count {
    font-size: 3vw !important;
}

.platform-888 .lotto5-bet-mix .total-result-render-count {
    background: unset !important;
}

.lotto5-bet-mix .header-div-title > span {
    line-height: unset !important;
}

.lotto5-bet-mix .header-div-title {
    padding: 1vw !important;
    line-height: unset !important;
}

.lotto5-bet-mix .lotto5-bet-mix-under-over {
    background: #D2E3F5 !important;
}

.lotto5-bet-mix .kh-font-bold {
    padding: 1vw !important;
}

.lotto5-bet-mix .kh-font-bold b {
    font-size: 3vw !important;
}

.lotto5-bet-mix .table td {
    line-height: unset !important;
}

.lotto5-bet-mix .outstanding-balance {
    font-size: 3vw !important;
    font-weight: bold;
}

.lotto5-bet-mix .last-ticket span {
    font-size: 3vw !important;
}

.lotto6-bet-mix {

}

.bet-mix .lotto6-v2-range-number {
    padding-top: 1vw;
    line-height: unset !important;
    font-size: 2vw !important;
}

.p-vw {
    padding: 1vw !important;
}

.f-head {
    font-size: 3vw !important;
    padding-right: 2vw;
}

.header-bet-mix-lotto12 {
    padding: 1vw;
    background: #3E579D !important;
}

.header-bet-mix-lotto12 span {
    color: #eeeeee !important;
    font-family: BattambangBold, RobotoBold !important;
}

.header-bet-mix-lotto12 .draw-code {
    font-size: 3vw !important;
}

.header-bet-mix-lotto12 .time-display-12 {
    font-size: 3vw !important;
    padding-right: 2vw;
}

.header-bet-mix-lotto12 img,
.header-bet-mix-lotto5 img,
.header-bet-mix-lotto6 img {
    width: 4vw !important;
    height: 4vw !important;
}

.header-bet-mix img {
    width: 4vw;
    height: 4vw;
    margin-right: 2vw;
}

.header-bet-mix {
    padding-right: 1vw;
    padding-left: 1vw;
    font-size: 3vw;
    font-weight: bold;
}

.title-bet-mix {
    font-size: 3vw !important;
    font-weight: bold;
    color: #eeeeee;
    font-family: BattambangBold, RobotoBold !important;

}

.mute-img {
    width: 4vw;
    height: 4vw;
    margin-right: 1.5vw;
}

.balance-bet-mix span {
    font-size: 2.5vw !important;
}