/*last game result over*/
.left-bet-type-OVER {
    background-color: #CB372B !important;
}

/*last game result under*/
.left-bet-type-UNDER {
    background-color: rgb(21, 0, 255) !important;
}

/*last game result 1*/
.left-bet-type-ONE {
    background-color: #0021a1 !important;
}

/*last game result 2*/
.left-bet-type-TWO {
    background-color: #0C5B26 !important;
}

/*last game result 3*/
.left-bet-type-THREE {
    background-color: #DEB152 !important;
}

/*last game result 4*/
.left-bet-type-FOUR {
    background-color: #720E1B !important;
}

/*last game result 5*/
.left-bet-type-FIVE {
    background-color: #CB382C !important;
}

/*last game result 6*/
.left-bet-type-SIX {
    background-color: #F00F2B !important;
}

/*background color logo*/
.bet-bg-logo {
    background-color: #3200CB !important;
}

.platform-888 .bet-bg-logo {
    background-color: #fff !important;
}

/*background count second*/
.bg-count-down {
    margin-bottom: 0;
    background: linear-gradient(
            -45deg, #710000, #df0202, #a20018) !important;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    color: #fff;
    cursor: pointer;
}

@media only screen and (max-width: 2012px) {
    /*number range bet*/
    .slot-value-card {
        background: linear-gradient(
                -45deg, #ffff00, #ffff00, #f9cc2f) !important;
    }

    /*color of text betting prize*/
    .slot-prefix-card > h1 {
        color: #db0b06 !important;
    }
}

/*betting under*/
.card-bg-color-UNDER {
    background: #3200cb !important;
}

/*betting over*/
.card-bg-color-OVER {
    background: #df0202 !important;

}

.card-bg-color-UNDER > h1, .card-bg-color-OVER > h1 {
    color: white !important;
}

/*background number item 1-36*/
.lotto6-content-result table td {
    height: 4.305vw !important;
    border: .06vw solid #567DCA !important;
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
    color: #000;
}

.lotto6-history-result table td {
    height: 2.3vw !important;
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
}

/*background result item number*/
table#keno_result_post td span.active {
    animation: box_number_animation 2s;
    background: #E60000 !important;
    color: #fff !important;
}

@keyframes box_number_animation {
    from {
        background-color: #6c0111 !important;
        color: #fff !important;
    }
    to {
        background-color: #ec0024 !important;
        color: #fff !important;
    }
}

/*background color of betting prize*/
.slot-prefix-card {
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
}

/*result text range*/
.range-number {
    color: #000 !important;
}


/*rebate rate amount*/
.bet-value {
    font-size: 2em;
    color: #2621a1 !important;
}

/*background number drawing*/
.lotto6-show-number-draw {
    color: #fff;
    background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%) !important;
    box-shadow: 0px 2px 10px #8d0026 !important;
}

/*header of balance, last bet, and u/o range bet*/
.header-div-blue-light {
    background-color: #eeeeee !important
}

.statistic-ball-ONE {
    background: #0021a1 !important;
    color: #fff !important;
    font-size: 1em;
}

.statistic-ball-TWO {
    background: #008B28 !important;
    color: #fff !important;
    font-size: 1em;
}

.statistic-ball-THREE {
    background: #FFC344 !important;
    color: #fff !important;
    font-size: 1em;
}

.statistic-ball-FOUR {
    background: #720E1B !important;
    color: #fff !important;
    font-size: 1em;
}

.statistic-ball-FIVE {
    background: #ff6882 !important;
    color: #fff !important;
    font-size: 1em;
}

/*result ball*/
.statistic-ball-SIX {
    background: #F00F2B !important;
    color: #fff !important;
    font-size: 1em;
}

.lotto6-v2-slot-prefix-card > h1 {
    line-height: unset !important;
    font-size: 3vw !important;
    color: #db0b06;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.57vw !important;
    background: #e7e8ea;
    font-weight: 500;
    font-family: "Arial Black" !important;
}

.lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-bg-color-OVER {
    background: #df0202;
    color: white !important;
}

.lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-bg-color-UNDER {
    background: #3200cb;
    color: white !important;
}

.lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-bg-color-ODD {
    background: #F16522;
    color: white !important;
}

.lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-bg-color-EVEN {
    background: #FAAF40;
    color: white !important;
}

.lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-type-ONE, .lotto6-v2-slot-prefix-card > h1.lotto6-v2-card-type-SIX {
    height: 10.05vw !important;
    font-size: 5vw !important;
}


.lotto6-v2-bet-pricing-card {
    height: unset !important;
    border-left: .01vw solid #567DCA !important;
    border-bottom: .01vw solid #567DCA !important;
}


.lotto6-v2-slot-prefix-card {
    height: unset !important;
    background: #e7e8ea;
    padding: .34vw !important;
}

.lotto6-v2-card-bg-color-UNDER > h1, .lotto6-v2-card-bg-color-OVER > h1, .lotto6-v2-card-bg-color-ODD > h1, .lotto6-v2-card-bg-color-EVEN > h1 {
    font-size: 2.5vw !important;
    line-height: 5.6vw !important;
    color: white !important;
}

.lotto6-v2-range-number {
    line-height: 1 !important;
    font-weight: 800;
    color: #000;
    font-size: .8vw !important;
}

.lotto6-v2-bet-value {
    margin-top: unset !important;
    font-size: 1vw !important;
    color: #2621a1;
    line-height: 1 !important;
    font-weight: 800;
}

.lotto6-v2-slot-value-card {
    height: 2.2vw !important;
    background: #efe580 !important;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    color: #fff;
    border-top: .09vw solid #567DCA !important;
}


.lotto6-jackpot-v2-img {
    width: 9.3vw;
    height: 2.15vw;
    top: 0;
    left: 0;
    z-index: 100;
}

.result-time-countup-render-lo6-mix {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5vw;
    height: 4.5vw;
    text-align: center;
    font-size: 3vw;
    color: #fff;
    font-weight: bold;
    border-radius: .3vw;
    background: #ad0026;
    margin: .3vw;
    position: absolute;
    top: 0;
    left: 0;
}

.header-div-title-6-mix {
    height: 7vw;
    padding: 0 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.head-flex-lo6-mix-label {
    font-size: 3vw;
    font-weight: bold;
    font-family: BattambangBold, RobotoBold, serif !important;
    color: white;
}

.w-load-lo6-mix {
    width: 5vw;
    margin-left: 1.5vw;
}

.lotto6-mix-show-number-draw {
    color: #fff;
    font-size: 14vw !important;
    line-height: 25vw !important;
    width: 24vw !important;
    height: 24vw !important;
    margin: .85vw auto;
    border-radius: 50%;
    display: block;
    font-weight: bold;
    background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%);
    box-shadow: 0 2px 10px #8d0026;
}

.lotto6-mix-range-number {
    /*border-top: .2vw solid #567DCA !important;*/
    line-height: 4vw;
    font-size: 2.5vw;
    font-weight: bold;
    color: black;
}

.lotto6-mix-bet-value {
    line-height: 4vw;
    font-size: 4vw;
    color: #2621a1;
    font-weight: bold;
}

.lotto6-mix-v2-slot-value-card {
    height: unset !important;
    background: #e7e8ea;
    padding: 0.34vw !important;
}

.lotto6-mix-v2-slot-prefix-card {
    height: 12vw;
    background: #e7e8ea;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #db0b06;
}

.f-num-lo6-mix {
    font-size: 8vw;
}

.lotto6-mix-v2-card-bg-color-OVER {
    background: #df0202;
    color: white !important;
}

.lotto6-mix-v2-card-bg-color-UNDER {
    background: #3200cb;
    color: white !important;
}

.lotto6-mix-v2-card-bg-color-OVER .f-num-lo6-mix, .lotto6-mix-v2-card-bg-color-UNDER .f-num-lo6-mix {
    font-size: 6vw;
}

.head-lo6-mix {
    padding: 0.5vw;
    background-color: red;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*.platform-999.GREEN .head-lo6-mix {*/
/*    background-color: #28640E;*/
/*}*/

.last-result-betting-lo6-mix {
    font-size: 3vw;
    font-weight: bold;
    color: white;
    font-family: BattambangBold, RobotoBold, serif !important;
}

.lotto6-mix .lotto6-v2-slot-prefix-card {
    padding: 1vw !important;
}

.lotto6-mix .lotto6-v2-slot-value-card {
    height: 5.7vw !important;
}

.lotto6-mix .lotto6-jackpot-v2-img {
    width: 24.5vw !important;
    height: 5.8vw !important;
}