.fade-in {
    text-align: center;
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein { /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein { /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/*.w-100 {*/
/*    width: 100%;*/
/*    min-width: 1200px;*/
/*}*/

.kh-font-battambang {
    font-family: BattambangRegular, RobotoRegular, serif;
}

table.table-component {
    background: #fff;
    width: inherit;
    margin-bottom: 50px;
}

table.table-component th, table.table-component td {
    padding: 5px 10px;
}

.wrap-form-login {
    position: absolute;
    top: 70px;
    float: left;
    max-width: 380px;
    width: 380px;
    padding: 20px;
    margin: 30px auto 0 15px;
    background: #041f80;
    border-radius: 10px;
}

.platform-type-P99 .wrap-form-login {
    background: #106B29;
}

.login-logo {
    border-radius: 50px;
    margin-top: 25px;
}

.wrap-form {
    max-width: 600px;
    width: 600px;
    padding: 0px;
    margin: 30px auto 0 auto;
}

.wrap-input-form {
    margin-bottom: 15px;
}

.input-form {
    width: 100%;
    height: 45px;
    padding-left: 195px;
    padding-right: 15px;
    border: 1px solid #446;
    border-radius: 4px;
}

.login-right-logo > img {
    margin-left: 205px;
    margin-top: 105px;
    padding: 15px;
    padding-right: 15px;
}

.label-form {
    text-align: center;
    background: #446;
    color: #fff;
    line-height: 43px;
    height: 45px;
    width: 180px;
    border-radius: 4px 0 0 4px;
    margin-top: -45px;
    position: absolute;
    font-family: BattambangRegular, RobotoRegular;
}

.input-form-phone-number {
    width: 100%;
    height: 45px;
    padding-left: 265px;
    padding-right: 15px;
    border: 1px solid #446;
    border-radius: 4px;
}

.label-form-phone-code {
    text-align: center;
    line-height: 43px;
    height: 43px;
    width: 80px;
    background: #eee;
    text-align: center;
    margin-top: -44px;
    margin-left: 180px;
    position: absolute;
}

.input-form-radio {
    width: 22px;
    height: 22px;
    padding-left: 195px;
    padding-right: 15px;
}

.radio-label {
    display: grid;
    color: #224;
}

.radio-inline {
    display: inline-block;
    padding-right: 20px;
    width: 100px;
}

.btn-submit {
    background: linear-gradient(to bottom, #585888 0%, #383868 100%);
    color: #fff;
    font-weight: bold;
    width: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 4px;
    margin: 0 1% 0 1%;
    cursor: pointer;
    transition: all 0.5s linear;
}

.btn-submit:hover {
    background: linear-gradient(to bottom, #484878 0%, #282848 100%);
}

.btn-submit-login {
    margin-top: 20px;
    background: #fafd83;
    color: #000;
    font-weight: bold;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s linear;
}

.btn-submit-login:hover {
    background: #ababab;
}

.table-dark {
    background: #282848;
}


.btn-logout {
    color: #fff;
    font-weight: bold;
    line-height: 40px;
    border-radius: 12px;
    cursor: pointer;
    width: 140px;
    font-size: 14px;
    background: #567DCA;
    font-family: BattambangRegular, RobotoRegular;
}

.btn-logout.bg-888 {
    background: #567DCA;
}

.platform-type-P99 .btn-logout.bg-888 {
    background: #239600;
}

.btn-logout:hover {
    background: #9a0000;
}

.platform-type-P99 .btn-logout:hover {
    background: #106B29;
}

.btn-full-screen {
    position: absolute;
    right: 5px;
    top: 155px;
    width: 40px;
    height: 40px;
    color: #fff;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 10px #333;
    cursor: pointer;
}

.btn-full-screen:hover {
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 10px #000;
}

body:fullscreen,
body:-ms-fullscreen,
body:-webkit-full-screen,
body:-moz-full-screen {
    overflow: scroll !important;
}

.page-info-title {
    font-size: 28px;
    font-weight: bold;
    color: #000;
    text-align: center;
    padding: 50px 0;
}

.page-info-desc {
    font-size: 18px;
    line-height: 26px;
    color: #000;
    text-align: justify;
}


.react-datepicker-wrapper {
    /*display: inline !important;*/
}

.wrap-form-date {
    max-width: 600px;
    width: 600px;
}

.wrap-input-form-date {
    margin: 5px 0;
    max-width: 280px;
    float: left;
    margin-right: 20px;
}

.wrap-input-form-login {
    margin: 5px 0;
    max-width: 100%;
    /* float: left; */
}

.input-form-login {
    width: 100%;
    /* max-width: 300px; */
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    color: #000;
    font-weight: bold;
    background: rgb(244 245 234);
    margin-top: 15px;
}

.label-form-login {
    text-align: left;
    padding-left: 20px;
    color: #555555;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    max-width: 300px;
    width: 100%;
}

.btn-submit-login {
    margin-bottom: 35px;
}

.input-form-date {
    width: 100%;
    max-width: 300px;
    height: 45px;
    padding-left: 135px;
    padding-right: 15px;
    border: 1px solid #446;
    border-radius: 4px;
}

.label-form-date {
    text-align: center;
    background: #446;
    color: #fff;
    line-height: 43px;
    height: 45px;
    width: 120px;
    border-radius: 4px 0 0 4px;
    margin-top: -45px;
    /* margin-left: 100px; */
    position: absolute;
}

.btn-submit-filter {
    background: linear-gradient(to bottom, #585888 0%, #383868 100%);
    color: #fff;
    font-weight: bold;
    width: 100px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.5s linear;
}

.btn-submit-filter:hover {
    background: linear-gradient(to bottom, #484878 0%, #282848 100%);
}

.nav {
    border-bottom: none;
    border-top: none;
    background: #d0d5ee;
}

.table {
    margin-bottom: 0;
}

/*.btn-primary {*/
/*    color: #fff;*/
/*    background-color: #558;*/
/*    border-color: #558;*/
/*}*/

.btn-primary:hover {
    background-color: #446;
    border-color: #446;
}

.page-link {
    color: #2e3192;
    padding: 8px 15px;
    font-family: BattambangRegular, RobotoRegular;
}

.page-item.active .page-link {
    border-color: #2e3192;
    background: #2e3192;
    z-index: 0;
    color: #fff;
}

.wrap-bg-image {
    background-color: #6bd3ff;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}

.platform-type-P99.wrap-bg-image {
    background-color: #239600;
}

.wrap-image-content {
    padding: 40px 55px;
    max-width: 1300px;
    margin: 0 auto;
}

.image-content-one {
    width: 100%;
    border-radius: 10px;
}

.image-content-child {
    width: 33.33%;
}

.form-login-title {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
}

.close {
    line-height: 1.1rem
}

.form-change-password {
    margin-top: 10px;
    font-size: 13px;
    height: 120px;
    float: left;
    width: 50%;
    border: 5px solid #dae4eb;
    background-color: #ffffff;
    margin-bottom: 150px;
}

.form-change-password tr td {
    line-height: 35px;
    font-weight: 800;
    font-size: 14px;
}

.form-change-password tr td:first-child,
.form-pick-number tr td:first-child {
    padding-left: 15px;
    text-align: left;
    color: #565555;
}

.form-change-password tr td.title-change-password {
    background: #3c5aa0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: bold;
    height: 40px;
    line-height: 25px;
}

.platform-888 .form-change-password tr td.title-change-password {
    background: #3E579D;
}


.form-change-password tr td.title-change-password div {
    font-family: BattambangRegular, RobotoRegular;
}

.label-change-password {
    width: 30%;
    font-family: BattambangRegular, RobotoRegular;
}

.form-change-password tr td p {
    font-family: BattambangRegular, RobotoRegular;
}

.form-input-change-password {
    float: left;
}

.form-input-change-password input {
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-left: 5px;
    margin-top: 3px;
    margin-bottom: 15px;
    padding: 0 5px;
    float: left;
    width: calc(100%);
}

.input-password > td {
    padding-top: 35px;
}

.wrap-btn-change-password {
    float: left;
}

.btn-game {
    font-family: BattambangRegular, RobotoRegular;
    margin: 0px 5px;
    color: #ffffff;
    background-color: #0b00a7;
    border: 1px solid #0b00a7;
    border-radius: 3px;
    box-shadow: 0px 0px 2px #567DCA;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 18px 5px 18px;
}

.platform-888 .btn-game {
    background-color: #567DCA;
    border: .06vw solid #567DCA;
}

.btn-game-bottom {
    margin-bottom: 100px;
}

.table-container {
    /*padding: 15px;*/
}

.table-container .table-custom {
    width: 100%;
}

.table-container .table-custom,
.table-container .table-custom th,
.table-container .table-custom td {
    border-collapse: collapse;
    border: 1px solid #ccc;
    text-align: center;
    font-family: BattambangRegular, RobotoRegular;
}

.table-container .table-custom td {
    line-height: 30px;
    font-family: BattambangRegular, RobotoRegular;
}

.table-container .table-custom th {
    line-height: 40px;
    background-color: #0b00a7;
    color: #ffffff;
    font-family: BattambangRegular, RobotoRegular;
}

.platform-888 .table-container .table-custom th {
    background-color: #3E579D;
}

.wrapper-filter-list {
    line-height: 52px;
    padding: 6px 18px;
    background-color: #eeeeee;
}

.form-date-range-group {
    float: left;
}

.form-date-range {
    display: inline-block;
}

.form-date-range label {
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
}

.input-date-range {
    color: #111;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.wrap-btn-filter {
    float: left;
    padding: 6px;
    line-height: 26px;
}

.bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: black;
    background: rgba(0, 0, 0, 0.2);
}

.modal-success {
    position: fixed;
    overflow-y: auto;
    z-index: 20;
    left: 35%;
    top: 250px;
    width: 30%;
    height: auto;
    border: 5px solid #dae4eb;
    background-color: #ffffff;
}

.wrap-message-row {
    margin: 20px auto;
    text-align: center;
}

.message-title {
    color: #111;
    font-size: 14px;
    font-family: BattambangRegular, RobotoRegular;
    text-align: center;
}

.bg-overlay-keno-result {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: black;
    background: rgba(0, 0, 0, 0.2);
}

.modal-keno-result {
    position: fixed;
    overflow-y: auto;
    z-index: 20;
    left: calc(50% - 375px);
    top: calc(50% - 200px);
    width: 750px;
    height: auto;
    border: 5px solid #ddbb22;
    background-color: #ffffff;
    border-radius: 1px;
}

.wrap-keno-header-row {
    background: #3c5aa0;
    float: left;
    width: 100%;
}

.btn-game-close {
    margin: 0;
    color: #ffffff;
    background-color: #dd2323;
    border: 1px solid #dd2323;
    padding: 3px 10px;
    text-decoration: none;
    cursor: pointer;
}

.wrap-keno-header-row .title {
    float: left;
    width: 50%;
    color: #fff;
    padding-left: 10px;
}

.wrap-keno-header-row .title p {
    font-family: BattambangRegular, RobotoRegular;
    color: #fff;
}

.wrap-keno-header-row .action-btn {
    float: left;
    width: 50%;
}

.bg-overlay-keno-betting {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}

.modal-keno-betting {
    overflow-y: auto;
    z-index: 20;
    width: 650px;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
}

.dialog-iframe .modal-keno-betting {
    width: 570px;
}

.bet-mix-dialog {
    width: auto !important;
}

.bet-mix-dialog .btn-bet-amount-red {
    width: 90px !important;
    height: 90px !important;
}

.lotto5-mix .modal-keno-betting, .lotto6-mix .modal-keno-betting, .lotto12-mix .modal-keno-betting {
    overflow-y: auto;
    width: 87%;
    z-index: 20;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 5px;
}

.modal-text-green {
    color: #24C121;
}

.wrap-keno-betting-header-row {
    background: #2c3a88;
    float: left;
    width: 100%;
    padding: 16px 16px;
    line-height: 2em;
    font-weight: 800;
}

.wrap-keno-betting-header-row .title {
    float: left;
    width: 50%;
    color: #fff;
}

.wrap-keno-betting-header-row .title p {
    font-family: BattambangRegular, RobotoRegular;
    color: #fff;
    margin-top: 5px;
}

.wrap-keno-betting-header-row .action-btn {
    float: left;
    width: 50%;
}

.btn {
    font-family: BattambangRegular, RobotoRegular;
}

.btn-game-close-betting {
    margin: 0;
    color: #ffffff;
    background-color: #dd2323;
    border: 1px solid #dd2323;
    padding: 3px 10px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: BattambangRegular, RobotoRegular;
}

.wrap-keno-betting-row {
    float: left;
    width: 100%;
    padding: 5px 10px;
}

.wrap-keno-betting-row .cols {
    float: left;
    width: 50%;
    text-align: center;
    font-weight: bold;
    color: #333;
    font-family: BattambangRegular, RobotoRegular;
}

.btn-game-bet {
    font-family: BattambangRegular, RobotoRegular;
    color: #ffffff;
    background-color: #24C121;
    padding: 15px 14px;
    font-size: 13px;
    cursor: pointer;
    border-radius: 4px;
    width: 150px;
    height: 100%;
    margin-bottom: 25px;
}

.btn-game-reset {
    font-family: BattambangRegular, RobotoRegular;
    color: #ffffff;
    background-color: #bb2323;
    padding: 15px 14px;
    font-size: 13px;
    cursor: pointer;
    border-radius: 4px;
    width: 150px;
    height: 100%;
    margin-bottom: 25px;
}

.wrap-keno-betting-row .body {
    font-family: BattambangRegular, RobotoRegular;
    padding: 20px;
    background: #233399;
    border-radius: 10px;
}

.btn-bet-amount-red {
    background: #fffa00;
    color: #f00;
    width: 115px;
    height: 115px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 68px;
    margin: 0 1% 10px 1%;
    cursor: pointer;
    transition: all 0.5s linear;
    font-size: 2em;
    font-weight: 800;
    font-family: BattambangRegular, RobotoRegular;
    box-shadow: 3px 3px 3px #bb9923;
}

.lotto5-mix .btn-bet-amount-red, .lotto6-mix .btn-bet-amount-red, .lotto12-mix .btn-bet-amount-red {
    width: 90px !important;
    height: 90px !important;
}

.btn-bet-amount-red:hover {
    background: #e02323;
    transition: all 0.5s linear;
    color: #fffa00;
}

.modal-bet-amount-row {
    float: left;
    width: 100%;
    padding: 5px 35px;
    float: left;
    width: 100%;
    padding: 5px 35px;
    /* margin-bottom: 25px; */
}

.col-left-modal-bet {
    float: left;
    width: 30%;
    text-align: left;
    font-weight: bold;
    color: #fff;
    padding: 0 0 10px 0;
    margin-top: 14px;
}

.col-right-modal-bet {
    float: left;
    width: 70%;
    text-align: left;
    font-weight: bold;
    color: #333;
    padding: 0 0 10px 0;
}

.modal-bet-amount-row .cols {
    float: left;
    width: 50%;
    text-align: left;
    font-weight: bold;
    color: #333;
    padding: 0 0 10px 0;
}

.modal-bet-amount-row .cols.title {
    line-height: 32px;
    color: #fff;
    font-family: BattambangRegular, RobotoRegular;
}

.input-bet-amount-red {
    width: 100%;
    height: 49px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #232389;
    border-radius: 4px;
    /* font-size: 2em; */
}

.input-bet-amount-red span {
    font-size: 17px !important;
    color: #33d600 !important;
}

button:focus {
    outline: 0 dotted;
    outline: 0 auto -webkit-focus-ring-color;
}

.wrap-keno-betting-row .cols span {
    color: #333;
}

.win-footer {
    background: #eee;
}

.win-footer td p,
.win-footer td.text-center span,
.win-footer td.text-right span {
    font-size: 14px;
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
}

.datail-footer {
    background: #eee;
}

.datail-footer td p,
.datail-footer td.text-center span,
.datail-footer td.text-right span {
    font-size: 14px;
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
}

.number-bold {
    font-size: 12px;
    font-family: BattambangRegular, RobotoRegular;
}

.datail-footer td {
    padding: 5px;
}

.draw-code-recent-bet {
    font-weight: 600;
}

.ticket-code-recent-bet {
    text-decoration: underline;
    cursor: pointer;
}

.my-printer-btn {
    line-height: 0.6;
}

table.printer tr td,
table.printer tr td span {
    font-weight: bold;
    color: #000;
}

@media print {
    .printer {
        display: block;
    }
}


table.printer tr td {
    padding: 5px;
}


.btn-game-green {
    font-family: BattambangRegular, RobotoRegular;
    margin: 0 5px;
    color: #ffffff;
    background-color: #567DCA;
    border: .06vw solid #567DCA;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 18px 5px 18px;
}

.lottery-type label, .lottery-type select {
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
}

.lottery-type select {
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px 5px;
    height: 82%;
    width: 150px;
    margin-top: 6px;
    border-radius: 5px;
}

.react-datepicker-popper {
    z-index: 999 !important;
}

.bg-red {
    background-color: #ff0000 !important;
}

.container {
    width: 90vw;
    display: table;
    border-collapse: collapse;
}

.left-side, .right-side {
    border: 1px solid #f1f1f1 !important;
    border-radius: 0.35vw;
}

.left-side {
    padding: 30px;
}

.right-side {
    padding-bottom: 15px;
    padding-left: 15px;
}

.time-sec {
    background: #f7f7f7;
    font-size: 2.5vw;
    font-weight: 600;
    line-height: normal;
    color: #666;
    padding: 0 10px;
    border-radius: 5px;
}

.time-sec-lo90v1 {
    /*background: #f7f7f7;*/
    font-size: 1.5vw;
    font-weight: 600;
    line-height: normal;
    color: black;
    padding: 0 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.date-sec, .ticket-sec {
    font-size: 1vw !important;
    color: #666;
    line-height: 1;
    font-weight: 700;
}

.lotto-name {
    color: #00a2ff;
    font-size: 28px;
    font-weight: 800;
    padding-left: 0.3vw;
}

.post-sec {
    margin: 0.3vw;
    width: 9vw;
}

.post-spacial-sec {
    margin: 0.3vw;
    width: 8.5vw;
}

.post-a {
    background: #f73;
    border-radius: 0.3vw;
    height: 3.4vw;
    padding: 0.1vw;
}

.post-a span {
    padding: 0.5vw !important;
}

.post-spacial {
    height: 3.4vw;
    background: #f73;
    border-radius: 0.3vw;
}

.post-spacial-value {
    font-size: 2vw;
    margin: 0.1vw !important;
    align-items: center !important;
    justify-content: center !important;
    background: white;
    border-radius: 0.3vw;
    font-weight: bold;
    min-width: 3vw;
    line-height: 3.3vw;
}

.post-label {
    font-size: 2.5vw;
    line-height: 2.5vw;
    border-radius: 0.3vw;
    color: #555;
    font-weight: bold;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.8vw;
    padding-right: 0.8vw;
    min-width: 4vw;
}

.post-value {
    font-size: 2.5vw;
    line-height: 1.3vw;
    border-radius: 0.3vw;
    text-align: right !important;
    padding: 1vw;
    color: white;
    font-weight: bold;
}

.post-spacial-label {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3vw;
    font-size: 1vw;
    color: white;
    border-radius: 0.3vw;
    font-weight: bold;
}

@keyframes lo90_ani_number {
    from {
        background-color: #00a2ff;
        color: #fff;
    }
    to {
        background-color: #fff;
        color: #666;
    }
}

.num-circle {
    height: 2.8vw;
    width: 2.8vw;
    border-radius: 50%;
    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
    line-height: 3vw;
    color: white;
    background: #00a2ff;
    margin-right: 0.2vw;
    margin-left: 0.3vw;
    margin-top: 0.4vw;
    transition: all .2s ease-out;
    /*border: 1px solid #00a2ff;*/
}

.num-circle-lo90v1 {
    height: 2.8vw;
    width: 2.8vw;
    border-radius: 50%;
    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
    line-height: 3vw;
    color: white;
    background: #00a2ff;
    margin-right: 0.2vw;
    margin-left: 0.3vw;
    margin-top: 0.4vw;
    transition: all .2s ease-out;
    /*border: 1px solid #00a2ff;*/
}


.num-circle.drawing {
    background: #fff !important;
    color: #666;
    border: 1px solid #ddd;
}

.num-circle-lo90v1.drawing {
    background: transparent;
    color: #666;
    border: 1px solid #ddd;
}

.drawing.active {
    background: #00a2ff !important;
    color: #fff;
    border: 1px solid #00a2ff;
    /*animation: lo90_ani_number 1s;*/

}

.lotto90-bet .num-circle {
    background: #fff;
    color: #666;
    border: 1px solid #ddd;
}

.lotto90-bet-v1 .num-circle-lo90v1 {
    background: transparent;
    color: #585858;
    font-family: Arial, serif;
    font-weight: bold;
    border: 0;
}

.lotto90-bet .num-circle:active, .lotto90-bet .num-circle.active {
    background: #00a2ff;
    color: #fff;
    border: 1px solid #00a2ff;
}

.lotto90-bet-v1 .num-circle-lo90v1:active, .lotto90-bet-v1 .num-circle-lo90v1.active {
    background: #00a2ff;
    color: #fff;
    border: 1px solid #00a2ff;
}

.lotto90-bet .num-circle:hover {
    transform: scale(1.08);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

.lotto90-bet-v1 .num-circle-lo90v1:hover {
    transform: scale(1.08);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

.num-circle-ticket {
    width: 2.5vw;
    height: 2.5vw;
    line-height: 2.7vw;
    text-align: center;
    font-size: 1.5vw;
    color: #fff;
    border-radius: 50%;
    background-color: #f73;
    float: right;
    margin-left: 0.5vw;
    font-weight: bold;
}

#drawing-ball.num-circle-ticket {
    position: absolute;
    top: 10px;
    font-size: 11em;
    font-weight: bold;
    line-height: 1.4em;
    width: 1.3em;
    height: 1.3em;
    box-shadow: 4px 5px 28px -1px rgba(0, 0, 0, 0.82);
    -webkit-box-shadow: 4px 5px 28px -1px rgba(0, 0, 0, 0.82);
    -moz-box-shadow: 4px 5px 28px -1px rgba(0, 0, 0, 0.82);
}

.tb-number {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 55vw;
}

.table-wrapper {
    display: flex;
    justify-content: center;
}

.total-sec {
    color: white;
    background: #00a2ff;
    border-radius: 7px;
    font-size: 2vw;
    line-height: 2.2vw;
    padding-left: 0.3vw;
    padding-right: 0.3vw;
    font-weight: 900 !important;
    padding-top: 0.1vw;
}

.total-draw-sec {
    display: inline-block;
    float: left;
    text-align: center;
    line-height: 1.5vw;
    color: #fff;
    background-color: #00a2ff;
    font-size: 1.5vw;
    border-radius: 5px;
    margin-left: 0.3vw;
    margin-top: 0.3vw;
    padding: 0.3vw !important;
    font-weight: bold;
    padding-top: 0.4vw !important;
}

.f-res {
    font-weight: 600;
    color: #666;
    font-size: 1vw;
    padding: 0.3vw !important;
}


.animate-slide-up {
    animation: slide-up 500ms ease-out;
}

#history-12 td, #history-12 th, #history-5 td, #history-5 th, #history-6 td, #history-6 th {
    padding: 0.3rem 1rem;
}

.result-number-cycle {
    width: 1.8vw;
    height: 1.8vw;
    line-height: 1.8vw;
    background-color: orange;
    margin: .1vw;
    font-size: 1vw !important;
    /*padding-left: 5px;*/
    /*padding-right: 3px;*/
    border-radius: 50%;
    display: inline-block;
    color: white !important;
}

.lotto90 .result-number-cycle {
    height: 1.5vw;
    width: 1.5vw;
    font-size: .8vw !important;
    line-height: 1.5vw;
    padding: 0;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px !important;
}

.f-result {
    font-size: 18px;
    color: #567DCA;
}

.f-15 {
    font-size: 15px;
}

.f-14 {
    font-size: 14px;
}


.grey-l {
    color: #9a9da2;
}

.bg-tr-his {
    background-color: #c1d8fa !important;
}

.num-color {
    background-color: #00C1FF;
}

.result-history {
    width: 1.7vw;
    height: 1.7vw;
    display: inline-block;
    background-color: #03c5f1;
    margin: 0.1vw;
    line-height: 1.9vw;
    text-align: center;
    /*padding-left: 5px;*/
    /*padding-right: 3px;*/
    border-radius: 50%;
    color: white !important;
}

.bg-orange {
    background-color: orange;
}

.lotto90 .tb-number {
    max-width: 20vw;
    justify-content: center;
}

.w-result-his {
    width: 22% !important;
}

.badge-secondary {
    color: #fff !important;
}

.print-tb-58-1 {
    width: 260px !important;
}

.label-spe {
    min-width: 5vw;
    text-align: left;
}

.result-ml {
    margin-left: 2vw;
}

#print-58 .win-animate, #print-58-6n .win-animate, #print-58-5d .win-animate {
    background-color: black !important;
}

#print-58 .text-danger, #print-58-6n .text-danger, #print-58-5d .text-danger {
    color: black !important;
}

#print-58 .bg-win {
    background-color: white !important;
}

.f-t {
    font-size: 14px;
}

#print-ticket-58 .win-animate, #print-58-5d .win-animate {
    background-color: black !important;
}

#print-ticket-58 .bg-win, #print-58-5d .bg-win {
    background-color: white !important;
}

.black-c {
    color: black;
}

.w-bet-card-ticket {
    margin-bottom: 3px;
    width: 100%;
    margin-left: 0;
    padding: 0;
}

.f-print {
    font-size: 18px !important;
}

.mar-l-1 {
    margin-left: 10px;
}

.mar-l-2 {
    margin-left: 20px;
}

.mr-x {
    margin-right: 10px;
}

.mr-xd {
    margin-right: 15px;
}

.mar-l-3 {
    margin-left: 30px;
}

.mar-l-35 {
    margin-left: 35px;
}

.mar-l-4 {
    margin-left: 40px;
}

.h-col-1 {
    height: 20px;
}

.h-col-2 {
    height: 60px;
}

.h-col-3 {
    height: 60px;
}

.h-col-4 {
    height: 80px;
}

.h-col-5 {
    height: 100px;
}

.h-col-6 {
    height: 125px;
}

.h-col-7 {
    height: 150px;
}

.h-col-8 {
    height: 165px;
}

.h-col-9 {
    height: 185px;
}

.h-col-10 {
    height: 205px;
}

.h-t-1 {
    height: 25px;
}

.h-t-2 {
    height: 55px;
}

.h-t-3 {
    height: 82px;
}

.h-t-4 {
    height: 115px;
}

.h-t-5 {
    height: 150px;
}

.h-t-6 {
    height: 180px;
}

.h-t-7 {
    height: 210px;
}

.h-t-8 {
    height: 240px;
}

.h-t-9 {
    height: 270px;
}

.h-t-10 {
    height: 300px;
}

.expanded-ticket {
    height: calc(100% - 55px);
}

.expanded {
    height: calc(100% - 35px);
}

.hr-vert {
    border: none;
    border-left: 1px solid black;
    height: 22px;
    text-align: right;
    padding-right: 8px;
}

.ml-hr-ticket {
    margin-left: 6px;
    padding: 0;
}

.hr-right {
    border-right: 1px solid black;
    height: 22px;
    text-align: right;
}

.hr-right-ticket {
    border-right: 1px solid black;
    height: 25px;
    text-align: right;
}

.win-animate {
    animation-name: backgroundColorPalette;
    animation-duration: 500ms;
    border-radius: 50%;
    text-align: center;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    color: white;
    height: 18px;
    width: 18px;
    line-height: 22px;
}

@keyframes backgroundColorPalette {
    0% {
        background: orangered;
    }
    100% {
        background: #ff9b85;
    }
}

.w-left-side {
    width: 156px;
    /*background-color: rebeccapurple;*/
}

.mb-bet-1 {
    margin-bottom: -4px;
}

.mb-bet-2 {
    margin-bottom: -5px;
}

.mb-bet-3 {
    margin-bottom: -6px;
}

.num-color {
    background-color: #03c5f1;
}

.f-25 {
    font-size: 25px !important;
}

.f-19 {
    font-size: 19px !important;
}

.dotted {
    border-top: 2px dashed black;
    color: transparent;
    padding: 0;
    margin: 0;
}

.solid {
    border-top: 1px solid black;
    color: transparent;
    padding: 0;
    margin: 0;
}


.f-result {
    font-size: 18px;
    color: #2159c9;
}

.post-wrapper {
    padding: 0 6vw;
    display: flex;
    justify-content: space-between;
    margin-top: 31px;
    flex-wrap: wrap;
    margin-bottom: 17px;
}

.post-circle {
    height: 6vw;
    width: 6vw;
    border-radius: 50%;
    background-color: #E4E4E4;
    font-weight: bold;
    font-size: 4vw;
    text-align: center;
    color: #145FCD;
    line-height: 6.2vw;
    transition: all .2s ease-out;
}

.post-circle:hover {
    transform: scale(1.1);
}

.post-circle.active {
    background-color: #145FCD;
    color: white;
}

.lotto90-bet input[type="radio"] {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
}

.lotto90-bet-v1 input[type="radio"] {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
}

.s-wrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.special-card {
    border: 2px solid gray;
    border-radius: 15px;
    cursor: grab;
}

.special-card.active {
    border: 2px solid red;
    border-radius: 15px;
}

.special-card .code {
    padding: .8vw;
    font-size: 1.2vw;
    font-weight: bold;
    color: black;
}

.special-card .value {
    height: 50px;
    color: white;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.special-card .value.UNDER {
    background: #003ad6;
}

.special-card .value.OVER {
    background: #ff0000;
}

.special-card .value.ODD {
    background: #71d54c;
}

.special-card .value.EVEN {
    background: #2fa702;
}

.under-over-odd-even .value {
    background: #0a73cc;
}

.range .value {
    background: #00a5ef;
}

.small-big .value {
    background: #6dc8f0;
}


.special-card.over-under {
    width: 23%;
}

.special-card.range {
    width: 18%;
}

.special-card.small-big {
    width: 15%;
}

.special-card .value {
    font-weight: 600;
}

.special-card .value .r-number {
    font-weight: bold;
    font-size: .9vw;
}

.price-wrapper {
    margin-top: 10px;
}

.price-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 10px;
    /*width: 100%;*/
}

.bet-price {
    font-weight: 900;
    padding: 5px;
    border: 1px solid grey;
    margin-left: 5px;
    border-radius: 20px;
    width: 81px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bet-price:active {
    background-color: #0C1163 !important;
    color: white;
}

.bet-price:hover {
    transform: scale(1.1);
    background-color: #0C1163 !important;
    color: white;
}

.bg-green {
    background-color: #04b65d !important;
}

.btn-wrapper button {
    width: 5vw;
    margin-right: .5vw !important;
}

.border-total {
    border-bottom: 1px solid red;
}

.bet-card-wrapper {
    display: flex;
    justify-content: center;
}

.bet-card {
    width: 200px;
}

.lotto90 .recent-ticket td {
    border: none !important;
}

.lotto90 .recent-ticket {
    height: 33.5vw;
    overflow: scroll;
}

.lotto90 .table-container {
    padding: unset !important;
}

.lotto90 .table-container .w-result-his {
    width: 25% !important;
}

.f-koulen {
    font-family: 'Koulen', sans-serif !important;
}

.f-bayon {
    font-family: 'Bayon', sans-serif !important;
}

.f-arial-black {
    font-family: "Arial Black", serif !important;
}

.bb-solid-1 {
    border-bottom: 1px solid black !important;
}

.l-h-25 {
    line-height: 25px !important;
}


.tb-lotto .table-striped > tbody > tr:nth-child(even) > td,
.tb-lotto .table-striped > tbody > tr:nth-child(even) > th {
    background-color: #F0F0F0;
}

.tb-lotto .table-striped > tbody > tr:nth-child(odd) > td,
.tb-lotto .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #ffffff;
}

.mr-ra {
    margin-right: .3vw;
}

.f-vw-v1 {
    font-size: 1vw;
    font-weight: bold;
    font-family: "Khmer OS Content", serif;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer !important;
}

.none-even {
    pointer-events: none !important;
}