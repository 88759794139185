@font-face {
    font-family: BattambangRegular;
    src: url('../fonts/Battambang/Battambang-Regular.ttf');
}

@font-face {
    font-family: BattambangBold;
    src: url('../fonts/Battambang/Battambang-Bold.ttf');
}

@font-face {
    font-family: RobotoRegular;
    src: url('../fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: RobotoBold;
    src: url('../fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
    font-family: KhmerOSKoulen;
    src: url('../fonts/KhmerOS/KhmerOSKoulen.ttf');
}

@font-face {
    font-family: KhmerOSContent;
    src: url('../fonts/KhmerOS/KhmerOSContent.ttf');
}

@font-face {
    font-family: "Arial Black";
    src: url('../fonts/ariblk.ttf');
}

body {
    background: #eeeeee;
    display: block !important;
    font-size: 16px;
    min-width: 100%;
    font-family: BattambangRegular, RobotoRegular;
}


.main-w {
    width: 100%;
    height: auto;
    text-align: center;
    margin: 0 auto;
    display: block;
}

/*.w-100 {*/
/*    width: 100%;*/
/*    height: auto;*/
/*    text-align: center;*/
/*    margin: 0 auto;*/
/*    display: block;*/
/*}*/

.w-1200 {
    width: 100%;
    max-width: 100%;
    height: auto;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.jackpot {
    width: 100%;
    max-width: 706px;
    height: auto;
    text-align: center;
    position: absolute;
    right: 161px;
    top: 171px;
    display: block;
    z-index: 99999;
}

.jackpot ul {
    display: flex;
}

.jackpot ul li {
    display: inline-block;
    flex: 1;
    font-size: 50px;
    font-weight: 600;
    color: #ffffff;
}

.data-not-found-img {
    width: 14.5vw;
}

.img-responsive {
    width: 100%;
    height: auto;
}

.img-result-lotto12 {
    width: 30% !important;
    height: auto !important;
}

/*.position-auto-print {*/
/*    position: absolute;*/
/*    right: 0px;*/
/*}*/

.enable-auto-print {
    background: green;
    color: white !important;
    border-radius: 5px;
}

.pointer {
    cursor: pointer;
}

.rebate-rate {
    position: absolute;
    background: white;
    color: black;
    font-size: 1em;
    border-radius: 10px;
    width: 70px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.3;
    padding-top: 1px;
}

.lotto12-mix .color-items {
    position: absolute;
    background: white;
    color: black;
    font-size: 1em;
    border-radius: 3px;
    left: 0;
    right: 0;
    top: 10vw;
    margin-left: 5px;
    margin-right: 5px;
    line-height: 1.3;
    padding-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
}

.lotto12-mix .color-items span {
    font-size: 3vw !important;
}

.rebate-under_over, .rebate-odd_even {
    bottom: 7px;
}

.rebate-color {
    bottom: 32px;
}

.lotto12-mix .rebate-color {
    bottom: 16px;
}

.rebate-number {
    bottom: 9px;
    font-size: 0.8em;
    background: red;
    color: #fff;
    width: 40px;
    line-height: 1.2;
    padding-top: 1px;
}

.lotto12-mix .rebate-number {
    bottom: 7px;
    font-size: 13px;
    background: red;
    color: #fff;
    width: 40px;
    line-height: 1.2;
    padding-top: 1px;
}

.jackpot-value {
    background-color: #eea91b;
    color: white !important;
    border-radius: 10px;
    padding: 1px 10px;
}

.sweet-alert {
    width: 50em !important;
}

.platform-type {
    background-color: blue;
    color: #fff !important;
    padding: 0px 10px;
    border-radius: 14px;
}

.header-label {
    font-size: 18px !important;
    color: white !important;
    line-height: 51px !important;
    font-family: RobotoBold;
}

.modal-body {
    padding: 0 !important;
}

.modal-content {
    border: none !important;
}

.f-bold {
    font-weight: 600 !important;
}

.web-version {
    position: absolute;
    right: 50px;
    margin-top: 10px;
}

#main-screen {
    background-color: white;
}

.lotto90 .sweet-alert {
    width: 25em !important;
}

.h-lotto .platform-888 {
    width: 30% !important;
}

