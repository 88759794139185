div, span, h1, h2, h3, h4, h5, h6, p, strong, b, i, ol, ul, li, tbody, tfoot, thead, tr, th, td, footer, header, menu, nav {
    font-family: BattambangRegular, RobotoRegular !important;
}

.no-padding-div {
    padding-right: 0;
    padding-left: 0;
}

.no-margin-div {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.no-padding-div-right {
    padding-right: 0;
    display: flex;
    flex-wrap: wrap;
}

.padding-div-right {
    padding-right: 22px;
}

.padding-div-left {
    padding-left: 15px;
}


.no-padding-div-left {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
}

.table td, .table th {
    color: black;
    padding: 0.3vw !important;
    /*line-height: 1.9vw;*/
}

.table td > b > span {
    color: black;
}

.text-black {
    color: black !important;
}

.text-white {
    color: #eeeeee !important;
}

.header-div-white {
    background-color: #ffffff;
}

.div-body-white {
    background-color: #ffffff;
}

.div-bg-gray {
    background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
}

/*
Header div style
*/
.header-div {
    padding: 10px;
    height: 55px;
    justify-content: space-between;
}

.header-div-blue-light {
    background-color: #DAE3EF;
    /*border-top: 1px solid #000;*/
    /*border-bottom: 1px solid #000;*/
}

.header-history-light {
    background-color: #DAE3EF;
    border-left: .06vw solid #567DCA;
    border-right: .06vw solid #567DCA;
}

.platform-999 .header-history-light {
    background-color: #FFBBBB;
}

.platform-999.GREEN .header-history-light {
    background-color: #CCF1BC;
}

.header-text {
    display: inline-flex !important;
    justify-content: space-between;
    text-transform: uppercase;
}

.header-div-title > span, .header-div-title > span > span {
    font-size: 1.1vw !important;
    line-height: 2;
    font-weight: bold;
    padding: 0 0.3vw;
    /*color: #21397B !important;*/
    font-family: BattambangBold, RobotoBold !important;
}

.platform-999 .header-div-title > span, .header-div-title > span > span {
    color: #21397B;
}

.lotto5-mix .header-div-title-mix > span,
.lotto5-mix .header-div-title-mix > span > span,
.lotto6-mix .header-div-title-mix > span,
.lotto6-mix .header-div-title-mix > span > span {
    font-size: 3vw !important;
    padding: 0.5vw !important;
    font-weight: bold;
    font-family: BattambangBold, RobotoBold !important;
}

.lotto5-mix .header-div-title > span,
.lotto5-mix .header-div-title > span > span {
    font-size: 2vw !important;
    padding: 0.5vw !important;
    font-weight: bold;

    /*color: #21397B !important;*/
    font-family: BattambangBold, RobotoBold !important;
}

.lotto12-mix .header-div-title > span, .lotto12-mix .header-div-title > span > span {
    font-size: 2vw !important;
    font-weight: bold;
    padding: 0vw;
    /*color: #21397B !important;*/
    font-family: BattambangBold, RobotoBold !important;
}

.lotto6-mix .header-div-title > span {
    font-size: 3vw !important;
    font-weight: bold;
    padding: 0.6vw !important;
    line-height: unset !important;
    font-family: BattambangBold, RobotoBold !important;
}

.lotto6-mix .header-div-title {
    font-size: 3vw !important;
    font-weight: bold;
    /*padding: 0.3vw !important;*/
    line-height: unset !important;
    font-family: BattambangBold, RobotoBold !important;
}

.header-div-title > span > span {
    color: #21397B;
    padding-left: 4px !important;
}

.lotto6-mix .header-div-title > span {
    font-size: 3vw !important;
    font-weight: 800;
    color: #fff;
    font-family: BattambangBold, RobotoBold !important;
}


.kh-font-bold, .kh-font-bold > b {
    font-family: BattambangBold, RobotoBold !important;
}

/*
Card style
*/
.card-border {
    border: .06vw solid #567DCA;
}

.card-border-left {
    border-left: .06vw solid #567DCA;;
}


.wrapper-game-content {
    display: block;
    min-width: 1200px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin: 0;
    font-family: BattambangRegular, RobotoRegular;
    background: #fff;
}

.wrapper-game-content-login {
    display: block;
    min-width: 1200px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin: 0;
    font-family: BattambangRegular, RobotoRegular;
}

.wrapper-new-keno {
    display: block;
    min-width: 1200px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin: 0;
    font-family: BattambangRegular, RobotoRegular;
    min-height: 100vh;
    background-color: #d4d4d4;
}


.wrap-keno-content {
    min-height: 100vh;
    background-color: #ffffff;
    margin-top: 2px;
}

.dashboard-padding-left {
    padding-left: 10px !important;
}

.dashboard-line-height {
    line-height: 25px;
}


.wrap-section-title-dashboard {
    display: inline-flex;
    width: 100%;
}

.btn-refresh-dashboard {
    text-align: right;
    line-height: 30px;
    width: 25%;
}

.btn-refresh-dashboard img {
    width: 1vw;
    cursor: pointer;
}

.platform-999 .btn-refresh-dashboard img {
    width: 1.5vw;
    cursor: pointer;
}

.btn-refresh-dashboard img.rotate {
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
    transition: all 0.5s linear;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.result-list-now {
    padding: 0;
    margin: 0;
}

.result-list-now li {
    list-style-type: none;
    display: block;
    overflow: hidden;
    font-weight: 600;
    padding: 3px 15px;
    border-bottom: 1px solid #eeeeee;
}

.recent-betting-result > tr > td {
    border-bottom: 1px solid #eeeeee;
}

/* .result-list-now li:nth-child(even) {
    background-color: #e9e9f3;
} */

.result-list-now li .result-now,
.result-list-now li .result-total {
    display: block;
    margin: 0;
    font-size: 12px;
    font-family: BattambangRegular, RobotoRegular;
    color: #111;
}

.result-list-now li .result-now {
    float: left;
    margin-top: 3px;
}

p.result-now,
p.result-total {
    height: 24px !important;
    line-height: 24px !important;
}

.result-list-now li .result-total {
    float: right;
}

.keno-play-main {
    /* width: 750px; */
    overflow: hidden;
    margin-bottom: 0;
}

.keno-play-main .keno-dispaly-result {
    width: 645px;
    float: left;
}

.keno-dispaly-result .keno-logo,
.keno-dispaly-result .keno-total {
    width: 100%;
    float: left;
    height: auto;
}

.keno-dispaly-result .keno-logo {
    background: linear-gradient(to bottom, #f9e619 7%, #fcf4ab 35%, #ffee2f 76%);;
    height: 60px;
}

.keno-dispaly-result .keno-logo img {
    /* width: 100%; */
    height: 60px;
    margin: 0 auto;
    display: block;
}

img {
    border: 0;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}

.loading-gif img {
    /*margin-top: 10px;*/
}

.lotto5-mix .loading-gif img {
    margin-top: 6px;
}

.keno-dispaly-result .keno-total {
    background: linear-gradient(to bottom, #f9e619 7%, #fcf4ab 35%, #ffee2f 76%);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    color: #111;
    height: 60px;
}

.keno-dispaly-result .keno-total .period-result {
    font-size: 15px;
    font-weight: 700;
    color: inherit;
    font-family: BattambangRegular, RobotoRegular;
    text-align: center;
    display: block;
    margin: 0;
    line-height: 1.5;
}

.keno-row {
    width: 100%;
    display: block;
    overflow: hidden;
    padding: 5px 10px 0;
}

.keno-dispaly-result .keno-total .label-title {
    font-size: 14px;
    font-family: BattambangRegular, RobotoRegular;
    color: inherit;
    float: left;
    line-height: 1.5;
}

.keno-dispaly-result .keno-total .total-result {
    font-size: 14px;
    font-family: BattambangRegular, RobotoRegular;
    color: inherit;
    float: right;
    margin: 0;
    line-height: 1.5;
}

.keno-content-result {
    width: 100%;
    overflow: hidden;
    display: block;
}

.platform-999 .total-result {
    color: #21397B !important;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: middle;
    background: transparent;
}

table {
    border-collapse: collapse;
}

.keno-content-result table td {
    border: 1px solid #080808;
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    background: #ffffff;
    overflow: hidden;
    color: #000;
}

.keno-content-result table td span {
    padding: 0;
    margin: 0;
    display: block;
    line-height: 55px;
    color: #000;
    font-size: 14px;
    font-family: BattambangRegular, RobotoRegular;
    font-weight: 600;
}

@keyframes box_number_animation {
    from {
        background-color: #6c0111;
        color: #fff;
    }
    to {
        background-color: #ec0024;
        color: #fff;
    }
}

/*table#keno_result_post td span.active {*/
/*    animation: box_number_animation 2s;*/
/*    background: #E60000;*/
/*    color: #fff;*/
/*}*/

.keno-play-main .keno-play-right {
    width: 555px;
    float: left;
}

.keno-play-right .random-play {
    width: 160px;
    display: block;
    float: left;
}

.keno-play-right .random-play .loading-gif {
    height: 60px;
    width: 100%;
    display: block;
    border-bottom: 1px solid #878787;
    background: linear-gradient(to bottom, #004df9 7%, #1e6dff 35%, #0055f1 76%);
    /* background: linear-gradient(to bottom, #f9e619 7%, #fcf4ab 35%, #ffee2f 76%); */
    /* border: 1px solid #bbaa00; */
    text-align: center;
    /* background: linear-gradient(to bottom, rgb(44, 49, 66) 0%, rgb(44, 49, 67) 50%, rgb(76, 77, 78) 100%); */
}

.loading-gif .timer-loading {
    margin: 0;
    display: block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    font-family: BattambangRegular, RobotoRegular;
    line-height: 24px;
}

.blgo-random-number {
    position: relative;
    width: 100%;
    margin-left: 0;
    display: block;
    /*background: linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%);*/
}

.bet-period-timer {
    width: calc(100% - 4px);
    display: block;
    height: 58px;
    background: linear-gradient(to bottom, #f9e619 7%, #fcf4ab 35%, #ffee2f 76%);
    color: #ffdd00;
}

.bet-period-timer #period-before {
    float: left;
    font-size: 14px;
    font-family: BattambangRegular, RobotoRegular;
    margin: 0;
    padding-left: 10px;
    line-height: 57px;
}

.bet-period-timer #timer-display {
    float: right;
    font-size: 1.5vw !important;
    font-family: BattambangRegular, RobotoRegular;
    margin: 0;
    padding-right: 10px;
    /*line-height: unset !important;*/
}

.bet-period-timer #timer-display-mix {
    float: right;
    font-size: 3vw !important;
    font-family: BattambangRegular, RobotoRegular;
    margin: 0;
    padding-right: 2vw;
    /*line-height: unset !important;*/
}

table#keno_result_post td.active {
    background: #bbaa00;
}

.keno-play-right .blog-play {
    width: calc(89% - 100px);
    height: 60px;
    float: left;
}

.over-under-odd-even {
    border-left: 0.06vw solid #567DCA;
    width: calc(100% - 0px);
    display: block;
    overflow: hidden;
}

.group-play-box {
    width: 50%;
    display: block;
    float: left;
    border-bottom: 0.06vw solid #567DCA;
}

.lotto5-mix .group-play-box {
    width: 100%;
    display: inline-flex;
    float: left;
}

.over-under-odd-even label {
    display: block;
    text-align: center;
    font-weight: bold;
    line-height: 56px;
    cursor: pointer;
    font-size: 16px;
    background: linear-gradient(to bottom, #c5c5c5 0%, #e7e7e7 50%, #c5c5c5 100%);
    border: 1px solid #ccc;
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
}

.over-under-odd-even label.range-lable {
    display: block;
    text-align: center;
    font-weight: bold;
    line-height: 55px;
    cursor: pointer;
    font-size: 16px;
    background: linear-gradient(to bottom, #c5c5c5 0%, #e7e7e7 50%, #c5c5c5 100%);;
    border: 1px solid #ccc;
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
}

.over-under-odd-even .box-bet {
    width: 50%;
    float: left;
    display: block;
    background: #f0cece;
    border: none;
    cursor: pointer;
}

.platform-999 .bg-OU-UNDER {
    border: 0.057vw solid #21397B !important;
    background: #003CFF
}

.platform-999 .bg-OU-OVER {
    border: 0.057vw solid #21397B !important;
    background: #C50808
}

.platform-999 .bg-OU-ODD, .platform-888 .bg-OU-ODD {
    border: 0.057vw solid #21397B !important;
    background: #F16522
}

.bg-OU-EVEN, .platform-888 .bg-OU-EVEN {
    border: 0.057vw solid #21397B !important;
    background: #FAAF40
}

.bg-range-UNDER {
    border: 0.057vw solid #21397B !important;
    background: #324DFF40;
}

.bg-range-OVER {
    border: 0.057vw solid #21397B !important;
    background: #D0232240;
}

.bg-range-ODD {
    border: 0.057vw solid #21397B !important;
    background: #F4753740;
}

.bg-range-EVEN {
    border: 0.057vw solid #21397B !important;
    background: #FAB93B40;
}

.border-r999 {
    border-right: 0.06vw solid #21397B !important;
}

.over-under-odd-even .box-bet-left,
.over-under-odd-even .box-bet-odd,
.over-under-odd-even .box-bet-under,
.over-under-odd-even .box-bet-4 {
    border-left: 1px solid #080808;
    border-right: 1px solid #080808;
    border-bottom: 1px solid #080808;
    border-top: 1px solid #080808;
}

.over-under-odd-even .box-bet-right,
.over-under-odd-even .box-bet-even,
.over-under-odd-even .box-bet-over,
.over-under-odd-even .box-bet-0 {
    border-left: none;
    border-right: 1px solid #080808;
    border-bottom: 1px solid #080808;
    border-top: 1px solid #080808;
}

.over-under-odd-even .box-bet-child,
.over-under-odd-even .box-bet-1,
.over-under-odd-even .box-bet-2,
.over-under-odd-even .box-bet-3 {
    border-bottom: 1px solid #080808;
    border-top: 1px solid #080808;
}

.over-under-odd-even .box-bet-2 {
    border-left: 1px solid #080808;
    border-right: 1px solid #080808;
}

.over-under-odd-even .box-bet {
    overflow: hidden;
    display: block;
    color: #000;
    text-decoration: none;
    /* height: 100%; */
    position: relative;
}

.over-under-odd-even .box-bet p {
    margin: 0;
    font-size: 10px;
    font-family: BattambangRegular, RobotoRegular;
    text-align: center;
    color: #111;
    /*line-height: 12px;*/
}

.over-under-odd-even .box-bet div .type {
    color: #fff;
    line-height: unset !important;
    font-size: 3vw !important;
    height: 5.1vw !important;
    border-bottom: 0.06vw solid #567DCA;
}

.over-under-odd-even .box-bet div .type div {
    font-family: KhmerOSKoulen, serif !important;
    font-size: 2vw !important;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}

.platform-999 .over-under-odd-even .box-bet div .type {
    font-family: KhmerOSKoulen, serif !important;
    font-size: 2vw !important;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    border-bottom: unset;
}

.lotto5-mix .over-under-odd-even .box-bet div .type {
    color: #fff;
    font-size: 5vw !important;
    height: auto !important;
    border-bottom: 0.06vw solid #567DCA;
    padding: 0.5vw !important;
}

@keyframes box_animation_over_win {
    from {
        background: linear-gradient(to bottom, #550000 0%, #990000 50%, #550000 100%);
        opacity: 1;
    }
    to {
        background: linear-gradient(to bottom, #ad0101 0%, #e71313 50%, #ad0101 100%);
        opacity: 0.8;
    }
}

.over-under-odd-even .box-bet div .over {
    opacity: 1;
    transition: all 1.8s linear;
    background: #ec0023;
    /* background: linear-gradient(to bottom, #990000 0%, #df1e1e 50%, #990000 100%); */
}

.over-under-odd-even .box-bet div .over.win-over {
    transition: all 1.8s linear;
    animation: box_animation_over_win 1.8s;
    background: linear-gradient(to bottom, #550000 0%, #990000 50%, #550000 100%);
}

@keyframes box_animation_under_win {
    from {
        background: linear-gradient(to bottom, #02006e 0%, #0501f1 50%, #02006e 100%);
        opacity: 1;
    }
    to {
        background: linear-gradient(to bottom, #0501f1 0%, #2b28f3 50%, #0501f1 100%);
        opacity: 0.8;
    }
}

.over-under-odd-even .box-bet div .under {
    opacity: 1;
    transition: all 1.8s linear;
    background: #0501f1;
    /* background: linear-gradient(to bottom, #0f00ab 0%, #5a41dd 50%, #0f00ab 100%); */
}

.over-under-odd-even .box-bet div .under.win-under {
    opacity: 1;
    animation: box_animation_under_win 1.8s;
    background: linear-gradient(to bottom, #02006e 0%, #0501f1 50%, #02006e 100%);
}

.over-under-odd-even .box-bet div .type-odd-even {
    color: #fff;
    line-height: 18px;
    margin-top: 9px;
}

.range {
    color: #ffee00;
    /*line-height: 18px;*/
}

/*.over-under-odd-even .box-bet p {*/
/*    !*margin: 0;*!*/
/*    !*margin-top: 8px;*!*/
/*    !*font-size: 16px;*!*/
/*    font-family: BattambangRegular, RobotoRegular;*/
/*    text-align: center;*/
/*}*/

.over-under-odd-even .box-bet .odds {
    color: #0a01f9;
    font-weight: bold;
    /*font-size: 14px;*/
    /*margin-top: 2px;*/
    /*line-height: 45px;*/
}

.group-play-box,
.range-type .group-play-box {
    width: 100%;
}

.over-under-odd-even .box-bet div .type-rage {
    color: #bb2233;
    font-size: 28px;
    line-height: 111px;
    margin-top: 0;
    background: #feffd6;
    opacity: 1;
    transition: all 1.8s linear;
}

.over-under-odd-even .box-bet div .type-rage.one,
.over-under-odd-even .box-bet div .type-rage.two,
.over-under-odd-even .box-bet div .type-rage.three,
.over-under-odd-even .box-bet div .type-rage.four,
.over-under-odd-even .box-bet div .type-rage.five {
    opacity: 0.5;
    transition: all 1.8s linear;
    background: #555;
}

.keno-history-result {
    /* margin-top: 10px; */
    width: 100%;
    /* width: 750px; */
    float: left;
}

.keno-history-result table td {
    height: 35px;
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    overflow: hidden;
    color: #000;
    background-color: #eadfcd !important;
    border: 1px solid #b7b3ac;
}

.keno-history-result table tbody tr td label {
    font-family: BattambangRegular, RobotoRegular;
}


.keno-history-result table td span {
    padding: 0;
    margin: 0;
    display: block;
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
}

.keno-history-result table td label {
    padding: 0;
    margin: 0;
    display: block;
    line-height: 55px;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    font-family: BattambangRegular, RobotoRegular;
}

.keno-history-result table td label.title {
    color: #111;
    background: linear-gradient(to bottom, #c5c5c5 0%, #e7e7e7 50%, #c5c5c5 100%);
    font-family: BattambangRegular, RobotoRegular;
}

.wrap-alert {
    text-align: left;
}

table.detail-report {
    font-size: 14px;
    font-weight: 700;
}

table.detail-report td, table.detail-report th {
    line-height: 30px !important;
}

.input-bet-amount {
    width: 100%;
    height: 45px;
    padding-left: 95px;
    padding-right: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.btn-bet-amount {
    background: linear-gradient(to bottom, #0078dd 0%, #00aaee 50%, #00d0ff 100%);
    color: #fff;
    font-weight: bold;
    width: 23%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 4px;
    margin: 0 1% 10px 1%;
    cursor: pointer;
    transition: all 0.5s linear;
}

.btn-bet-amount:hover {
    background: linear-gradient(to bottom, #0078dd 0%, #0078dd 50%, #00d0ff 100%);
}

.game-bet-info-left {
    display: inline-block;
    width: 60%;
}

.game-bet-info-right {
    display: inline-block;
    width: 40%;
}

.game-bet-info-right span {
    font-weight: bold;
}

.label-bet-info {
    font-weight: bold;
    line-height: 28px;
    color: #000;
    font-family: BattambangRegular, RobotoRegular;
}

.value-bet-info {
    font-weight: bold;
    line-height: 28px;
    color: #000;
    font-family: BattambangRegular, RobotoRegular;
}

.btn-confirm-bet {
    background: linear-gradient(to bottom, #585888 0%, #383868 100%);
    color: #fff;
    font-weight: bold;
    width: 48%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 4px;
    margin: 0 1% 0 1%;
    cursor: pointer;
    transition: all 0.5s linear;
}

.btn-confirm-bet:hover {
    background: linear-gradient(to bottom, #484878 0%, #282848 100%);
}

.total-result-render {
    width: 100%;
    line-height: 191px;
    font-weight: bold;
    margin: 0 auto;
}

.total-result-render-count {
    color: #fff !important;
    font-size: 32px;
    background: #414355;
    /* background: linear-gradient( to bottom, #f9c730 0%, #c3bc23 53%, #f9c730 100%); */
}

.platform-888 .total-result-render-count {
    /*background: linear-gradient(#323447, #3C3D4D, #46495D);*/
}

.platform-999 .total-result-render-count {
    background: #BC731F
}


.total-result-render-count-warning {
    color: #dd1122 !important;
    font-size: 32px;
    background: #eeeeee;
    /* background: linear-gradient( to bottom, #f9c730 0%, #c3bc23 53%, #f9c730 100%); */
}

.total-result-render-count-total {
    color: #fff !important;
    font-size: 32px;
    background: linear-gradient(to bottom, #990000 0%, #df1e1e 50%, #990000 100%);
}

.result-time-countup-render {
    width: 13%;
    font-size: 1.5vw !important;
    color: #fff;
    font-weight: bold;
    border-radius: 2px;
    background: #ad0026;
    margin: 2px;
    position: absolute;
}

.bg-transparent {
    background: transparent;
}

.show-logo-render {
    text-align: center;
    width: 98%;
    height: 168px;
    margin: 0 auto;
    display: block;
    background: linear-gradient(to bottom, #f9e619 0%, #ffffff 50%, #ffee2f 100%)
}

.hide-logo-render {
    display: none !important;
}

.show-number-draw, .show-logo-render img {
    width: 25vw;
    height: 25vw;
    /*margin: 31px auto;*/
}

.hide-number-draw {
    box-shadow: none;
}

.show-number-draw {
    text-align: center;
    font-size: 46px;
    line-height: 80px;
    font-weight: bold;
    color: #fff;
    border-radius: 70px;
    display: block;
    background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%);
    box-shadow: 0px 2px 10px #8d0026;
}

.btn-remove-betting {
    color: #fff;
    background: linear-gradient(#ff0026 0%, #ad0026 50%, #8d0026 100%);
    width: 30px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
}

.btn-remove-betting:hover {
    background: linear-gradient(#ff0026 0%, #ff0026 50%, #8d0026 100%);
    box-shadow: 0px 1px 4px #8d0026;
}

.line-height-betting {
    line-height: 30px !important;
}

.line-height-betting td {
    font-size: 15px !important;
    font-weight: bold;
}

.main-screen-iframe .line-height-betting {
    line-height: unset !important;
}

.statistic-ball-three {
    margin: 2px 2px 2px 3px !important;
    background: #108923;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}


.statistic-ball-over,
.statistic-ball-E,
.statistic-ball-four {
    margin: 2px 2px 2px 3px !important;
    background: #79071b;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}

.statistic-ball-BLUE {
    margin: 0 auto !important;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #1502f1;
    color: #fff;
}

.lotto12-mix .statistic-ball-BLUE {
    margin: 0 auto !important;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #1502f1;
    color: #fff;
}

.statistic-ball-GREEN {
    margin: 0 auto !important;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #168d01;
    color: #fff;
}

.lotto12-mix .statistic-ball-GREEN {
    margin: 0 auto !important;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #168d01;
    color: #fff;
}

.statistic-ball-RED {
    margin: 0 auto !important;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #e00000;
    color: #fff;
}

.lotto12-mix .statistic-ball-RED {
    margin: 0 auto !important;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #e00000;
    color: #fff;
}

.statistic-ball-under,
.statistic-ball-two {
    margin: 0 auto !important;
    background: #1502f1;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}

.statistic-ball-one {
    margin: 2px 2px 2px 3px !important;
    background: #ddbb10;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}

.statistic-ball-five {
    margin: 2px 2px 2px 3px !important;
    background: #ff6882;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}

.table thead th {
    border-bottom: 1px solid #e2e5e8;
    color: #fff;
    background: #3c56af;
    text-transform: uppercase;
}

.statistic-ball-over {
    margin: 2px 2px 2px 3px !important;
    background: transparent;
    width: 30px;
    height: 30px;
    border: 6px solid radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgba(156, 0, 0, 1) 45%, rgba(156, 0, 0, 1) 95%, rgba(255, 0, 0, 0) 95%);
    background: radial-gradient(circle, rgba(255, 0, 0, 0) 40%, rgb(236 0 35) 45%, rgb(236 0 35) 95%, rgba(255, 0, 0, 0) 95%);
    border-radius: 15px;
}

.statistic-ball-under {
    margin: 2px 2px 2px 3px !important;
    background: transparent;
    width: 30px;
    height: 30px;
    border: 6px solid radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgba(0, 0, 142) 45%, rgba(0, 0, 142, 1) 95%, rgba(0, 0, 255, 0) 95%);
    background: radial-gradient(circle, rgba(0, 0, 205, 0) 40%, rgb(21 2 241) 45%, rgb(21 2 241) 95%, rgba(0, 0, 255, 0) 95%);
    border-radius: 15px;
}

.title-ticket-id-btn {
    text-decoration: underline;
    color: #232399 !important;
    cursor: pointer;
}

.new-keno-left .group-betcredit .total-amount span {
    color: rgb(0, 0, 0);
}

td.text-center span {
    color: rgb(0, 0, 0);
    font-size: 14px;
}

.total-amount span {
    color: rgb(0, 0, 0);
}

.dashboard-fix-height {
    max-height: 479px;
    overflow-y: auto;
}

.bet-mix .dashboard-fix-height {
    max-height: 40vh !important;
    padding-bottom: 30px;
}

.bet-mix {
    border-right: 2px solid;
    border-left: 2px solid;
    border-top: 2px solid;
    height: 95vh;
}

.recent-betting-table {
    /*border-bottom: 0.06vw solid #567DCA;*/
}

.recent-betting-table-head {
    border-bottom: 0.06vw solid #567DCA;
}

.flip-card {
    background-color: transparent;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-180 {
    transform: rotateY(180deg);
}

.flip-180 .flip-card-back {
    display: block !important;
}

.flip-card-front, .flip-card-back {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    color: black;
}

.flip-card-back {
    color: white;
    transform: rotateY(180deg);
    top: 0;
    position: absolute;
}

#card-inner-result, .result-back {
    width: 10vw;
    height: 10vw;
}


.card-size {
    position: relative;
    margin: 2px;
    width: 15%;
    height: 20%;
}

.lotto12-betting-animation {
    width: 90% !important;
    margin: auto;
    padding-bottom: 10px !important;
}

.lotto12-mix .lotto12-betting-animation {
    width: 93% !important;
    margin: auto;
    padding-bottom: 10px !important;
    /*height: 400px;*/
}

.color-animated {
    border: 5px solid red;
    border-style: inset;
    animation-name: flash_border;
    animation-duration: 0.8s;
    border-radius: 10px;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: flash_border;
    -webkit-animation-duration: 0.8s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: flash_border;
    -moz-animation-duration: 0.8s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
}

.no-radius {
    border-radius: 0 !important;
}

@keyframes flash_border {
    0% {
        border: 5px solid red;
    }
    50% {
        border: 5px solid black;
    }
    100% {
        border: 5px solid red;
    }
}

@-webkit-keyframes flash_border {
    0% {
        border: 5px solid red;
    }
    50% {
        border: 5px solid black;
    }
    100% {
        border: 5px solid red;
    }
}

@-moz-keyframes flash_border {
    0% {
        border: 5px solid red;
    }
    50% {
        border: 5px solid black;
    }
    100% {
        border: 5px solid red;
    }
}

.jackpot-div {
    position: absolute;
    right: 1px;
    top: 0px;
}


@keyframes changeWidth {
    from {
        transform: scaleX(1);
    }

    to {
        transform: scaleX(1.2);
    }
}

.jackpot-12-number {
    position: absolute;
    right: 5px;
    top: 5px;
}

.f-32 {
    font-size: 32px !important;
    /*line-height: 55px;*/
    color: white !important;
    font-weight: 700;
    font-family: BattambangRegular, RobotoRegular;
}

.h-lotto {
    height: 100vh !important;
}

.border-lotto {
    border-right: 5px solid #80bdff;
}

.m-lotto-1 {
    margin: 2px !important;
}

.lotto12-bet-UNDER {
    background-color: #45C9FF !important;
}

.lotto12-bet-OVER {
    background-color: #F65AA9 !important;
}

.lotto12-bet-ODD {
    background-color: #F16521 !important;
}

.lotto12-bet-EVEN {
    background-color: #F8AF40 !important;
}

.lotto12-bet-RED {
    background-color: #EC1D24 !important;
}

.lotto12-bet-BLUE {
    background-color: #224099 !important;
}

.lotto12-bet-GREEN {
    background-color: #37B349 !important;
}

.lotto12-bet-label span {
    font-size: 24px !important;
    color: white !important;
    /*line-height: 44px !important;*/
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
}

.lotto12-bet-label-color {
    font-size: 21px !important;
    color: white !important;
    line-height: 87px !important;
    height: 138px;
}

.lotto5-th {
    background: #034df8 !important;
    color: white !important;
    text-align: center;
    border-top: 1px #567DCA solid !important;
    border-right: 1px #567DCA solid !important;
    line-height: 35px !important;
}

.lotto5-th-mix {
    background: #034df8 !important;
    color: white !important;
    text-align: center;
    border-top: 1px #567DCA solid !important;
    border-right: 1px #567DCA solid !important;
    line-height: 6vw !important;
}

.platform-999 .lotto5-th-mix {
    background: #FFBBBB !important;
    color: #21397B !important;
}

.platform-999.GREEN .lotto5-th-mix {
    background: #CCF1BC !important;
}

.lotto5-th label {
    font-size: 18px !important;
}

.lotto5-th-mix label {
    font-size: 2vw !important;
}

.border-st-r {
    border-right: 0.06vw solid #567DCA !important;
}

.bg-red-l {
    background: #f7483b !important;
    color: white !important;
}

.bg-yellow-l {
    background: #fffd7a !important;
}

.full-screen {
    object-fit: contain;
    position: fixed !important;
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    box-sizing: border-box !important;
    min-width: 0px !important;
    max-width: none !important;
    min-height: 0px !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
    margin: 0px !important;
    overflow: scroll;
}

.print-btn {
    font-size: 22px !important;
    line-height: 20px !important;
    margin-top: 10px;
    margin-left: 5px;
    padding: 0 2px;
    padding-top: 2px;
    text-align: center;
    right: 40px !important;
}

.checkbox-label {
    line-height: 20px !important;
    margin-top: 5px;
    margin-left: 5px;
}

.none-select {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.lotto5-count-down {
    height: 3vw !important;
}

.lotto5-mix .header-div-title,
.lotto6-mix .header-div-title {
    /*border: 2px solid #344b79;*/
    /*height: 6.5vw;*/
}

/*.lotto5-mix .header-div-title span {*/
/*    line-height: 50px;*/
/*}*/

.bet-mix .header-div-title {
    border: unset;
}

.bet-mix .total-result-render-count {
    background-color: transparent !important;
    line-height: 1.6 !important;
    font-size: 3vw !important;
    font-family: BattambangRegular, RobotoRegular;
    font-weight: bold;
}

.table-recent-betting td {
    font-size: 15px !important;
}

.table-recent-betting td div, .table-recent-betting td div span {
    font-size: 15px !important;
    font-weight: bold;
}

.table-draw-result td {
    font-size: .8vw !important;
    line-height: 1.9vw !important;
    font-family: BattambangRegular, RobotoRegular;
}

.table-draw-result td span, .table-draw-result td b {
    font-size: 1vw !important;
    font-weight: bold;
}

.last-result {
    font-size: 16px !important;
    font-weight: bold;
}

.last-result-betting {
    line-height: 2.8vw !important;
}

.header-history-light .last-result-betting {
    font-size: 1.1vw !important;
}

.bet-mix .scroll-recent-ticket {
    max-height: 400px;
    overflow-y: scroll;
}

.lotto5-mix .scroll-recent-ticket,
.lotto6-mix .scroll-recent-ticket,
.lotto12-mix .scroll-recent-ticket {
    /*max-height: 28vw;*/
    /*overflow-y: scroll;*/
}

.header-draw {
    display: flex;
    justify-content: space-between;
    margin: -6px -6px 0 -6px;
    padding: 0 6px;
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
}

.header-draw div {
    font-family: BattambangBold;
    font-weight: unset;
}

.lotto12-betting .color-items {
    position: absolute;
    background: white;
    color: black;
    font-size: 1em;
    border-radius: 3px;
    left: 0;
    right: 0;
    top: 65px;
    margin-left: 5px;
    margin-right: 5px;
    line-height: 1.3;
    padding-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
}

.lotto12-betting .lotto12-bet-label-color {
    height: 172px;
}

.lotto12-betting .lotto12-bet-label {
    height: 85px;
}

.lotto12-betting .rebate-under_over, .lotto12-betting .rebate-odd_even {
    bottom: 15px;
}

.w-last-draw {
    width: 33%;
}

.w-40 {
    width: 38%;
}

.w-35 {
    width: 35%;
}

.w-uo {
    width: 35px !important;
}


.print-tb-58 {
    width: 260px;
}

.dotted {
    border-top: 2px dashed black;
    color: transparent;
    padding: 0;
    margin: 0;
}

.ticket-logo {
    font-size: 22px !important;
    color: black !important;
    font-weight: bold !important;
}

#print-58 .table th, #print-58 .table td {
    border: 0;
    padding: 6px 0;
    /*line-height: 20px !important;*/
}

#print-58 .text-danger {
    color: black;
}

#print-58 span {
    /*font-size: 20px;*/
    /*font-weight: bold !important;*/
    color: black !important;
}

.f-20 {
    font-size: 20px !important;
}

.f-22 {
    font-size: 22px !important;


}

.f-23 {
    font-size: 23px !important;
}

.f-24 {
    font-size: 24px !important;
}

.f-26 {
    font-size: 26px !important;
}

.f-28 {
    font-size: 28px !important;
}

.f-30 {
    font-size: 30px !important;
}

.f-35 {
    font-size: 35px !important;
}

.font-weight-none {
    font-weight: 100 !important;
}

.in-recent-size {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: .9vw;
    width: 1.2vw;
    height: 1.5vw;
    margin-left: .2vw;
    padding-top: .1vw;
    border-radius: .3vw;
}

.lotto5-mix .in-recent-size, .lotto6-mix .in-recent-size, .lotto12-mix .in-recent-size {
    width: 3vw;
    height: 4vw;
    border-radius: .5vw;
    font-size: unset;
}

.lotto5-mix .btn-refresh-dashboard img, .lotto6-mix .btn-refresh-dashboard img, .lotto12-mix .btn-refresh-dashboard img {
    width: 3vw;
}

/*.statistic-ball-UNDER, .statistic-ball-OVER{*/
/*    width: 1.655vw!important;*/
/*    height: 1.655vw!important;*/
/*}*/


@keyframes scale-jp {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    100% {
        transform: scale(.5);
        -webkit-transform: scale(.5);
    }
}

.jp-animate {
    animation: scale-jp 150ms ease-in-out infinite alternate;
    -webkit-animation: scale-jp 150ms ease-in-out infinite alternate;
}

.p-r-7 {
    padding-right: 11px !important;
}

.lotto12-mix .header-div-title-label span {
    font-size: 3vw !important;
    font-weight: bold;
    font-family: BattambangBold, RobotoBold, serif !important;
    padding: 0.5vw;
}

.lotto5-mix .data-not-found,
.lotto6-mix .data-not-found,
.lotto12-mix .data-not-found {
    width: 30% !important;
}

#lotto-mix {
    height: 100vh;
    overflow: hidden;
}