/*.mr-drawing {*/
/*    margin-right: 473px !important;*/
/*}*/

.drawing, .ticket-header, .lo5d .bg-blue-l {
    background-color: #A2DAFA !important;
}

.drawing-result {
    background: radial-gradient(circle, #0877D3 0%, #001588 90%);
}

.last-result .A, .drawing-result .A {
    background-color: #FF0000;
}

.last-result .B, .drawing-result .B {
    background-color: #0877D3;
}

.last-result .C, .drawing-result .C {
    background-color: #00A651;
}

.last-result .D, .drawing-result .D {
    background-color: #FDBC3B;
}

.betting-header .head {
    padding: 11px;
}

.last-result .head {
    background-color: red;
    display: flex;
    padding: 5px 25px;
    font-weight: bold;
    color: white;
    justify-content: space-between;
}

.last-result .head .title {
    font-size: 1vw;
}

.last-result .head .draw {
    font-size: 1.2vw;
}

.w-icon-lo5d {
    width: 1.3vw;
}

.last-res-card {
    background-color: white;
    padding: 20px;
}

.last-res-card .draw {
    margin-top: 8px;
    font-weight: bold;
    font-size: 18px;
    color: black;
}

.history-res .draw {
    font-weight: bold;
    font-size: 18px;
    color: black;
}

.history-res .total-score {
    font-weight: bold;
    font-size: 18px;
    color: black;
}

.ball-number img {
    width: 35px;
    margin-right: 3px;
}

.f-timer {
    font-family: Arial, serif;
}

.tv-screen img {
    width: 266px;
}

.lo5d-logo-padding {
    padding: 82px 33px !important;
}

.counting-num {
    font-size: 100px;
    font-weight: bold;
    color: red;
    line-height: 100px;
    width: 180px;
    text-align: center;
    font-family: Arial, serif;
}

.current-draw {
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    font-family: Arial, serif;
}

.f-draw {
    font-size: 40px !important;
    margin-top: 10px;
}

.bg-l-result {
    background-color: #FDFDFD;
}

.last-result .body {
    padding: 33px;
}

.tb-result .body {
    padding: 2px;
}

.last-result .result-row {
    margin-bottom: 1rem;
    align-items: center;
}

.tb-result .result-row {
    margin-top: 10px;
}

.post-cir {
    height: 3.5vw;
    width: 3.5vw;
    border-radius: .5vw;
    color: #fff;
    font-size: 2.5vw;
    line-height: 3.5vw;
    text-align: center;
    font-weight: bold;
}

.tb-result .post-cir {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    font-size: 18px;
    line-height: 40px;
}

.num-cir .post-cir {
    color: white;
    background-color: #2196F3;
}

.random-result {
    width: 4.4vw;
    height: 4.4vw;
}

.ticket-head {
    /*margin: 15px auto;*/
    padding: 5px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    background-color: #A2DAFA;
}


.m-paging {
    margin-top: 10px;
}

.h-lane-card {
    height: auto !important;
    min-height: 343px;
    margin-right: 0;
}

.wrapper {
    position: relative;
    overflow: auto;
    white-space: nowrap;
    max-height: 300px;
}

.wrapper::-webkit-scrollbar {
    width: 1em;
}

.button-betting {
    width: 25px;
    height: 25px;
}

.form-betting .form-control {
    padding: 5px 10px !important;
    height: 2.5vw;
    border-radius: 5px;
    text-align: start;
}

.form-betting label {
    font-size: 1vw !important;
}

.w-right {
    width: 100%;
}

.w-type {
    width: 38px;
}

.betting-board .post {
    padding: 0.3vw 1vw;
    border: 1px solid grey;
    margin-left: .3vw;
    border-radius: .5vw;
    background-color: transparent;
    font-weight: bold;
    font-size: 1vw;
    /*line-height: 22px;*/
    transition: all .2s ease-out;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.7);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.betting-board .post :hover, .betting-board .post :active, .post-active, .price:active, .price:hover, .number-btn:active, .number-btn:hover {
    background-color: #0C1163 !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.7) !important;
    color: white;
}

.betting-board .price {
    width: 5vw;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lo5d-bet-btn button {
    width: 5.5vw;
}

.betting-h {
    max-height: 300px;
}

.w-last-result {
    /*width: 462px;*/
    height: 330px;
}

.form-betting .form-control {
    padding: 5px 10px !important;
    height: 2.5vw;
    border-radius: 5px;
    text-align: start;
}

.mt-input {
    margin-top: 23px;
}

.number-btn {
    width: 1.9vw;
    font-size: 1.2vw;
    padding: 6px 0;
    font-weight: bold;
    margin-right: .4vw;
    border-radius: .3vw;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.7);
    border: 1px solid #b8b5b5;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#results .table thead th,
#lotto5d .table thead th {
    border: 1px solid #e2e5e8;
    color: #fff;
    background: #b65c06;
    text-transform: uppercase;
}

.num-result {
    width: 25px;
    height: 25px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
}

.res-post {
    margin: 0 10px;
}

.post-result-A {
    background-color: #FF0000;
}

.post-result-B {
    background-color: #0877D3;
}

.post-result-C {
    background-color: #00A651;
}

.post-result-D {
    background-color: #FDBC3B;
}

.post-result-E {
    background-color: #0C1163;
}

.post-result-F {
    background-color: #00ACC1;
}

.res-num {
    margin-right: 10px;
    border: 2px solid #0094ff;
    color: #666;
    line-height: 1.5;
}

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.num-last-result {
    width: 1.8vw;
    height: 1.8vw;
    line-height: 1.8vw;
    font-size: 1.5vw;
    color: #FFFFFF;
    border-radius: .5vw;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
}

.res-last-result {
    margin-right: .2vw;
    margin-bottom: .2vw;
    /*background-color: #0094ff;*/
}

.last-result-post {
    margin: 0 .1vw .2vw .1vw;
}

.lo5d-his-result {
    padding: 4px 0;
}

.custom-last-draw {
    font-weight: 600;
    color: #666;
    font-size: 1vw !important;
    padding: 0.3vw !important;
}

.result-2d {
    border: 1px solid #01a9ac;
    color: #666;
}

.num-result.result-2d {
    border: 2px solid #01a9ac;
    line-height: 1.5;
}

.result-3d {
    border: 1px solid #0094ff;
    color: #666;
}

.refresh-icon {
    font-size: 18px;
    font-weight: bold;
    color: #0C1163;
}

.border-filter {
    border-right: 1px solid black;
    border-radius: 5% 0 0 5%;
}

.menu-span:active, .draw-code .active {
    background-color: #00A651;
    color: white;

}

.two-d-label {
    text-transform: none;
    font-size: 1.7vw;
    color: #34e085;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    font-weight: 800;
}


.menu-span {
    background-color: transparent;
    border-radius: 5%;
    padding: 8px 0;
    font-size: 15px;
    height: 42px;
    display: inline-block;
    line-height: 26px;
    cursor: pointer;
    width: 100px;
}

.menu-span:hover {
    background-color: #00A651;
    color: white;
}

.sc-betting {
    overflow: scroll !important;
    max-height: 85vh !important;
}

.w-bet-item {
    margin-bottom: 3px;
    width: 150px;
    padding: 0;
}

.pl-pr {
    padding-left: 5px;
    padding-right: 5px;
}

.w-total {
    margin-left: 5px;
}

.w-total-bottom {
    width: 15%;
}

.w-bet-item-v1 {
    margin-bottom: 3px;
    width: 150px;
    padding: 0;
}

.color-lane-head {
    color: #393939;
}

.select-lane {
    background-color: #e3e3e3;
    border-radius: 3px;
    margin: 5px 5px;
    position: relative;
    padding: 6px 3px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: auto;
    max-height: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.w-156 {
    width: 156px !important;
}

.betting-item-card {
    z-index: 999 !important;
    right: 100%;
    top: 60px;
    width: 160px;
    background: transparent;
    opacity: 0.9;
}

.betting-item-card-white {
    z-index: 999 !important;
    right: 100%;
    top: 60px;
    width: 160px;
    background: transparent;
}

.w-betting-card {
    width: 150px !important;
}

.h-header-history {
    height: 50px !important;
}

.wrap-last-result {
    width: 475px !important;
    height: 90vh;
    overflow-x: scroll;
}

.time-sec-lo5d {
    border-radius: 10px;
    font-size: 2vw;
    font-weight: 600;
    line-height: normal;
    color: #000;
    padding: 0 10px;
}

.w-betting-board {
    width: 100% !important;
}

.fs-num {
    font-size: 1.7vw !important;
}

.bg-head-timer {
    background: linear-gradient(180deg, #f27700, #FFDABCFF) !important;
}

.date-sec-lo5d {
    font-size: 1vw !important;
    color: #000;
    font-weight: 700;
}

.bg-drawing {
    background-color: #f3d1b0;
}

.l5d-left-side {
    min-width: 75% !important;
}

.l5d-right-side {
    min-width: 25% !important;
    /*max-width: 550px !important;*/

}

.main-card {
    /*min-width: 1417px;*/
    max-width: 100%;
    overflow-x: scroll;
}

.logo-5d {
    width: 165px !important;
    display: inline-block;
}

.logo-5d img {
    width: 5vw !important;
    padding-top: 0 !important;
    display: block !important;
    margin-left: 15px !important;
}

.lotto-lo5d-name {
    color: #00a2ff;
    font-size: 1.5vw;
    font-weight: 800;
    padding-left: 0.3vw;
}

.m-result {
    margin: 0 1.5vw !important;
}

.custom-btn {
    padding: 0.3vw 1vw;
    font-size: .8vw;
}

.m-btn-bet {
    margin: .2vw;
}

.mt-lo5d {
    margin-top: .5vw;
    margin-right: 1vw;
}

.ml-hr {
    margin-left: 2px;
    padding: 0;
}

.ml-hr-ticket {
    margin-left: 12px;
    padding: 0;
}

.ml-sim {
    margin-left: 3px;
    padding: 0;
}

.p-hr-show {
    padding-right: 10px !important;
}

.p-hr-show-2 {
    padding-right: 5px !important;
}

.l5d-v1-drawing {
    width: 100%;
    background-image: url("../images/lo5d/lotto5d_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2vw;
}

.l5d-v1-drawing .time-sec-lo5d {
    font-size: 2.5vw;
    line-height: 2.5vw;
    font-family: "Arial Black", cursive !important;
}

.draw-date-wrapper {
    margin: 0.2vw;
}


.title-5d {
    font-size: 1.5vw;
    font-family: 'Moul', cursive !important;
    color: #fff;
}

.title-5d-v1 {
    font-size: 3vw;
    color: #fff;
    font-family: "Arial Black", cursive !important;
}

.l5d-v1-drawing .draw-timer-wrap {
    background: white;
}

.post-5d-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.post-5d {
    margin-top: 1vw;
    position: relative;
}

.post-5d img {
    width: 20vw;
}

.number-2d {
    width: 9.5vw;
    font-family: "Arial Black", cursive !important;
    font-size: 3vw;
    text-align: center;
    font-weight: bold;
    display: flex;
    height: 4.6vw;
    justify-content: center;
    align-items: center;
}

.number-5d-wrapper {
    width: 100%;
    position: absolute;
    top: 4.3vw;
    display: flex;
    justify-content: space-between;
}

.d-board-Fullscreen .number-5d-wrapper {
    top: 5.3vw;
}

.number-3d {
    width: 11vw;
    text-align: center;
    font-size: 3vw;
    font-weight: bold;
    display: flex;
    height: 4.6vw;
    justify-content: center;
    align-items: center;
    font-family: "Arial Black", cursive !important;
}

.space-mid {
    width: 5vw;
}

.post-5d-A {
    color: red;
}

.post-5d-B {
    color: #16A2FF;
}

.post-5d-C {
    color: #6100FF;
}

.post-5d-D {
    color: #FD489B;
}

.l5d-v1-drawing .time-sec-title {
    margin: 1.4vw 0;
    font-size: 1.6vw;
    font-weight: bold;
    color: black;
}


.lo5d-v1 .card-body {
    padding: .3vw;
}

.lo5d-v1.betting-board .post {
    padding: 0.1vw 1vw !important;
}

.lo5d-v1 .form-betting .form-control {
    padding: 0.3vw !important;
    height: 2vw !important;
    font-size: .8vw;
    font-weight: bold;
}

.lo5d-v1 .number-btn {
    padding: 0 !important;
}

.lo5d-v1 .lo5d-v1.betting-board .post {
    padding: 0 1vw !important;
}

.lo5d-v1 .res-last-result, .lo5d-v1 .last-result-post {
    margin-right: 1.2vw !important;
    border-radius: 50%;
    width: 2.5vw !important;
    height: 2.5vw !important;
    line-height: 2.5vw !important;
    margin-bottom: 0.2vw !important;
    font-size: 1.4vw !important;
}

.lo5d-v1 .lo5d-his-result {
    padding: 0;
}

.lo5d-last-result {
    /*max-height: 28vw;*/
    overflow: hidden;
}

.d-board-Fullscreen .lo5d-last-result .bg-white {
    background-color: transparent !important;
}

.d-board-Fullscreen .lo5d-last-result {
    height: 100vh;
    max-height: unset !important;
}

.d-board-Fullscreen .lo5d-last-result .num-last-result {
    color: white !important;
}

.d-board-Fullscreen .lo5d-his-result .text-black {
    color: white !important;
}

.lotto5d-draw-section {
    top: 13vw !important;
}

.d-board-Fullscreen .post-5d img {
    width: 23vw !important;
}

.w-55 {
    width: 55% !important;
}

.d-board-Fullscreen .border-top {
    border-top: unset !important;
}

.d-board-Fullscreen .number-2d {
    width: 11vw;
}

.d-board-Fullscreen .number-3d {
    width: 12vw;
}

.loading-5d div {
    width: 2.3vw !important;
    /*height: 1vw !important;*/
}

.loading-5d {
    margin-top: .5vw !important;
}
