
.content-left-bet {
    margin: 0 auto;
    width: 40vw;
    /*height: 100%;*/
}

.content-left-bet .flex-between {
    /*height: 1.15385rem;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-left-bet .content-left-bet-top {
    display: flex;
    align-items: center;
}

.content-left-bet .logo2 {
    width: 5vw;
    height: 5vw;
    margin-right: 0.19231rem;
}

.content-left-bet-countdown {
    min-width: 2.11538rem;
}

.content-left-bet-countdown .countdown-title {
    font-size: 2vw;
    font-weight: 700;
    color: #ffcb09;
}

.content-left-bet .flex-between .countdown {
    display: inline-block;
    font-size: 2.8vw;
    font-weight: 700;
    color: #ffcb09;
}

.l199-issueno, .l199-issueno span {
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    font-size: 1.5vw;
    color: #fff;
    line-height: 1;
    text-align: left;
}

.l199-fullscreen {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 1.5vw;
    height: 1.5vw;
    z-index: 10;
}

.l199 .d-board {
    position: relative;
    height: unset !important;
}

.content-left-bet .issueNo {
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 40vw;
    height: 13vw;
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: flex-start;
    position: relative;
    padding: 5vw;
}

.content-left-bet .issueNo .deng-img {
    top: 1.2vw;
    left: 1.2vw;
    position: absolute;
    width: 36.5vw;
    height: 10.5vw;
    z-index: 2;
}

.content-left-bet .content-left-bet-number {
    margin-left: 0.09615rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    /*height: 100%;*/
    padding: .5vw 0 0 0;
    background-size: 100% 100%;
}

.content-left-bet-number .code {
    margin: .08vw .2vw;
    width: 1.6vw;
    height: 1.6vw;
    text-align: center;
    color: #f6f6f6;
    box-sizing: border-box;
}

.code > span {
    display: inline-block;
    height: 0.2vw !important;
    font-size: 1vw;
    /*line-height: 1.7;*/
    color: #f6f6f6;
}

.bet-bottom-groups {
    margin: 0.5vw 0;
    padding: 0.5vw 5vw;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 0.8vw;
    box-shadow: 0 0.2vw 0.5vw #0000001a, 0 -0.2vw 0.5vw #0000001a inset;
}

.bet-bottom-groups .bet-bottom-title {
    width: 5.8vw;
    font-size: .8vw;
    text-align: left;
    font-weight: 400;
    color: black;
}

.bet-bottom-groups .demo-button-item {
    margin: 0 0.5vw;
    width: 6vw;
    height: 2vw;
    padding: 0 0.1vw;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    border: 1px solid #c1c1c1;
    border-radius: .3vw;
    cursor: pointer;
}

.bet-bottom-groups .demo-button-item .demo-button-item-group {
    font-size: 0.8vw;
    font-weight: 700;
    text-align: center;
}

.l199 .l-side {
    display: block;
}

.l199 .bet-bottom {
    box-sizing: border-box;
    padding: 1vw;
    width: 100%;
    min-height: 0.96154rem;
    background: #fff;
    border-radius: 0.8vw;
    box-shadow: 0 0.2vw 0.5vw #0000001a, 0 -0.2vw 0.5vw #0000001a inset;
}

.l199 .bet-bottom .bet-bottom-row {
    padding: 0.4vw 0;
    height: 10vw;
    overflow-x: hidden;
    overflow-y: scroll;
}

.l199 .bet-bottom .bet-bottom-list {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 2vw;
}

.l199 .bet-bottom .bet-bottom-list .bet-bottom-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .4vw 0;
}

.l199 .bet-bottom .bet-bottom-list .bet-bottom-title span {
    color: black;
    font-size: 0.7vw;
    font-weight: 400;
    text-align: right;
}

.l199 .bet-bottom .bet-bottom-list .bet-bottom-list-item {
    padding-left: 0.01923vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.14423vw;
    cursor: pointer;
}

.l199 .bet-bottom .bet-bottom-list .bet-bottom-list-item .off {
    width: 3vw;
    height: 3vw;
    font-size: 0.8vw;
    font-weight: 700;
    color: #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: .57692vw;
    /*background: url("../../../src/assets/images/lotto199/bg-white-circle.png") no-repeat;*/

    background-size: cover;
}

.l199 .bet-bottom .bet-bottom-list .bet-bottom-list-item .on {
    width: 3vw;
    height: 3vw;
    font-size: 0.8vw;
    font-weight: 700;
    color: #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: .57692vw;
    /*background: url("src/assets/images/lotto99/bg-circle.png") no-repeat;*/
    background-size: cover;
}

.l199 .bet-bottom .bet-bottom-list .bet-bottom-title .select_all {
    width: 1vw;
    height: 1vw;
    margin-right: 0.09615vw;
}

.l199 .r-side {
    width: 100% !important;
    height: 100%;
    background: #fff;
    padding-bottom: 0.09615rem;
    box-shadow: 0 0.2vw 0.4vw #0000001a;
    min-height: 100vh !important;
    overflow-y: hidden;
}

.left-tabs {
    display: flex;
    justify-content: flex-start;
    /*align-items: center;*/
    box-sizing: border-box;
    width: 100%;
    height: 2.8vw;
    background: linear-gradient(180deg, #faf9fb 0%, #ffffff 50%, #e5e5e5 100%);
}

.left-tabs > span {
    display: inline-block;
    width: 1.53846vw;
    height: 0.53846vw;
    font-size: .19231vw;
    font-family: Arial-Regular, Arial;
    font-weight: 500;
    color: #333;
    line-height: .53846vw;
    cursor: pointer;
    text-align: center;
}

.left-tabs .red {
    color: #5a44a8;
    background: linear-gradient(180deg, #f9f9fa 0%, #f0eef4 42%, #e8e4ee 100%);
}


.left-tabs > span {
    width: 10vw;
    height: 2.8vw;
    font-size: 1vw;
    font-family: Arial-Regular, Arial;
    font-weight: 500;
    color: #333;
    line-height: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.content-left .content-left-bet .issueNo .color1 {
    margin-left: 0.72115vw;
    color: #00acff;
}

.content-left .content-left-bet .issueNo .issueNo-row-bottom {
    margin-top: 0.72115vw;
    font-size: .86538vw;
    font-weight: 700;
    text-align: center;
    text-shadow: 0.01923vw 0.01923vw 0 #000, -0.01923vw 0.01923vw 0 #000, 0.01923vw -0.01923vw 0 #000, -0.01923vw -0.01923vw 0 #000;
}

.results {
    background: #fff;
}

.results .list {
    /*height: calc(100vh - 2.11538vw);*/
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: hidden;
}

.results .list .item {
    width: 100%;
    box-sizing: border-box;
    padding: .5vw;
    border-bottom: 0.0962rem solid #e3e3e3;
    text-align: left;
}

.results .list .item .list-groups {
    display: flex;
    align-items: flex-start;
    font-size: .15385vw;
}

.results .list .item .list-groups .list-group {
    display: flex;
    align-items: center;
    margin-left: 1vw;
    flex-direction: row;
}

.results .list .item_99 {
    width: 100%;
    box-sizing: border-box;
    padding: .5vw .5vw .5vw 2vw;
    border-bottom: 0.0962rem solid #e3e3e3;
    text-align: left;
}

.results .list .item_99 .list-groups {
    display: flex;
    align-items: flex-start;
    font-size: .15385vw;
}

.results .list .item_99 .list-groups .list-group {
    display: flex;
    align-items: center;
    margin-left: 1vw;
    flex-direction: row;
}

.list-groups .list-group .list-group-item-l99 {
    margin-right: 1vw;
    text-align: center;
}

.list-group .list-group-item-l99 .item-group {
    font-size: 1vw;
    font-weight: 400;
    line-height: 1.2;
    color: #333;
}

.list-groups .list-group .list-group-item-l99 .item-value {
    width: 2vw;
    height: 2vw;
    font-size: 1vw;
    font-weight: 700;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.l99-post-A {
    background: rgb(0, 172, 255);
}

.l99-post-B {
    background: rgb(255, 38, 85);
}

.l99-post-C {
    background: rgb(114, 116, 253);
}

.l99-post-D {
    background: rgb(255, 144, 1);
}

.l99-post-E {
    background: rgb(125, 230, 33);
}

.order .table {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    overflow: auto;
}

.order .table table {
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    /*font-size: .19231rem;*/
    table-layout: fixed;
    overflow: auto;
}

.order .table tr th:nth-child(1) {
    border: 0.00962rem solid #e5e5e5;
}

.order .table tr th {
    background: #eceeff;
    vertical-align: middle;
    border: 0.1rem solid #e5e5e5;

}

.order .table tr td {
    word-wrap: break-word;
    word-break: break-all;
    vertical-align: middle;
}

.text-btn {
    text-decoration: underline;
    color: #5a44a8 !important;
    cursor: pointer;
}


.order .table tr td span {
    color: black;
}

.order .red {
    color: red !important;
}

.issueNo-row-bottom {
    margin-top: 3.5vw;
}

.issueNo-row-bottom span {
    font-size: 3vw;
    font-weight: 700;
    text-align: center;
    text-shadow: 0.1vw 0.1vw 0 #000, -0.1vw 0.1vw 0 #000, 0.1vw -0.1vw 0 #000, -0.1vw -0.1vw 0 #000
}

.color1 span {
    /*margin-left: -1vw;*/
    color: #00acff
}

.color2 span {
    /*margin-left: 3.7vw;*/

    color: #ff2655
}

.color3 span {
    /*margin-left: 3.7vw;*/
    color: #7274fd
}

.color4 span {
    /*margin-left: 3.7vw;*/

    color: #ff9001
}

.color5 span {
    /*margin-left: 3.7vw;*/
    color: #7de621
}

.color1 span,
.color2 span,
.color3 span,
.color4 span,
.color5 span{
    padding-right: 4.5vw;
}

.wrap-result-l99{
    margin-left: -0.9vw;
}

.content-left-bet-number .code-active {
    text-align: center;
    color: #6d0f7f;
    background: #ffcb09;
    border-radius: 50%;
    box-sizing: border-box;
}

.demo-button-item-select {
    border: 0.00962rem solid #5b439e !important;
    color: #5b439e !important;
}

.demo-button-item > img {
    width: 0.8vw;
    height: 0.8vw;
    margin-left: 0.1vw;
}

.item-value-result {
    width: 2vw;
    height: 2vw;
    font-size: 1vw !important;
    font-weight: 700;
    color: #fff !important;
    text-align: center;
    display: inline-block;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 5px;
    line-height: 2vw;
    /*padding-left: 5px;*/
    /*padding-right: 3px;*/
}

.post99-wrapper {
    padding: 1vw 13vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.post99-circle {
    height: 3vw;
    width: 3vw;
    border-radius: 50%;
    background-color: #E4E4E4;
    font-weight: bold;
    font-size: 2vw;
    text-align: center;
    color: #145FCD;
    line-height: 3vw;
    transition: all .2s ease-out;
}

.post99-circle:hover {
    transform: scale(1.1);
}

.post99-circle.active {
    background-color: #145FCD;
    color: white;
}

.l199 .num-circle {
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
    font-size: 1vw;
    font-weight: bold;
    text-align: center;
    line-height: 2vw;
    margin: 0.2vw;
    transition: all .2s ease-out;
    /*border: 1px solid #00a2ff;*/
}


.code99-active {
    color: #6d0f7f !important;
    background: #ffcb09 !important;
}

.w-right_lo99 {
    width: 25vw;
}

.w-mid_lo99 {
    width: 60vw;
}

.d-board-Fullscreen .issueNo{
    width: 54vw!important;
    padding: 10vw;
}

/*.d-board-Fullscreen .deng-img{*/
/*    width: 100% !important;*/
/*}*/

.d-board-Fullscreen .results{
    background: transparent!important;
}

.d-board-Fullscreen .item_99 span{
    font-weight: bold;
    color: white!important;
    font-size: 1vw;
}

.d-board-Fullscreen .list-group .list-group-item-l99 .item-group{
    color: white!important;
    font-weight: bold;
}

/*.d-board-Fullscreen .content-left-bet {*/
/*    width: 80vw!important;*/
/*}*/

.d-board-Fullscreen .tb-number {
    max-width: 100%!important;
}

.d-board-Fullscreen .num-circle{
    width: 2.5vw;
    height: 2.5vw;
    line-height: 1.5;
    font-size: 1.8vw;
    /*margin: 0.6vw 0.3vw;*/
    background: transparent;
    color: white;
    border: none;
}

.d-board-Fullscreen .content-left-bet .logo2 {
    width: 8vw;
    height: 8vw;
}

.d-board-Fullscreen .content-left-bet .flex-between .countdown{
    font-size: 5vw;
}

.d-board-Fullscreen .content-left-bet{
    width: 68vw!important;
}

.d-board-Fullscreen .issueNo-row-bottom span {
    font-size: 5vw;

}

.d-board-Fullscreen .results .list .item_99{
    border: none;
}

.d-board-Fullscreen .wrap-result-l99{
    margin-left: -4.7vw;
}


.d-board-Fullscreen .color1 span,
.d-board-Fullscreen .color2 span,
.d-board-Fullscreen .color3 span,
.d-board-Fullscreen .color4 span,
.d-board-Fullscreen .color5 span{
    line-height: 1.6vw;
    padding-right: 8.7vw;
}

.d-board-Fullscreen .bg-his-result{
    background: rgba(56, 19, 96, 0.5);
}

.d-board-Fullscreen .results .list{
    height: calc(100vh);
}

.content-left-bet .content-left-bet-top{
    margin-left: -4vw;
}

.d-board-Fullscreen .content-left-bet .content-left-bet-top{
    margin-left: -2vw;
}

.d-board-Fullscreen .ml-vw{
    margin-left: 5vw;
}

.w-25-vw{
    width: 26vw!important;
}

.l199 .tb-number{
    max-width: 50vw;
}

.t-vw-2{
    margin-top: 3vw;
}

.lotto99 .lotto-name {
    color: white !important;
    font-weight: unset !important;
    font-family: 'Moul', cursive !important;
    font-size: 2vw;
}

.lotto99 .content-left-bet {
    margin: 1vw auto;
    width: 50vw;
    /*height: 100%;*/
}

.lotto99 .date-sec, .lotto99 .ticket-sec {
    color: black!important;
    font-size: 1.1vw !important;
    text-align: left;
    font-weight: bold;
}

.lotto99 .content-left-bet .flex-between .countdown {
    background: unset;
    font-size: 2.6vw;
    font-family: 'Arial Black', sans-serif !important;
    line-height: 0.5;
    padding: 0.6vw;
    margin-top: 0.4vw;
    color: black;
}


.date-sec-lo99, .ticket-sec-lo99{
    color: white!important;
    font-size: 1.1vw !important;
    text-align: left;
    font-weight: bold;
}

.lotto99 .draw-timer-wrap .time-sec-title{
    margin: 1.4vw 0;
    font-size: 1.6vw;
    font-weight: bold;
    color: black;
}

.count-digits{
    width: 3.3vw!important;
}

.count-digits::after{
    position: relative;
    content: attr(data-count-digit);
    display: block;
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
    color: #ffcb09!important;
    opacity: 0.5;
}

.ball-99-bg{
    background-image: url("../images/lotto99/num99_bg.png");
    background-size: 100% 100%;
}