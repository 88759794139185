.wrapper-new-lotto5 {
    display: block;
    min-width: 1200px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin: 0;
    font-family: BattambangRegular, RobotoRegular;
    min-height: 100vh;
    background-color: white;
}


.wrap-lotto5-content {
    min-height: 100vh;
    background-color: #ffffff;
    margin-top: 3px;
}

.new-lotto5-left {
    margin-top: 5px;
    margin-right: 10px;
    width: 400px;
    display: block;
    float: left;
    padding-bottom: 10px;
    background: #fff;
}

.new-lotto5-left h3 {
    font-size: 14px;
    text-transform: uppercase;
    height: 45px;
    margin: 0;
    line-height: 30px;
    color: #111;
    /* background: #e8e8e8; */
    font-family: BattambangRegular, RobotoRegular;
    padding-left: 15px;
    text-align: left;
    width: 75%;
}


.new-lotto5-left .group-betcredit {
    display: block;
    overflow: hidden;
    padding: 0 15px;
}

.new-lotto5-left .group-betcredit .betcredit-label {
    font-size: 14px;
    font-family: BattambangRegular, RobotoRegular;
    color: #111;
    float: left;
    margin-top: 5px;
}

.new-lotto5-left .group-betcredit .total-amount {
    font-size: 14px;
    color: #111;
    font-family: BattambangRegular, RobotoRegular;
    float: right;
    margin: 0;
}

/* .result-list-now li:nth-child(even) {
    background-color: #e9e9f3;
} */

.result-list-now li .result-now,
.result-list-now li .result-total {
    display: block;
    margin: 0;
    font-size: 12px;
    font-family: BattambangRegular, RobotoRegular;
    color: #111;
}


.new-lotto5-right {
    width: calc(92% - 310px);
    float: left;
    height: auto;
    margin: 0 0 0 0;
    margin: 5px 0 30px 0;
}

.wrap-lotto5-top {
    float: left;
    /* width: 750px; */
    margin-right: 10px;
}

.lotto5-play-main {
    /* width: 750px; */
    overflow: hidden;
    margin-bottom: 0px;
}

.lotto5-play-main .lotto5-dispaly-result {
    width: 100%;
    float: left;
}

.lotto5-dispaly-result .lotto5-logo,
.lotto5-dispaly-result .lotto5-total {
    width: 100%;
    float: left;
    height: auto;
}

.lotto5-dispaly-result .lotto5-logo {
    /* border-top: 1px solid #224;
    border-left: 1px solid #224; */
    /* background: linear-gradient(to bottom,
    #224 0%,
    #446 50%,
    #224 100%); */
    background: linear-gradient(to bottom, #f9e619 7%, #fcf4ab 35%, #ffee2f 76%);;
    height: 60px;
}

.lotto5-dispaly-result .lotto5-logo img {
    /* width: 100%; */
    height: 60px;
    margin: 0 auto;
    display: block;
}

.lotto5-dispaly-result .lotto5-total {
    background: linear-gradient(to bottom, #2962ff 7%, #2962ff 35%, #2962ff 76%);
    border-right: 0.06vw solid #567DCA;
    color: #FFF;
    height: 3vw !important;
    border-top: 0.06vw solid #567DCA;
}

.lotto5-dispaly-result .lotto5-total .period-result {
    font-size: 15px;
    font-weight: 700;
    color: inherit;
    font-family: BattambangRegular, RobotoRegular;
    text-align: center;
    display: block;
    margin: 0;
    line-height: 1.5;
}

.lotto5-row {
    width: 100%;
    display: block;
    overflow: hidden;
}

.padding-h {
    padding: 5px 10px 0 !important;
}

.lotto5-row label, .lotto5-row p {
    font-size: 15px;
    color: white;
    line-height: 32px;
}

.lotto5-dispaly-result .lotto5-total .label-title {
    font-size: 1vw !important;
    font-family: BattambangRegular, RobotoRegular;
    color: inherit;
    float: left;
    line-height: 1.3;
}

.KPW-title {
    line-height: 39px !important;
}

.lotto5-dispaly-result .lotto5-total .total-result {
    font-size: 1vw !important;
    font-family: BattambangRegular, RobotoRegular;
    color: inherit;
    float: right;
    margin: 0;
    line-height: 1.3;
}

.lotto5-content-result {
    width: 100%;
    overflow: hidden;
    display: block;
}

.lotto5-mix-content-result {
    width: 100%;
    overflow: hidden;
    display: block;
}

.lotto5-content-result table td {
    width: 3.1vw;
    height: 3.095vw;
    border: .06vw solid #567DCA;
    padding: 2px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
    overflow: hidden;
    color: #000;
}

.lotto5-mix-content-result table td {
    border: .06vw solid #567DCA;
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
    overflow: hidden;
    color: #000;
}

.lotto5-content-result table td span {
    padding: 0;
    margin: 0;
    height: 2.82vw;
    display: block;
    line-height: unset !important;
    color: #000;
    font-size: 1.5vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: BattambangRegular, RobotoRegular;
    font-weight: 600;
}

.lotto5-mix-content-result table td span {
    padding: 1vw;
    margin: 0;
    display: block;
    /*line-height: 48px;*/
    font-size: 3vw;
    color: #000;
    font-family: BattambangRegular, RobotoRegular;
    font-weight: 600;
}


table#lotto5_result_post td span.active {
    animation: box_number_animation 2s;
    background: #ec0024;
    color: #fff;
}

.lotto5-play-main .lotto5-play-right {
    width: 100%;
    float: left;
}

.lotto5-play-right .random-play {
    border-bottom: 0.06vw solid #567DCA;
    border-left: 0.06vw solid #567DCA;
    width: 100%;
    /*height: 12vw !important;*/
}

.lotto5-play-right .random-play .loading-gif {
    height: 60.5px;
    width: 100%;
    display: block;
    border-bottom: 1px solid #878787;
    background: linear-gradient(to bottom, #d50000 7%, #d50000 35%, #d50000 76%);
    /* background: linear-gradient(to bottom, #f9e619 7%, #fcf4ab 35%, #ffee2f 76%); */
    /* border: 1px solid #bbaa00; */
    text-align: center;
    /* background: linear-gradient(to bottom, rgb(44, 49, 66) 0%, rgb(44, 49, 67) 50%, rgb(76, 77, 78) 100%); */
}

.lotto5 .loading-gif {
    height: 3vw !important;
    width: 100%;
    display: block;
    background: linear-gradient(to bottom, #d50000 7%, #d50000 35%, #d50000 76%);
    text-align: center;
}

.lotto5 .loading-gif .timer-loading {
    margin: 0;
    display: block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    font-family: BattambangBold, RobotoBold;
    line-height: 24px;
}

.lotto5 .blgo-random-number {
    position: relative;
    width: 100%;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 9vw !important;
}


.lotto5 .modal-lotto5-result {
    position: fixed;
    overflow-y: auto;
    z-index: 20;
    left: calc(50% - 375px);
    top: calc(50% - 200px);
    width: 750px;
    height: auto;
    border: 5px solid #ddbb22;
    background-color: #ffffff;
    border-radius: 1px;
}

.wrap-lotto5-header-row .title {
    float: left;
    width: 50%;
    color: #fff;
    padding-left: 10px;
}

.wrap-lotto5-header-row .title p {
    font-family: BattambangRegular, RobotoRegular;
    color: #fff;
}

.wrap-lotto5-header-row .action-btn {
    float: left;
    width: 50%;
}

.bg-overlay-lotto5-betting {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: black;
    background: rgba(0, 0, 0, 0.6);
}

.modal-lotto5-betting {
    overflow-y: auto;
    z-index: 20;
    width: 650px;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 10%;
}

.lotto5 .bet-period-timer {
    width: calc(100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3vw !important;
    background: linear-gradient(to bottom, #004df9 7%, #004df9 35%, #004df9 76%);
    color: #fff;
}

.lotto5 .wrap-lotto5-betting-header-row {
    background: #2c3a88;
    float: left;
    width: 100%;
    padding: 16px 16px;
    line-height: 2em;
    font-weight: 800;
}

.lotto5 .wrap-lotto5-betting-header-row .title {
    float: left;
    width: 50%;
    color: #fff;
}

.lotto5 .wrap-lotto5-betting-header-row .title p {
    font-family: BattambangRegular, RobotoRegular;
    color: #fff;
    margin-top: 5px;
}

.wrap-lotto5-betting-header-row .action-btn {
    float: left;
    width: 50%;
}

.lotto5 .bet-period-timer #period-before {
    float: left;
    font-size: 1vw !important;
    font-family: BattambangBold, RobotoBold;
    margin: 0;
    padding-left: 10px;
    line-height: unset !important;
}

.lotto5-mix .bet-period-timer #period-before {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    font-size: 3vw !important;
}

table#lotto5_result_post td.active {
    background: #bbaa00;
}

.lotto5 .lotto5-play-right .blog-play {
    width: calc(89% - 100px);
    height: 60px;
    float: left;
}

.lotto5 .over-under-odd-even label {
    display: block;
    text-align: center;
    font-weight: bold;
    line-height: 54px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(to bottom, #fff 0%, #fff 50%, #fff 100%);
    /*background: linear-gradient(to bottom, #c5c5c5 0%, #e7e7e7 50%, #c5c5c5 100%);*/
    border: 1px solid #ccc;
    color: #111;
    font-family: BattambangBold, RobotoBold;
}

.lotto5 .over-under-odd-even label.range-lable {
    display: block;
    text-align: center;
    font-weight: bold;
    line-height: 55px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(to bottom, #fff 0%, #fff 50%, #fff 100%);;
    border: 1px solid #ccc;
    color: #111;
    font-family: BattambangBold, RobotoBold;
}

.lotto5 .over-under-odd-even .box-bet {
    float: left;
    display: block;
    background: #f0cece;
    cursor: pointer;
    /*border: 0.06vw solid #567DCA;*/
    border-right: 0.06vw solid #567DCA;
    border-top: 0.06vw solid #567DCA;
}

.platform-999 .lotto5 .over-under-odd-even .box-bet {
    background: #fff;
    border-right: 0;
}

.lotto5 .over-under-odd-even .box-bet div .under {
    opacity: 1;
    transition: all 1.8s linear;
    background: #0501f1;
    /* background: linear-gradient(to bottom, #0f00ab 0%, #5a41dd 50%, #0f00ab 100%); */
}

.lotto5 .over-under-odd-even .box-bet div .under.win-under {
    opacity: 1;
    animation: box_animation_under_win 1.8s;
    background: linear-gradient(to bottom, #02006e 0%, #0501f1 50%, #02006e 100%);
}

.lotto5 .range {
    color: #ffee00;
    line-height: 18px;
}


.lotto5 .over-under-odd-even .box-bet .odds {
    color: #21397B;
    font-weight: bold;
    font-size: 20px;
    margin-top: 0;
    line-height: 35px;
}

.lotto5-mix .over-under-odd-even .box-bet .odds {
    color: #21397B;
    font-weight: bold;
    font-size: 3vw;
    margin-top: 0;
}


.lotto5 .over-under-odd-even .box-bet div .type-rage {
    color: #bb2233;
    font-size: 4vw !important;
    line-height: unset !important;
    margin-top: 0;
    background: #fff;
    height: 6.47vw !important;
    opacity: 1;
    transition: all 1.8s linear;
    font-weight: bold;
}

.lotto5-mix .over-under-odd-even .box-bet div .type-rage {
    color: #bb2233;
    font-size: 9vw;
    line-height: 15vw !important;
    margin-top: 0;
    background: #fff;
    opacity: 1;
    transition: all 1.8s linear;
    border-bottom: 0.06vw solid #567DCA;
}

.type-rage-mix {
    color: #FA0000 !important;
    font-size: 10vw !important;
    line-height: 18vw !important;
    margin-top: 0 !important;
    background: #fff;
    opacity: 1;
    transition: all 1.8s linear;
    font-weight: 900;
    font-family: "Arial Black", sans-serif !important;
}


.lotto5-history-result {
    /* margin-top: 10px; */
    width: 100%;
    /* width: 750px; */
    float: left;
}

.lotto5-history-result table td {
    height: 2.2vw !important;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    overflow: hidden;
    color: #000;
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
    border: .06vw solid #567DCA;
}

.lotto5-mix .lotto5-history-result table td {
    height: 5.5vw !important;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    overflow: hidden;
    color: #000;
    background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 51%, #eaeaea 100%) !important;
    border: .06vw solid #567DCA;
}

.lotto5-mix .lotto5-history-result table td span {
    padding: 1vw !important;
}

.lotto5-history-result table tbody tr td label {
    font-family: BattambangRegular, RobotoRegular;
}


.lotto5-history-result table td span {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: unset !important;
    font-size: 1.5vw !important;
    font-weight: bold;
    width: 2vw !important;
    height: 2vw !important;
}

.lotto5-history-result table td span {
    font-size: 1.3vw !important;
    color: white;
}

.lotto5-mix .lotto5-history-result table td span {
    font-size: 3vw !important;
    font-weight: bold;
    width: 5vw !important;
    height: 5vw !important;
}

.lotto5-history-result table td label {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: unset !important;
    color: #21397B !important;
    font-size: 1vw !important;
    font-weight: bold;
    cursor: pointer;
    height: 2.2vw !important;
    font-family: BattambangRegular, RobotoRegular;
}

.lotto5-history-result table td label.title {
    color: #111;
    background: linear-gradient(to bottom, #c5c5c5 0%, #e7e7e7 50%, #c5c5c5 100%);
    font-family: BattambangRegular, RobotoRegular;
}


.lotto5 .total-result-render-count-warning {
    color: #dd1122 !important;
    font-size: 2vw !important;
    height: 3vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
}

.lotto5 .total-result-render-count-total {
    color: #fff !important;
    font-size: 2vw !important;
    line-height: unset !important;
    background: linear-gradient(to bottom, #990000 0%, #df1e1e 50%, #990000 100%);
}

.lotto5 .result-time-countup-render {
    width: 13%;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    border-radius: 2px;
    background: #ad0026;
    margin: 2px;
    position: absolute;
}

.lotto5-mix .result-time-countup-render {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5vw;
    text-align: center;
    font-size: 3vw;
    color: #fff;
    font-weight: bold;
    border-radius: .3vw;
    background: #ad0026;
    margin: .3vw;
    position: absolute;
}

.result-time-countup-render-mix {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5vw;
    height: 4.5vw;
    text-align: center;
    font-size: 3vw;
    color: #fff;
    font-weight: bold;
    border-radius: .3vw;
    background: #ad0026;
    margin: .3vw;
    position: absolute;
}

.lotto5 .show-logo-render {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    display: block;
    background: linear-gradient(to bottom, #004df9 0%, #004df9 50%, #004df9 100%)
}


.lotto5 .show-number-draw {
    text-align: center;
    font-size: 5vw !important;
    line-height: unset !important;
    font-weight: bold;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%);
    box-shadow: 0px 2px 10px #8d0026;
    width: 8vw !important;
    height: 8vw !important;
    margin: unset !important;
    /*margin: 1vw auto 1vw auto !important;*/

}

.lotto5 .statistic-ball-three {
    margin: 2px 2px 2px 3px !important;
    background: #108923;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}

.lotto5 .statistic-ball-under,
.lotto5 .statistic-ball-two {
    margin: 2px 2px 2px 3px !important;
    background: #1502f1;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}

.lotto5 .statistic-ball-one {
    margin: 2px 2px 2px 3px !important;
    background: #ddbb10;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}

.lotto5 .statistic-ball-five {
    margin: 2px 2px 2px 3px !important;
    background: #ff6882;
    height: 30px;
    width: 30px;
    line-height: 30px !important;
    border-radius: 15px;
    color: #fff;
}


.bg-overlay-lotto5-result {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: black;
    background: rgba(0, 0, 0, 0.2);
}

.bg-overlay-lotto5-betting {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: black;
    background: rgba(0, 0, 0, 0.6);
}

.lotto5 .range-type .box-bet {
    height: 100%;
    width: 20%;
}


.lotto5 .box-bet-UNDER, .lotto5 .box-bet-OVER {
    border: 1px solid #080808 !important;
}

.lotto5 .row {
    margin: 0;
}

.lotto5-play-right .w-100 {
    min-width: 555px;
}

.lotto5 .col-8 {
    flex: 0 0 calc(66.666667% + 25px);
    min-width: calc(66.666667% + 25px);
}

.lotto5 .col-4 {
    flex: 0 0 calc(33.333333% - 25px);
}

.lotto5 .under-over {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    line-height: unset !important;
    cursor: pointer;
    height: 3vw !important;
    font-size: 1.2vw !important;
    font-weight: bold;
    background: linear-gradient(to bottom, #fff 0%, #fff 50%, #fff 100%);
    border-right: 0.06vw solid #567DCA;
    border-left: 0.06vw solid #567DCA;
    color: #111;
    font-family: BattambangBold, RobotoBold;
}

.lotto5 .under-over-label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    line-height: unset !important;
    cursor: pointer;
    height: 2vw !important;
    font-size: 1.2vw !important;
    font-weight: bold;
    background: linear-gradient(to bottom, #fff 0%, #fff 50%, #fff 100%);
    border-right: 0.06vw solid #567DCA;
    border-left: 0.06vw solid #567DCA;
    color: #111;
    font-family: BattambangBold, RobotoBold;
}

/*.lotto5-mix .random-play {*/
/*    height: 100px !important;*/
/*}*/

.border-b {
    border-bottom: .06vw solid #567DCA;
}

.border-t {
    border-top: .06vw solid #567DCA;
}


.border-r {
    border-right: .06vw solid #567DCA;
}

.border-l {
    border-left: .06vw solid #567DCA;
}

.border-all {
    border: .06vw solid #567DCA;
}


.mar-3 {
    margin-right: -19px !important;
    padding-right: 10px !important;
}

.pad-r-3 {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.w-space {
    width: 4px !important;
}

.pad-l-3 {
    padding-left: 7px !important;
}

.pad-l {
    padding-left: 2px !important;
}

.pad-r {
    padding-right: 2px !important;
}


.mal-3 {
    margin-left: -19px !important;
}

.bg-main {
    background-color: #D2E3F5 !important;
}

.platform-888 .bg-main {
    background-color: #D2E3F5 !important;
}

.platform-999 .bg-main {
    background-color: #FFBBBB !important;
}

.platform-999.GREEN .bg-main {
    background-color: #CCF1BC !important;
}

.w-banner {
    width: 100px;
}

.bg-grey-l {
    background-color: #F0F0F0 !important;
}

.second {
    width: 100%;
}

.bg-win {
    background: #f3bc37;
}

.jackpot-wrapper {
    width: 7.4vw;
    height: 3.4vw;
    position: relative;
}

.jackpot-wrapper-mix {
    /*width: 20.4vw !important;*/
    /*height: 9.4vw;*/
    position: relative !important;
}

.under-over-label-wrapper {
    height: 3.85vw;
    position: relative;
}

.jackpot-v2-img {
    max-width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

.range-v2 {
    font-size: 1vw !important;
}

.odds-v2 {
    font-size: 1.5vw !important;
    margin-top: .5vw !important;
    font-weight: 900;
}

.total-result-render-count {
    font-size: 2vw !important;
    line-height: unset !important;
    /*height: 3vw !important;*/
}

.lotto5-show-logo-render img {
    width: 8vw !important;
    height: 8vw !important;
    display: flex;
}

.lotto5-show-logo-render {
    height: 12vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lotto5-mix .row {
    margin: 0;
}

.lotto5-mix .box-bet .UNDER {
    background: linear-gradient(to bottom, #3D00F4, #6532E7, #3D00F4) !important;
    color: #fff !important;
}

.lotto5-mix .box-bet .OVER {
    background: linear-gradient(to bottom, #990000 0%, #df1e1e 50%, #990000) !important;
    color: #fff !important;
}

.platform-999 .lotto5-mix .box-bet .UNDER {
    background: #003CFF !important;
    color: #fff !important;
}

.platform-999 .lotto5-mix .box-bet .OVER {
    background: #C50808 !important;
    color: #fff !important;
}

.platform-999 .lotto5-mix .box-bet .ODD {
    background: #F16522 !important;
    color: #fff !important;
}

.platform-999 .lotto5-mix .box-bet .EVEN {
    background: #FAAF40 !important;
    color: #fff !important;
}

.platform-999 .bg-mix-range-UNDER {
    background: #324DFF40;
}

.platform-999 .bg-mix-range-OVER {
    background: #D0232240;
}

.platform-999 .bg-mix-range-ODD {
    background: #F4753740;
}

.platform-999 .bg-mix-range-EVEN {
    background: #FAB93B40;
}

.lotto5-mix .border-mix {
    border-left: 0.06vw solid #567DCA;
    border-collapse: collapse;
}

.m-vw {
    /*margin: 1vw!important;*/
}


.lotto5-mix .total-result-render-count-warning {
    color: #dd1122 !important;
    font-size: 11vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    height: 100%;
    font-weight: bold;
}

.lotto5-mix .lotto5-show-logo-render {
    height: unset !important;
}

.lotto5-mix .blgo-random-number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
}

.lotto5-mix .total-result-render-count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11vw !important;
    height: 100% !important;
    font-weight: bold;

}


.lotto5-mix .total-result-render-count-total {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.lotto5-mix .lotto5-show-logo-render {
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lotto5-mix .show-number-draw {
    width: 22vw !important;
    height: 22vw !important;
    border-radius: 50% !important;
    line-height: 22vw !important;
}

.lotto5-mix .show-number-draw span {
    color: white;
    font-size: 15vw !important;
}

.lotto5-mix .lotto5-show-logo-render img {
    width: 22vw !important;
    height: 22vw !important;
    /*margin: 50px auto !important;*/
}

.lotto5-mix .over-under-odd-even .box-bet .range {
    font-size: 3vw !important;
    text-align: center;
}

.lotto5-mix .over-under-odd-even .box-bet .odds {
    font-size: 3vw !important;
    text-align: center;
}

.lotto5-mix p {
    line-height: unset;
}

.lotto5 p {
    line-height: 1.2vw;
}

.lotto5-mix .total-result-render-count-total {
    font-size: 11vw !important;
    font-weight: bold;
}

.lotto5-mix .last-result-betting {
    font-size: 3vw !important;
}

.lotto5-mix .under-over {
    font-size: 3vw !important;
    padding: .4vw;
    font-family: BattambangRegular, RobotoRegular;
}

.lotto5-mix .header-label {
    font-size: 3vw !important;
    padding-left: 3vw;
}

.lotto5-mix .bet-period-timer #timer-display {
    font-size: 3vw !important;
}

.border-mix-odds-t {
    border: 0.06vw solid #567DCA;
    border-bottom: 0;
}

.border-mix-odds-b {
    border: 0.06vw solid #567DCA;
    border-top: 0;
}

.num-range {
    font-size: 2.5vw !important;
    line-height: 4.65vw !important;
}

.num-odds {
    font-size: 3.5vw !important;
    font-weight: 900;
    line-height: 4.6vw !important;
}

.p-win {
    padding: 1vw;
}

.head-foo-mix {
    font-size: 1.1vw !important;
    line-height: 2vw;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    font-family: BattambangBold, RobotoBold, serif !important;
}

.head-flex-mix {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.head-flex-mix-label {
    font-size: 3vw;
    font-weight: bold;
    font-family: BattambangBold, RobotoBold, serif !important;
}

.w-load-mix {
    width: 5vw;
    margin-top: 0 !important;
    margin-right: 1.5vw;
}

.lotto5-mix .left-tabs > span,
.lotto6-mix .left-tabs > span,
.lotto12-mix .left-tabs > span {
    width: 49.5vw !important;
    height: 5vw !important;
    font-size: 2.2vw !important;
    font-weight: bold;
}

.lotto5-mix .left-tabs,
.lotto6-mix .left-tabs,
.lotto12-mix .left-tabs {
    height: unset !important;
}

.lotto5-mix .dashboard-fix-height,
.lotto6-mix .dashboard-fix-height,
.lotto12-mix .dashboard-fix-height {
    height: 58.5vw !important;
    overflow-y: scroll;
    padding-bottom: 13vw;
}

.img-lotto5 {
    width: 100%;
    height: 3vw;
}