
.dash-wrapper {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /*min-height: 200vh;*/
}

.lotto12v2 {
    margin-top: 3px;
}

.l-side {
    box-sizing: border-box;
    width: 73%;
    border-radius: 1vw;
    /*display: flex;*/
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0.09615rem;
    min-height: 100%;
    /*margin-right: .5vw;*/
}

.r-side {
    width: 27% !important;
    max-height: 60vh;
}

.d-board {
    padding: .6vw;
    width: 100%;
    height: 24vw;
    min-height: 5.14423rem;
    box-sizing: border-box;
    /*background: url(public/images/zodiac_bg@1x.a104956b.png) no-repeat 100% 100%;*/
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: .3vw;
    border-bottom-right-radius: .3vw;
}

.d-board-Fullscreen {
    /*padding: 1%;*/
    width: 100%;
    height: 100%;
    /*min-height: 5.14423rem;*/
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-lotto12v3 {
    padding-right: 3vw;
}

.turntable {
    padding: 0 0.14423rem 0.14423rem;
    position: relative;
    box-sizing: border-box;
    z-index: 8;
    margin-top: -0.28846rem;
}

.content-left-bet-code {
    margin-left: -1vw;
    margin-top: 1.2vw;
    width: 22vw;
    height: 19vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 0.19231vw;
    z-index: 2;
}

.d-lotto12v3 .content-left-bet-code {
    width: 30vw;
    padding-left: 6.8vw;
    margin-left: -10vw;
    margin-top: -3.5vw;
    height: 20vw;
}

.d-board-Fullscreen .content-left-bet-code {
    margin-left: -2vw;
    width: 46vw;
    height: 42vw;
}

.d-board-Fullscreen .d-lotto12v3 .content-left-bet-code {
    padding-left: 19.5vw !important;
    margin-left: -25vw;
    width: 69vw;
    height: 42vw;
    margin-top: -.5vw;
}

.deng-img {
    position: absolute;
    width: 21vw;
    height: 21vw;
    z-index: 9;
}

.d-board-Fullscreen . deng-img {
    width: 48vw !important;
    height: 48vw !important;
}

.d-board-Fullscreen .d-lotto12v3 .deng-img {
    width: 48vw !important;
    height: 48vw !important;
    max-width: unset !important;
}

.turntable .zodiac_div {
    width: 21vw;
    height: 21vw;
    /*background: url(./zodiac_left_bg@2x.57c2301a.png) no-repeat;*/
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 1.2vw;
}

.d-board-Fullscreen .turntable .zodiac_div {
    width: 48vw;
    height: 48vw;
    padding: 2.5vw;
}

.flex-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 25%;
}

.flex-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 9vw;
    box-sizing: border-box;
}

.d-board-Fullscreen .flex-two {
    height: 21vw;
}

.zodiac_item {
    width: 25%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zodiac_item_img {
    width: 100%;
    height: 100%;
    transform: scale(1.52);
}

.flex-f {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    height: 9vw;
}

.d-board-Fullscreen .flex-f {
    height: 21vw;
}

.zodiac_item_w14 {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.w72 {
    width: 50%;
    height: 100%;
    padding: 0.03846rem;
    box-sizing: border-box;
    text-align: center;
}

.zodiac_center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-left-bet-issueno {
    margin-bottom: 0.28846vw;
    font-size: 1.4vw;
    font-weight: 700;
    color: #fddb21;
    text-align: center;
}

.d-lotto12v3 .content-left-bet-issueno {
    color: white;
    font-size: 1.5vw;
    margin-right: 1vw;
}

.d-board-Fullscreen .content-left-bet-issueno {
    font-size: 3.4vw;
}

.currentNumber_off {
    width: 8.5vw;
    padding: .5vw;
    text-align: center;
    background: #ffffff;
    border-radius: 5vw;
    opacity: 1;
    border: 2px solid #fdbf85;
    box-sizing: border-box;
}

.d-lotto12v3 .currentNumber_off {
    background: unset;
    border: none;
    color: white;
}

.d-board-Fullscreen .currentNumber_off {
    width: 17.5vw;
    border: 2px solid #fdbf85;
}

.d-board-Fullscreen .d-lotto12v3 .currentNumber_off {
    border: none !important;
}

.d-board-Fullscreen .countdown {
    font-size: 3.6vw;
}

.d-board-Fullscreen .d-lotto12v3 .countdown {
    font-size: 5vw;
}

.countdown {
    /*height: 0.61538vw;*/
    font-size: 1.6vw;
    line-height: 1;
    font-weight: 700;
    color: #333;
}

.d-lotto12v3 .countdown {
    color: white;
    font-size: 2.5vw;
}


.d-board-Fullscreen .currentNumber_off .countdown-title {
    font-size: 2vw;
}

.currentNumber_off .countdown-title {
    /*height: 0.61538vw;*/
    font-size: .9vw;
    line-height: 1;
    font-weight: 700;
    color: #333;
}

.currentNumber_on {
    height: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-animation: myfirst-2e2a6de3 2s;
    -webkit-animation-timing-function: cubic-bezier(.25, .1, .25, 1);
    -webkit-animation-iteration-count: 1;
    animation: myfirst-2e2a6de3 2s;
    animation-timing-function: cubic-bezier(.25, .1, .25, 1);
    animation-iteration-count: 1
}

.d-lotto12v3 .currentNumber_on {
    margin-top: 1vw;
}

.d-board-Fullscreen .d-lotto12v3 .currentNumber_on {
    margin-top: 4vw;
}

@keyframes myfirst-2e2a6de3 {
    0% {
        -webkit-transform: translateY(-5vw)
    }

    to, 20%, 50%, 80% {
        -webkit-transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-1vw)
    }

    60% {
        -webkit-transform: translateY(-.6vw)
    }
}

.text-result {
    font-size: 3vw;
    color: white;
    margin-right: .6vw;
    font-weight: bold;
}

.d-board-Fullscreen .text-result {
    font-size: 6vw;
}

.img-result {
    width: 4vw;
    height: 3.9vw;
}

.d-lotto12v3 .img-result {
    width: 5vw;
    height: 4.9vw;
}

.d-board-Fullscreen .d-lotto12v3 .img-result {
    width: 13vw;
    height: 12.9vw;
}

.d-board-Fullscreen .img-result {
    width: 7vw;
    height: 6.9vw;
}

.zodiac_border {
    width: 92%;
    height: 10vw;
    margin-top: 3vw;
}

.d-lotto12v3 .zodiac_border {
    margin-top: unset;
}

.d-board-Fullscreen .zodiac_border {
    height: 23vw;
}

.movements {
    width: 100%;
    height: 9vw;
    display: block;
    justify-content: center;
    align-items: center;
}

.d-board-Fullscreen .movements {
    height: 22vw;
}

.column {
    width: 10%;
    border-left: 1px solid #6e4145;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    align-items: center;
}

.line:nth-child(1) {
    border-top: 1px solid #6e4145;
}

.line {
    width: 100%;
    /*height: 1.7vw;*/
    border-bottom: 1px solid #6e4145;
    display: flex;
    justify-content: center;
    align-items: center;
}

.range1 {
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: .7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial-Bold, Arial;
    font-weight: 700;
}

.d-board-Fullscreen .range1 {
    width: 2.8vw;
    height: 2.8vw;
    font-size: 1.3vw;
}

.column {
    /*width: 1.4vw;*/
    height: 1.5vw;
    border-left: 1px solid #6e4145;
    box-sizing: border-box;
}

.d-board-Fullscreen .column {
    border-left: 2px solid #6e4145;
    height: 3.5vw;
}

.i-fullscreen {
    position: relative;
    right: -96%;
    width: 1.5vw;
    height: 1.5vw;
}

.d-board-Fullscreen .i-fullscreen {
    position: absolute;
    right: .5vw;
    top: .5vw;
    width: 2vw;
    height: 2vw;
}


.bet-bottom {
    box-sizing: border-box;
    padding: 5px 20px 20px 20px !important;
    width: 100%;
    min-height: 20px !important;
    background: #f6f7f9 !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 2px #0000001a, 0 2px 2px #0000001a inset;
}

.bet-bottom-list {
    display: flex;
    align-items: center;
    /*margin-bottom: 1vw !important;*/
}

.bet-bottom-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 10vw !important;
    height: 3vw !important;
    margin-right: 1vw !important;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 0.5vw;
    position: relative;
}

.bet-bottom-list-item-odds {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5vw;
    height: 1.5vw;
    font-size: .9vw;
    font-weight: 700;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    padding: 0.5vw 0.2vw;
}

.zodiac-img {
    width: 2.8vw !important;
    height: 2.8vw;
    margin: 0.5vw;
}

.sign-wrapper {
    padding: 0 .5vw !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-input {
    position: absolute;
    right: 10px;

}

.el-input__wrapper {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 4.7vw;
    height: 1.2vw;
    padding: 0.00962rem 0.10577rem;
    background-color: #ffffff;
    background-image: none;
    border-radius: 0.2vw;
    transition: cubic-bezier(.645, .045, .355, 1);
    transform: translateZ(0);
    box-shadow: 0 0 0 0.02vw inset;
}


.el-input__wrapper:hover {
    box-shadow: 0 0 0 .08vw #409eff inset
}

.el-input__wrapper.is-focus {
    box-shadow: 0 0 0 .08vw #409eff inset

}

.el-input__inner.form-control:focus {
    border-color: unset !important;
    box-shadow: unset !important;
    background: unset !important;
}


.el-input__inner {
    height: .8vw;
    width: 100%;
    flex-grow: 1;
    -webkit-appearance: none;
    color: #606266;
    font-size: inherit;
    padding: 0 5px;
    outline: 0;
    border: none;
    background: 0 0;
    border-radius: 1px !important;
    box-sizing: border-box;
}

.bgzodiac1 {
    background: #f22f00;
}

.bgzodiac2 {
    background: #3fa745;
}

.bgzodiac3 {
    background: #559beb;
}


.bet-bottom .bet-bottom-list .select1 {
    border: 1px solid #f22f00 !important;
    background: #fdcdcd !important;
    border-radius: 0.3vw;
}

.bet-bottom .bet-bottom-list .zodiac-color1:hover {
    background: #fdcdcd !important;
    border-radius: 0.3vw;
}

.bet-bottom .bet-bottom-list .zodiac-color2:hover {
    background: #cdefcf !important;
    border-radius: 0.3vw;
}

.bet-bottom .bet-bottom-list .select2 {
    border: 1px solid #3fa745 !important;
    background: #cdefcf !important;
    border-radius: 0.3vw;
}

.bet-bottom .bet-bottom-list .zodiac-color3:hover {
    background: #c3dfff !important;
    border-radius: 0.3vw;
}

.bet-bottom .bet-bottom-list .select3 {
    border: 0.1vw solid #559beb !important;
    background: #c3dfff !important;
    border-radius: 0.3vw;
}

.quickAmount {
    display: flex;
    align-items: center;
    /*width: 10vw;*/
    height: 1.4vw;
    border: 0.1vw solid #dddddd;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 51%, #e9e9e9 100%);
    border-radius: 0.2vw;
    box-sizing: border-box;
}

.bet-bottom-balance {
    margin-left: 5vw;
    text-align: right;
}

.btn-ok {
    color: #fff !important;
    background: linear-gradient(90deg, #fa7002 0%, #f44402 100%) !important;
}

.bet-bottom-btn {
    margin: 0 0.8vw;
    padding: .6vw .8vw;
    font-size: .8vw;
    color: #c1c1c1;
    font-weight: 700;
    background: linear-gradient(360deg, #dadada 0%, #eeeeee 42%, #ececec 100%);
    box-shadow: 0 1px #00000080;
    opacity: 1;
    border-radius: 0.2vw;
    text-align: center;
    cursor: pointer;
}


.bet-bottom-clear {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #c1c1c1;
}

.bet-bottom-clear p {
    color: #c1c1c1;
}

.bet-bottom-clear.data-ok {
    color: #9e77ff !important;
}

.bet-bottom-clear.data-ok p {
    color: #9e77ff !important;
}


.quickAmount .quickAmount-active {
    border: 0.1vw solid #7f65d8;
}

.quickAmount .quickAmount-item {
    width: 3vw;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}

.quickAmount .quickAmount-item .quickAmount-value {
    width: 100%;
    font-size: 0.7vw;
    color: #333;
    text-align: center;
    line-height: 1.5vw;
    border-right: 0.00962rem solid #dddddd;
    box-sizing: border-box;
}

.mtop {
    margin-top: -0.09615rem;
    border-top: 0.02885rem solid #f0f0f0;
    border-top-left-radius: 0.08654rem;
    border-top-right-radius: 0.08654rem;
}

.lv3-flex {
    display: flex;
    align-items: center;
}


.f-delete {
    font-size: 1.5vw;
}

.bet-bottom-balance p, .bet-bottom-balance p span {
    font-size: 0.7vw;
    font-weight: 600;
}

.results .list .item .item-code {
    display: inline-block;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    font-size: 0.8vw;
    font-weight: 700;
    color: #fff;
    line-height: 1.5vw;
    text-align: center;
}

.results .list .item span {
    font-size: 0.8vw;
}

.s-ONE, .s-TWO, .s-THREE, .s-FOUR, .s-RED {
    background: rgb(242, 47, 0);
}

.s-NINE, .s-TEN, .s-ELEVEN, .s-TWELVE, .s-BLUE {
    background: rgb(85, 155, 235);
}

.s-FIVE, .s-SIX, .s-SEVEN, .s-EIGHT, .s-GREEN {
    background: rgb(63, 167, 69);
}

.item-code-result {
    display: inline-block;
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 50%;
    font-size: 0.9vw;
    font-weight: 700;
    color: #fff !important;
    line-height: 1.6vw;
    text-align: center;
}

.d-lotto12v3 .turntable {
    padding: 0 0vw;
    position: relative;
    box-sizing: border-box;
    z-index: 8;
    margin-top: -3vw;
}

.d-board-Fullscreen .d-lotto12v3 .turntable {
    margin-top: 1vw;
}

.d-lotto12v3 .turntable .lucky-wheel {
    width: 26vw;
    height: 26vw;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.d-board-Fullscreen .d-lotto12v3 .turntable .lucky-wheel {
    width: 54vw;
    height: 54vw;
}


.d-lotto12v3 .turntable img {
    height: auto;
    max-width: 100%;
}

.d-lotto12v3 .turntable .deng-img {
    position: absolute;
    left: 2.5vw;
    top: 2.5vw;
    width: 21vw;
    height: 21vw;
    z-index: 10;
}

/*.d-board-Fullscreen .d-lotto12v3 .turntable .deng-img {*/
/*    left: 0vw !important;*/
/*    top: -10.5vw !important;*/
/*}*/

.d-lotto12v3 .turntable .arrow-logo1 {
    position: absolute;
    width: 4.5vw;
    height: 4.5vw;
    z-index: 11;
}

.d-board-Fullscreen .d-lotto12v3 .turntable .arrow-logo1 {
    width: 8.5vw;
    height: 8.5vw;
}

.d-board-Fullscreen .d-lotto12v3 .turntable .arrow {
    width: 19vw;
    height: 19vw;
}

.d-lotto12v3 .turntable .arrow {
    position: absolute;
    width: 10vw;
    height: 10vw;
    background-size: 100% 100%;
    z-index: 10;
}

.d-lotto12v3 .drawing-text div, .d-lotto12v3 .drawing-text p {
    color: white;
    font-weight: bold;
}

.d-lotto12v3 .drawing-text div {
    font-size: 1.7vw;
}

.d-board-Fullscreen .d-lotto12v3 .drawing-text div {
    font-size: 4vw;
}

.d-lotto12v3 .drawing-text p {
    font-size: 2vw;
}

.d-board-Fullscreen .d-lotto12v3 .drawing-text p {
    font-size: 4vw;
}

.d-lotto12v3 .drawing-text div.text-result {
    font-size: 4vw;
}

.d-board-Fullscreen .d-lotto12v3 .drawing-text div.text-result {
    font-size: 10vw;
}

.currentNumber_on_div {
    width: 3.2vw;
    height: 3.2vw;
    margin-right: 0.6vw;
    margin-left: 0.6vw;
    border-radius: .5vw;
}

.d-board-Fullscreen .currentNumber_on_div {
    width: 7vw;
    height: 7vw;
    margin-right: 3vw;
    margin-left: 3vw;
}

.bet-bottom-list-item-title {
    width: 3vw !important;
    /*height: 3vw;*/
    font-size: .7vw;
    font-weight: 700;
    color: #333;
    margin: 0.5vw;
    padding-left: .1vw;
    display: flex;
    text-align: center;
    align-items: center;
}

.bet-bottom-list-title {
    width: 4vw !important;
    /*height: 3vw;*/
    font-size: .7vw;
    font-weight: 700;
    color: #333;
    display: flex;
    text-align: center;
    align-items: center;
}
