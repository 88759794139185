@media only screen and (min-width: 3512px) {

    .lotto12-countdown-number {
        padding-top: 5px;
    }

    .lotto12-countdown-number > div {
        font-size: 45px !important;
    }

    .dashboard-fix-height {
        max-height: 479px;
        overflow-y: auto !important;
    }

    /* The bet pricing */
    .bet-pricing-card {
        height: 497.2px;
        max-height: 500px;
        border: 1px solid #000;
    }

    .slot-prefix-card {
        height: 55%;
        background-color: #e7e8ea;
    }

    .slot-value-card {
        height: 45%;
        background: linear-gradient(-45deg, #ffff00, #ffff00, #f9cc2f);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        color: #fff;
        border-top: 2px solid #000;
    }

    .slot-prefix-card > h1 {
        line-height: 270px !important;
        font-size: 6em !important;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .card-bg-color-UNDER > h1, .card-bg-color-OVER > h1 {
        color: white;
        font-size: 5em !important;
    }

    .range-number {
        line-height: 3em !important;
        font-weight: 800;
        color: #000;
        font-size: 1.6em !important;
    }

    .bet-value {
        margin-top: -75px;
        font-size: 3em !important;
        color: #2621a1 !important;
        font-weight: 800;
    }

    /* The bet pricing */
    /* The betting history */
    .bet-item {
        line-height: 2.5em;
        width: 97px;
        height: 99.7px;
        border: 1px solid #000;
        font-size: 1em;
        font-weight: 600;
        background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
        padding: 5px;
        padding-top: 11px;
    }

    .lotto6-statistic-ball-UNDER, .lotto6-statistic-ball-OVER {
        margin: 5px 7px 7px 12px !important;
        width: 70px;
        height: 70px;
    }

    .lotto6-statistic-ball-ONE, .lotto6-statistic-ball-TWO, .lotto6-statistic-ball-THREE, .lotto6-statistic-ball-FOUR, .lotto6-statistic-ball-FIVE, .lotto6-statistic-ball-SIX {
        margin: 2px 2px 2px 7px !important;
        width: 75px;
        height: 75px;
        border-radius: 45px;
        line-height: 72px !important;
        font-size: 1.6em;
    }

    /* The end of betting history */
    /* History old style */
    .lotto6-history-result table td {
        height: 117px !important;
    }

    .lotto6-history-result table td span {
        line-height: 87px !important;
        font-size: 43px !important;
    }

    .lotto12-history-result table td span {
        font-size: 42px !important;
    }


    .statistic-ball-, .statistic-ball-OVER, .statistic-ball-UNDER, .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX, .lotto12-statistic-ball-UNDER, .lotto12-statistic-ball-OVER, .statistic-ball-BLUE, .statistic-ball-RED, .statistic-ball-GREEN {
        margin: 0 auto !important;
        width: 85px;
        height: 85px;
        border-radius: 45px;
    }

    .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX {
        line-height: 75px !important;
    }

    /* Then of history old style */
    .btn-remove-betting {
        width: 61px;
        height: 61px;
        border-radius: 25px;
        padding: 5px;
    }

    .input-date-range {
        height: 64px !important;
        margin-top: 10px !important;
    }


    /* Logo and number render */
    .lotto6-show-logo-render img {
        /*width: 308px !important;*/
        /*margin: 45px auto !important;*/
    }

    .card-count-down-logo {
        height: 275px !important;
        max-height: 275px !important;
    }


    /* The end of logo and number render */
    /* The Six 36-v2 bet pricing */
    .lotto6-v2-show-logo-render img {
        width: 342px;
        margin: 26px auto;
    }

    .lotto6-v2-card-count-down-logo {
        height: 391px;
        max-height: 393.5px;
    }

    .lotto6-v2-result-time-countup-render {
        width: 13%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        background: #ad0026;
        margin: 2px;
        position: absolute;
    }

    .lotto6-v2-show-number-draw {
        font-size: 175px !important;
        line-height: 206px;
        width: 305px;
        height: 305px;
        margin: 45px auto;
        border-radius: 230px;
    }

    .lotto6-v2-total-result-render-count {
        color: rgb(255, 255, 255) !important;
        font-size: 180px !important;
        line-height: 396px !important;
    }

    .lotto6-v2-total-result-render-count-warning {
        color: #fce700 !important;
        font-size: 180px !important;
        line-height: 396px !important;
    }

    .lotto6-v2-total-result-render-count-total {
        font-size: 180px !important;
        line-height: 396px !important;
    }

    .lotto6-v2-dashboard-fix-height {
        height: 400px;
        overflow-y: auto;
    }

    /*.lotto6-v2-bet-pricing-card {*/
    /*    height: 260.7px;*/
    /*    border: 1px solid #000;*/
    /*}*/
    .lotto6-v2-slot-prefix-card > h1 {
        line-height: 165px !important;
        font-size: 5em !important;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .lotto6-v2-range-number {
        line-height: 2em;
        font-weight: 800;
        color: #000;
        font-size: 1em;
    }

    .lotto6-v2-bet-value {
        margin-top: -41px;
        font-size: 2em !important;
        color: #2621a1;
        font-weight: 800;
    }

    /* The Six 36-v2 bet pricing */
    .modal-keno-betting {
        overflow-y: auto;
        z-index: 20;
        width: 876px !important;
        height: auto;
        background-color: #ffffff;
        border-radius: 10px;
        margin: 0 auto;
    }

    .btn-bet-amount-red {
        font-size: 1.2em !important;
    }

    /*
        Lotto5 for responsive
    */
    .lotto5 .bet-period-timer, .lotto5 .loading-gif, .lotto5-dispaly-result .lotto5-total {
        height: 197px !important;
    }

    .lotto5-dispaly-result .lotto5-total .label-title, .lotto5-dispaly-result .lotto5-total .total-result {
        font-size: 40px !important;
    }

    .lotto5 .bet-period-timer #period-before {
        font-size: 35px !important;
        line-height: 75px !important;
    }

    .loading-gif > img {
        width: 100px !important;
        height: 100px !important;
    }

    .lotto5-content-result table td span {
        /*line-height: 120px !important;*/
        font-size: 49px !important;
    }

    .lotto5 .under-over {
        line-height: 110px !important;
        font-size: 70px !important;
    }

    .lotto5-total-result-render {
        /*line-height: 110px !important*/
    }

    .lotto5 .result-time-countup-render {
        width: 13%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        background: #ad0026;
        margin: 2px;
        position: absolute;
    }

    /* Render Draw */
    .lotto5 .total-result-render-count-total, .total-result-render-count, .lotto5 .total-result-render-count-warning {
        font-size: 85px !important;
    }

    /* Bet box */
    .over-under-odd-even .box-bet div .type, .lotto5 .over-under-odd-even .box-bet div .type-rage {
        line-height: 215px !important;
        font-size: 100px !important;
    }

    .lotto5 .over-under-odd-even .box-bet .odds {
        line-height: 48px !important;
    }

    /* Bet history */
    .lotto5-history-result table td {
        height: 105px !important;
    }

    .lotto5-history-result table td label {
        font-size: 45px !important;
    }

    .lotto5-history-result table td span {
        font-size: 36px !important;
    }

    .lotto12-mix .lotto12-countdown-number > div {
        font-size: 22px !important;
    }


    .logo {
        width: 165px !important;
        display: inline-block;
    }

    .logo img {
        height: 125px !important;
        max-width: 125px !important;
        padding-top: 0 !important;
        display: block !important;
        margin-left: 15px !important;
    }

    .right-top-bar div img {
        width: 55px;
    }

    .loading-gif img {
        margin-top: 50px;
    }

    .btn-logout {
        font-size: 30px !important;
    }

    .header-right div img {
        width: 65px;
    }


    .left-bet-type-TWO, .left-bet-type-THREE, .left-bet-type-FOUR, .left-bet-type-FIVE, .left-bet-type-OVER, .left-bet-type-UNDER {
        padding: 0 10px !important;
    }

    .over-under-odd-even .box-bet div .type {
        height: 236px !important;
    }

    .btn-refresh-dashboard img {
        width: 45px;
        margin-right: 15px;
    }

}

@media only screen and (max-width: 3012px) {

    .card-count-down-logo {
        height: 275px !important;
        max-height: 275px !important;
    }


    /* The end of logo and number render */
    /* The bet pricing */
    .bet-pricing-card {
        height: 297.5px;
        max-height: 299px;
        border: 1px solid #000;
    }

    .slot-prefix-card {
        height: 66%;
        background-color: #e7e8ea;
    }

    .slot-value-card {
        height: 34%;
        background: linear-gradient(-45deg, #ffff00, #ffff00, #f9cc2f);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        color: #fff;
        border-top: 2px solid #000;
    }

    .slot-prefix-card > h1 {
        line-height: 200px;
        font-size: 7em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .card-bg-color-UNDER > h1, .card-bg-color-OVER > h1 {
        color: white;
        font-size: 5em !important;
    }

    .range-number {
        line-height: 2.5em;
        font-weight: 800;
        color: #000;
        font-size: 1.5em;
    }

    .bet-value {
        margin-top: -28px;
        font-size: 2em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The bet pricing */
    /* The betting history */
    .bet-item {
        line-height: 2.5em;
        width: 69px;
        height: 69.2px;
        border: 1px solid #000;
        font-size: 1em;
        font-weight: 600;
        background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
        padding: 1px;
        padding-top: 5px;
    }

    .lotto6-statistic-ball-UNDER, .lotto6-statistic-ball-OVER {
        margin: 4px 7px 7px 9px !important;
        width: 50px;
        height: 50px;
    }

    .lotto6-statistic-ball-ONE, .lotto6-statistic-ball-TWO, .lotto6-statistic-ball-THREE, .lotto6-statistic-ball-FOUR, .lotto6-statistic-ball-FIVE, .lotto6-statistic-ball-SIX {
        margin: 3px 2px 2px 8px !important;
        width: 53px;
        height: 54px;
        border-radius: 56px;
        line-height: 51px !important;
        font-size: 1em;
    }

    /* The end of betting history */
    /* History old style */
    .lotto6-history-result table td {
        height: 68.7px;
    }

    .lotto6-history-result table td span {
        line-height: 30px;
        font-size: 25px;
    }

    .lotto12-history-result table td span {
        font-size: 27px;
    }

    .statistic-ball-, .statistic-ball-OVER, .statistic-ball-UNDER, .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX, .lotto12-statistic-ball-UNDER, .lotto12-statistic-ball-OVER, .statistic-ball-BLUE, .statistic-ball-RED, .statistic-ball-GREEN {
        margin: 0 auto !important;
        width: 50px;
        height: 50px;
        border-radius: 35px;
    }

    .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX {
        line-height: 50px !important;
    }

    /* Then of history old style */
    .btn-remove-betting {
        width: 61px;
        height: 37px;
        border-radius: 25px;
        padding: 5px;
    }


    /* The Six 36-v2 bet pricing */
    .lotto6-v2-show-logo-render img {
        width: 222px;
        margin: 26px auto;
    }

    .lotto6-v2-card-count-down-logo {
        height: 277px;
        max-height: 278.5px;
    }

    .lotto6-v2-show-number-draw {
        font-size: 110px;
        width: 194px;
        height: 194px;
        margin: 35px auto;
        line-height: 202px;
    }

    .lotto6-v2-result-time-countup-render {
        width: 13%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        background: #ad0026;
        margin: 2px;
        position: absolute;
    }

    .lotto6-v2-total-result-render-count {
        color: rgb(255, 255, 255) !important;
        font-size: 110px;
        line-height: 276px;
    }

    .lotto6-v2-total-result-render-count-warning {
        color: #fce700 !important;
        font-size: 110px;
        line-height: 276px;
    }

    .lotto6-v2-total-result-render-count-total {
        font-size: 110px;
        line-height: 276px;
    }

    .lotto6-v2-dashboard-fix-height {
        height: 400px;
        overflow-y: auto;
    }

    .lotto6-v2-result table td span {
        line-height: 90.5px;
        font-size: 21px;
    }

    /*.lotto6-v2-bet-pricing-card {*/
    /*    height: 184.7px;*/
    /*    border: 1px solid #000;*/
    /*}*/
    .lotto6-v2-slot-prefix-card > h1 {
        line-height: 104px;
        font-size: 5em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .lotto6-v2-range-number {
        line-height: 2em;
        font-weight: 800;
        color: #000;
        font-size: 1em;
    }

    .lotto6-v2-bet-value {
        margin-top: -19px;
        font-size: 2em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The Six 36-v2 bet pricing */
    /*
        Lotto5 for responsive
    */
    .lotto5 .bet-period-timer, .lotto5 .loading-gif, .lotto5-dispaly-result .lotto5-total {
        height: 68px;
    }

    .lotto5-dispaly-result .lotto5-total .label-title, .lotto5-dispaly-result .lotto5-total .total-result {
        font-size: 21px;
        line-height: 2.1;
    }

    .bet-period-timer #timer-display, .lotto5 .bet-period-timer #period-before {
        font-size: 21px;
        line-height: 36px;
    }

    .loading-gif > img {
        width: 40px;
        height: 40px;
    }

    .lotto5 .under-over {
        line-height: 60px;
        font-size: 18px;
    }

    .lotto5 .lotto5-content-result table td span {
        /*line-height: 56px;*/
        font-size: 25px;
    }

    .lotto5-total-result-render {
        /*line-height: 61px !important;*/
    }

    .lotto5 .result-time-countup-render {
        width: 13%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        background: #ad0026;
        margin: 2px;
        position: absolute;
    }

    /* Render Draw */
    .lotto5 .total-result-render-count-total, .total-result-render-count, .lotto5 .total-result-render-count-warning {
        font-size: 30px;
    }


    /* Bet box */
    .over-under-odd-even .box-bet div .type, .lotto5 .over-under-odd-even .box-bet div .type-rage {
        line-height: 102px;
    }

    .lotto5 .over-under-odd-even .box-bet .odds {
        line-height: 48px;
    }

    /* Bet history */
    .lotto5-history-result table td {
        height: 45px;
    }

    .lotto5-history-result table td label {
        font-size: 18px;
        line-height: 45px;
    }

    .lotto5-mix .lotto5-history-result table td label {
        font-size: 14px;
        line-height: 40px;
    }

    .lotto5-history-result table td span {
        font-size: 18px;
    }


}

@media only screen and (max-width: 2512px) {

    .dashboard-fix-height {
        max-height: 479px;
        overflow-y: auto;
    }

    /* The bet pricing */
    .bet-pricing-card {
        height: 260.1px;
        max-height: 312px;
        border: 1px solid #000;
    }

    .slot-prefix-card {
        height: 60%;
        background-color: #e7e8ea;
    }

    .slot-value-card {
        height: 40%;
        background: linear-gradient(-45deg, #ffff00, #ffff00, #f9cc2f);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        color: #fff;
        border-top: 2px solid #000;
    }

    .slot-prefix-card > h1 {
        line-height: 170px;
        font-size: 5em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .card-bg-color-UNDER > h1, .card-bg-color-OVER > h1 {
        color: white;
        font-size: 5em !important;
    }

    .range-number {
        line-height: 2em;
        font-weight: 800;
        color: #000;
        font-size: 1.5em;
    }

    .bet-value {
        margin-top: -24px;
        font-size: 3em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The bet pricing */
    /* The betting history */
    .bet-item {
        line-height: 2.5em;
        width: 56px;
        height: 60.1px;
        border: 1px solid #000;
        font-size: 1em;
        font-weight: 600;
        background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
        padding: 5px;
        padding-top: 9px;
    }

    .lotto6-statistic-ball-UNDER, .lotto6-statistic-ball-OVER {
        margin: 1px 2px 2px 3px !important;
        width: 40px;
        height: 40px;
    }

    .lotto6-statistic-ball-ONE, .lotto6-statistic-ball-TWO, .lotto6-statistic-ball-THREE, .lotto6-statistic-ball-FOUR, .lotto6-statistic-ball-FIVE, .lotto6-statistic-ball-SIX {
        margin: 0px 2px 2px 1px !important;
        width: 44px;
        height: 44px;
        border-radius: 35px;
        line-height: 40px !important;
        font-size: 0.7em;
    }

    /* The end of betting history */
    /* History old style */
    .lotto6-history-result table td {
        height: 60.7px;
    }

    .lotto6-history-result table td span {
        line-height: 30px;
        font-size: 25px;
    }

    .lotto12-history-result table td span {
        font-size: 25px;
    }

    .statistic-ball-, .statistic-ball-OVER, .statistic-ball-UNDER, .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX, .lotto12-statistic-ball-UNDER, .lotto12-statistic-ball-OVER, .statistic-ball-BLUE, .statistic-ball-RED, .statistic-ball-GREEN {
        margin: 0 auto !important;
        width: 40px;
        height: 40px;
        border-radius: 35px;
    }

    .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX {
        line-height: 40px !important;
    }

    /* Then of history old style */
    .btn-remove-betting {
        width: 42px;
        height: 29px;
        border-radius: 25px;
        padding: 1px;
    }


    /* Logo and number render */
    .lotto6-show-logo-render img {
        /*width: 205px !important;*/
        /*margin: 20px auto !important;*/
    }


    /* The end of logo and number render */
    /* The Six 36-v2 bet pricing */
    .lotto6-v2-show-logo-render img {
        width: 165px;
        margin: 37px auto;
    }

    .lotto6-v2-card-count-down-logo {
        height: 237px;
        max-height: 237px;
    }

    .lotto6-v2-show-number-draw {
        font-size: 90px;
        width: 150px;
        height: 150px;
        margin: 46px auto;
        line-height: 150px;
    }

    .lotto6-v2-result-time-countup-render {
        width: 13%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        background: #ad0026;
        margin: 2px;
        position: absolute;
    }

    .lotto6-v2-total-result-render-count {
        color: rgb(255, 255, 255) !important;
        font-size: 110px;
        line-height: 252px;
    }

    .lotto6-v2-total-result-render-count-warning {
        color: #fce700 !important;
        font-size: 110px;
        line-height: 252px;
    }

    .lotto6-v2-total-result-render-count-total {
        font-size: 110px;
        line-height: 252px;
    }

    .lotto6-v2-dashboard-fix-height {
        height: 400px;
        overflow-y: auto;
    }

    .lotto6-v2-result table td span {
        line-height: 77.5px;
        font-size: 1.5em;
    }

    /*.lotto6-v2-bet-pricing-card {*/
    /*    height: 157.7px;*/
    /*    border: 1px solid #000;*/
    /*}*/
    .lotto6-v2-slot-prefix-card > h1 {
        line-height: 88px;
        font-size: 5em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .lotto6-v2-range-number {
        line-height: 2em;
        font-weight: 800;
        color: #000;
        font-size: 1em;
    }

    .lotto6-v2-bet-value {
        margin-top: -16px;
        font-size: 2em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The Six 36-v2 bet pricing */
    /*
        Lotto5 for responsive
    */
    .lotto5 .bet-period-timer, .lotto5 .loading-gif, .lotto5-dispaly-result .lotto5-total {
        height: 53px;
    }

    .lotto5-dispaly-result .lotto5-total {
        height: auto;
    }

    .lotto5-mix .bet-period-timer, .lotto5-mix .loading-gif, .lotto5-mix .lotto5-total {
        height: 7vw;
        line-height: 7vw;
    }

    .lotto5-dispaly-result .lotto5-total .label-title, .lotto5-dispaly-result .lotto5-total .total-result {
        font-size: 16px;
        line-height: 2.1;
        margin-top: 3px;
        font-weight: 900;
    }

    .bet-period-timer #timer-display, .lotto5 .bet-period-timer #period-before {
        font-size: 16px;
        line-height: 49px;
        font-weight: bold;
    }

    .loading-gif > img {
        width: 25px;
        height: 25px;
    }

    .platform-999 .loading-gif > img {
        width: 35px;
        height: 35px;
    }

    .lotto5 .under-over, .lotto5-content-result table td span {
        /*line-height: 35px;*/
        font-size: 20px;
        color: #21397B;
    }

    .lotto5-total-result-render {
        /*line-height: 35px !important;*/
    }

    .lotto5-mix .lotto5-total-result-render {
        line-height: 151px !important;
    }

    .lotto5 .result-time-countup-render {
        width: 13%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        background: #ad0026;
        margin: 2px;
        position: absolute;
    }

    /* Render Draw */
    .lotto5 .total-result-render-count-total, .total-result-render-count, .lotto5 .total-result-render-count-warning {
        font-size: 30px;
    }

    /* Bet box */
    .over-under-odd-even .box-bet div .type, .lotto5 .over-under-odd-even .box-bet div .type-rage {
        line-height: 160px;
    }

    .box-bet div .type, .lotto5 .over-under-odd-even .box-bet div .type-rage {
        font-size: 65px;
        line-height: 130px;
        font-family: "Arial Black", sans-serif !important;
        font-weight: 900;

    }

    .lotto5 .over-under-odd-even .box-bet .odds {
        line-height: 38px;
    }

    /* Bet history */
    .lotto5-history-result table td {
        height: 45px;
    }

    .lotto5-history-result table td label, .lotto12-history-result table td label {
        font-size: 18px;
        line-height: 45px;
    }

    .lotto5-history-result table td span, .lotto12-history-result table td span {
        font-size: 20px;
    }


}

@media only screen and (max-width: 2012px) {

    /* end font text */
    .dashboard-fix-height {
        max-height: 479px;
        overflow-y: auto;
    }


    /* The bet pricing */
    .bet-pricing-card {
        height: 234px;
        max-height: 300px;
        border: 1px solid #000;
    }

    .slot-prefix-card {
        height: 60%;
        background-color: #e7e8ea;
    }

    .slot-value-card {
        height: 40%;
        background: linear-gradient(-45deg, #ffff00, #ffff00, #f9cc2f);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        color: #fff;
        border-top: 2px solid #000;
    }

    .slot-prefix-card > h1 {
        line-height: 146px;
        font-size: 4em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .card-bg-color-UNDER > h1, .card-bg-color-OVER > h1 {
        color: white;
        font-size: 4em !important;
    }

    .range-number {
        line-height: 4em;
        font-weight: 800;
        color: #000;
        font-size: 1.2em;
    }

    .bet-value {
        margin-top: -33px;
        font-size: 2em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The bet pricing */
    /* The betting history */
    .bet-item {
        line-height: 2.5em;
        width: 43px;
        height: 45px;
        border: 1px solid #000;
        font-size: 1em;
        font-weight: 600;
        background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
        padding: 2px;
        padding-top: 4px;
    }

    .lotto6-statistic-ball-UNDER, .lotto6-statistic-ball-OVER {
        margin: 0px 2px 2px 2px !important;
        width: 35px;
        height: 35px;
    }

    .lotto6-statistic-ball-ONE, .lotto6-statistic-ball-TWO, .lotto6-statistic-ball-THREE, .lotto6-statistic-ball-FOUR, .lotto6-statistic-ball-FIVE, .lotto6-statistic-ball-SIX {
        margin: 0px 2px 2px 2px !important;
        width: 35px;
        height: 35px;
        border-radius: 35px;
        line-height: 33px !important;
        font-size: 0.6em;
    }

    /* The end of betting history */
    /* History old style */
    .lotto6-history-result table td {
        height: 53.7px;
    }

    .lotto6-history-result table td span {
        line-height: 30px;
        font-size: 25px;
    }

    .lotto12-history-result table td span {
        font-size: 22px;
    }

    .statistic-ball-,
    .statistic-ball-OVER,
    .statistic-ball-UNDER,
    .statistic-ball-ONE,
    .statistic-ball-TWO,
    .statistic-ball-THREE,
    .statistic-ball-FOUR,
    .statistic-ball-FIVE,
    .statistic-ball-SIX,
    .lotto12-statistic-ball-UNDER,
    .lotto12-statistic-ball-OVER,
    .statistic-ball-BLUE,
    .statistic-ball-RED,
    .statistic-ball-GREEN {
        margin: 0 auto !important;
        width: 36px;
        height: 36px;
        border-radius: 35px;
    }

    .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX, .lotto12-statistic-ball-UNDER, .lotto12-statistic-ball-OVER, .statistic-ball-BLUE, .statistic-ball-RED, .statistic-ball-GREEN {
        line-height: 36px !important;
    }

    /* Then of history old style */
    .btn-remove-betting {
        width: 42px;
        height: 29px;
        border-radius: 25px;
        padding: 1px;
    }


    /* Logo and number render */
    .lotto6-show-logo-render img {
        /*width: 166px !important;*/
        /*margin: 20px auto !important;*/
    }


    /* The end of logo and number render */
    /* The Six 36-v2 bet pricing */
    .lotto6-v2-show-logo-render img {
        width: 182px;
        margin: 7px auto;
    }

    .lotto6-v2-card-count-down-logo {
        height: 199px;
        max-height: 199px;
    }

    .lotto6-v2-show-number-draw {
        font-size: 90px;
        width: 155px;
        height: 155px;
        margin: 19px auto;
        line-height: 151px;
    }

    .lotto6-v2-result-time-countup-render {
        width: 13%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 2px;
        background: #ad0026;
        margin: 2px;
        position: absolute;
    }

    .lotto6-v2-total-result-render-count {
        color: rgb(255, 255, 255) !important;
        font-size: 90px;
        line-height: 200px;
    }

    .lotto6-v2-total-result-render-count-warning {
        color: #fce700 !important;
        font-size: 90px;
        line-height: 200px;
    }

    .lotto6-v2-total-result-render-count-total {
        font-size: 90px;
        line-height: 200px;
    }

    .lotto6-v2-dashboard-fix-height {
        height: 400px;
        overflow-y: auto;
    }

    .lotto6-v2-result table td span {
        line-height: 64px;
        font-size: 2em;
    }

    /*.lotto6-v2-bet-pricing-card {*/
    /*    height: 132.5px;*/
    /*    border: 1px solid #000;*/
    /*}*/
    .lotto6-v2-slot-prefix-card > h1 {
        line-height: 88px;
        font-size: 5em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .lotto6-v2-range-number {
        line-height: 2em;
        font-weight: 800;
        color: #000;
        font-size: 1em;
    }

    .lotto6-v2-bet-value {
        margin-top: -16px;
        font-size: 1.5em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The Six 36-v2 bet pricing */

}

@media only screen and (max-width: 1512px) {

    .dashboard-fix-height {
        max-height: 479px;
        overflow-y: auto;
    }

    /* Logo and number render */
    .lotto6-mix .lotto6-show-number-draw {
        font-size: 63px !important;
        line-height: 122px !important;
        color: #fff;
        width: 120px !important;
        height: 120px !important;
        margin: 27px auto;
        border-radius: 130px;
        display: block;
        background: linear-gradient(#ff2644 0%, #ad0026 50%, #8d0026 100%);
        box-shadow: 0px 2px 10px #8d0026;

    }

    /* The end of logo and number render */
    /* The bet pricing */
    .bet-pricing-card {
        height: 180.2px;
        max-height: 312px;
        border: 1px solid #000;
    }

    .slot-prefix-card {
        height: 60%;
        background-color: #e7e8ea;
    }

    .slot-value-card {
        height: 40%;
        background: linear-gradient(-45deg, #ffff00, #ffff00, #f9cc2f);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        color: #fff;
        border-top: 2px solid #000;
    }

    .slot-prefix-card > h1 {
        line-height: 110px;
        font-size: 6em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .card-bg-color-UNDER > h1, .card-bg-color-OVER > h1 {
        color: white;
        font-size: 4em !important;
    }

    .range-number {
        line-height: 3em;
        font-weight: 800;
        color: #000;
        font-size: 1.2em;
    }

    .bet-value {
        margin-top: -14px;
        font-size: 2em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The bet pricing */
    /* The betting history */
    .bet-item {
        line-height: 2.5em;
        width: 32px;
        height: 40.5px;
        border: 1px solid #000;
        font-size: 1em;
        font-weight: 600;
        background: linear-gradient(to bottom, #e0e1e5 0%, #f1f1f1 51%, #e0e1e5 100%);
        padding: 3px;
    }

    .lotto6-statistic-ball-UNDER, .lotto6-statistic-ball-OVER {
        margin: 4px 2px 2px 0px !important;
        width: 25px;
        height: 25px;
    }

    .lotto6-statistic-ball-ONE, .lotto6-statistic-ball-TWO, .lotto6-statistic-ball-THREE, .lotto6-statistic-ball-FOUR, .lotto6-statistic-ball-FIVE, .lotto6-statistic-ball-SIX {
        margin: 4px 2px 2px 0px !important;
        width: 26px;
        height: 26px;
        border-radius: 35px;
        line-height: 24px !important;
        font-size: 0.6em;
    }

    /* The end of betting history */
    /* History old style */
    .lotto6-history-result table td {
        height: 41.3px;
    }

    .lotto6-history-result table td span {
        line-height: 30px;
        font-size: 20px;
    }

    .lotto6-mix .lotto6-history-result table td span {
        line-height: 30px;
        font-size: 13px;
    }

    .lotto12-history-result table td span {
        font-size: 16px;
    }

    .lotto12-mix .lotto12-history-result table td span {
        font-size: 13px;
    }


    .statistic-ball-, .statistic-ball-OVER, .statistic-ball-UNDER, .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX, .lotto12-statistic-ball-UNDER, .lotto12-statistic-ball-OVER, .statistic-ball-BLUE, .statistic-ball-RED, .statistic-ball-GREEN {
        margin: 0 auto !important;
        width: 30px;
        height: 30px;
        border-radius: 35px;
    }

    .statistic-ball-ONE, .statistic-ball-TWO, .statistic-ball-THREE, .statistic-ball-FOUR, .statistic-ball-FIVE, .statistic-ball-SIX {
        line-height: 30px !important;
    }

    /* Then of history old style */
    /* The Six 36-v2 bet pricing */
    .last-result-betting {
        font-size: 15px !important;
    }

    .lotto6-v2-show-logo-render img {
        width: 134px;
        margin: 7px auto;
    }

    .lotto6-v2-card-count-down-logo {
        height: 182px;
        max-height: 124px;
    }

    .lotto6-v2-show-number-draw {
        width: 115px;
        height: 114px;
        font-size: 75px;
        line-height: 110px;
    }

    .lotto6-v2-total-result-render-count {
        color: rgb(255, 255, 255) !important;
        font-size: 75px;
        line-height: 153px;
    }

    .lotto6-v2-total-result-render-count-warning {
        color: #fce700 !important;
        font-size: 75px;
        line-height: 153px;
    }

    .lotto6-v2-total-result-render-count-total {
        font-size: 75px;
        line-height: 153px;
    }

    .lotto6-v2-dashboard-fix-height {
        height: 400px;
        overflow-y: auto;
    }

    .lotto6-v2-result table td span {
        line-height: 64px;
        font-size: 1.5em;
    }

    /*.lotto6-v2-bet-pricing-card {*/
    /*    height: 97px;*/
    /*    border: 1px solid #000;*/
    /*}*/
    /*.lotto6-mix .lotto6-v2-bet-pricing-card {*/
    /*    height: 118px;*/
    /*    border: 1px solid #000;*/
    /*}*/
    .lotto6-v2-slot-prefix-card > h1 {
        line-height: 58px;
        font-size: 5em;
        color: #db0b06;
        font-family: BattambangRegular, RobotoRegular;
    }

    .lotto6-v2-range-number {
        line-height: 2em;
        font-weight: 800;
        color: #000;
        font-size: 1em;
    }

    .lotto6-mix .lotto6-v2-range-number {
        line-height: 26px;
        font-weight: 800;
        color: #000;
        font-size: 2vw !important;
    }

    .lotto6-v2-bet-value {
        margin-top: -14px;
        font-size: 1.5em;
        color: #2621a1;
        font-weight: 800;
    }

    /* The Six 36-v2 bet pricing */

}

.lotto5-mix #period-before {
    color: white !important;
}

.lotto5-mix #timer-display {
    color: white !important;
}

.lotto6-v2-card-bg-color-UNDER {
    background: #3200cb;
    color: white !important;
}

.lotto6-v2-card-bg-color-OVER {
    background: #df0202;
    color: white !important;
}

.lotto6-v2-card-bg-color-ODD {
    background: #F16522;
    color: white !important;
}

.lotto6-v2-card-bg-color-EVEN {
    background: #FAAF40;
    color: white !important;
}


.KPW span {
    line-height: 59px !important;
}