.main-screen-iframe .h-120,
.main-screen-iframe .nav-888,
.main-screen-iframe .wrapper-new-lotto5,
.main-screen-iframe .wrapper-new-keno,
.main-screen-iframe .wrapper-game-content,
.main-screen-iframe .nav {
    min-width: unset !important;
}

.padding-l-vw {
    padding-left: 0.2vw;
    padding-right: 0;
}

.padding-r-vw {
    padding-right: 0.2vw;
    padding-left: 0;
}

.main-screen-iframe .lotto5-dispaly-result .lotto5-total {
    height: 4vw !important;
}

.main-screen-iframe .lotto6-history-result table td {
    height: 3.3vw !important;
}

.main-screen-iframe .lotto5-history-result table td {
    height: 3.3vw !important;
}

.main-screen-iframe .img-lotto5 {
    height: 4vw;
}

.main-screen-iframe .lotto5-count-down {
    height: 4vw !important;
}

.main-screen-iframe .lotto5 .loading-gif {
    height: 100% !important;
}

.main-screen-iframe .lotto5 .bet-period-timer {
    height: 4vw !important;
}

.main-screen-iframe .total-result-render-count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vw !important;
}

.main-screen-iframe .lotto5 .under-over {
    height: 4vw !important;
}

.main-screen-iframe .lotto5 .total-result-render-count-total {
    height: 4vw !important;
}

.main-screen-iframe .lotto5-content-result table td {
    height: 4vw !important;
}

.main-screen-iframe .lotto5-content-result table td span {
    height: 4vw !important;
    font-size: 1.8vw !important;
}

.main-screen-iframe .over-under-odd-even .box-bet div .type {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8vw !important;
}

.main-screen-iframe .under-over-label-wrapper {
    height: 8vw !important;
}

.main-screen-iframe .lotto5 .under-over-label {
    height: 4vw !important;
}

.main-screen-iframe .jackpot-wrapper {
    height: 5.2vw !important;
}

.main-screen-iframe .range-type .p-2 {
    padding: unset !important;
}

.main-screen-iframe .lotto5 .blgo-random-number {
    height: 16.1vw !important;
}

.main-screen-iframe .lotto5-history-result table td label {
    height: 4vw !important;
}

.main-screen-iframe .odds-v2 {
    margin-top: 0.8vw !important;
    font-size: 1.8vw !important;
}

.main-screen-iframe .range-v2 {
    font-size: 1.4vw !important;
}

.platform-999.main-screen-iframe .range-v2 {
    font-size: 1.2vw !important;
}

.main-screen-iframe .lotto5-dispaly-result .lotto5-total .label-title {
    font-size: 1.3vw !important;
}

.platform-999.main-screen-iframe .lotto5-dispaly-result .lotto5-total .label-title {
    font-size: 1vw !important;
}

.main-screen-iframe .lotto5-dispaly-result .lotto5-total .total-result {
    font-size: 1.3vw !important;
}

.platform-999.main-screen-iframe .lotto5-dispaly-result .lotto5-total .total-result {
    font-size: 1vw !important;
}

.main-screen-iframe .lotto5 .under-over {
    font-size: 1.5vw !important;
}

.main-screen-iframe .lotto5 .under-over-label {
    font-size: 1.5vw !important;
}

.main-screen-iframe .lotto5-history-result table td label {
    font-size: 1.5vw !important;
}

.main-screen-iframe .lotto5 .bet-period-timer #period-before {
    font-size: 1.3vw !important;
}

.main-screen-iframe .header-div-title > span {
    font-size: 1.5vw !important;
}

.main-screen-iframe .lotto12-mt-vw {
    /*margin-top: 6vw;*/
}

.main-screen-iframe .w-40 {
    width: 45% !important;
}

.main-screen-iframe .table-draw-result td {
    font-size: 1.2vw !important;
}

.platform-999.main-screen-iframe .table-draw-result td {
    font-size: 1vw !important;
}

.main-screen-iframe .table-draw-result td span {
    font-size: .9vw !important;
}

.main-screen-iframe .table-draw-result td b {
    font-size: 1.1vw !important;
}

.main-screen-iframe .table td {
    vertical-align: middle !important;
}

.main-screen-iframe .table-draw-result {
    height: 4vw !important;
}

.main-screen-iframe .header {
    height: 4vw !important;
}

.main-screen-iframe .lotto6-v2-card-count-down-logo {
    height: 18.22vw !important;
}

.main-screen-iframe .header-history-light {
    height: 4vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-screen-iframe .lotto6-v2-slot-prefix-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vw !important;
}

.main-screen-iframe .lotto6-v2-card-bg-color-ONE, .main-screen-iframe .lotto6-v2-card-bg-color-SIX {
    height: 15.1vw !important;
}

.main-screen-iframe .lotto6-jackpot-v2-img {
    height: 3vw !important;
}

.main-screen-iframe .lotto6-v2-slot-value-card {
    height: 3.05vw !important;
}

.main-screen-iframe .lotto6-content-result table td {
    height: 6.06vw !important;
}

.main-screen-iframe table#keno_result_post td span.active {
    height: 100% !important;
}

.main-screen-iframe .lotto12-betting .lotto12-bet-label {
    height: 9.2vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-screen-iframe .lotto12-betting .lotto12-bet-label-color {
    height: 18.6vw;
}

.main-screen-iframe .div-body-white .img-responsive {
    height: 8.2vw;
}

.main-screen-iframe .wrap-keno-content .row {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.main-screen-iframe .wrap-keno-content .col,
.main-screen-iframe .wrap-keno-content .col-3,
.main-screen-iframe .wrap-keno-content .col-6,
.main-screen-iframe .wrap-keno-content .col-8,
.main-screen-iframe .wrap-keno-content .col-9,
.main-screen-iframe .wrap-keno-content .col-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.main-screen-iframe .p-r-7 {
    padding-right: 0 !important;
}

.main-screen-iframe .lo90-v1-wap .num-circle-ticket-A, .lo90-v1-wap .num-circle-ticket-B, .lo90-v1-wap .num-circle-ticket-C, .lo90-v1-wap .num-circle-ticket-D, .lo90-v1-wap .num-circle-ticket-E {
    margin: 0 0.5vw !important;
}

.main-screen-iframe .justify-content-end {
    justify-content: center !important;
}

.main-screen-iframe .btn-wrapper button {
    width: 5vw;
    margin-right: 0.2vw;
}

.main-screen-iframe .l5d-v1-drawing {
    height: 64.4vw;
    padding: 3vw 1vw !important;
}

.main-screen-iframe .l5d-v1-drawing .time-sec-lo5d {
    line-height: 1.5vw;
}

.main-screen-iframe .bet-bottom-list-title {
    width: 7vw !important;
}

.main-screen-iframe .bet-bottom-list-item-odds {
    width: 2.5vw;
    height: 2.5vw;
    font-size: 1.5vw;
}

.main-screen-iframe .bet-bottom-list-item-title {
    font-size: 1.5vw;
}


.main-screen-iframe .l-side {
    width: 85% !important;
}

.main-screen-iframe .zodiac_border {
    height: 18vw;
}

.main-screen-iframe .bet-bottom-list-item {
    height: 5vw !important;
}

.main-screen-iframe .bet-bottom-list-title {
    font-size: 1.5vw !important;
}

.main-screen-iframe .results .list .item span {
    font-size: 1.2vw;
}

.main-screen-iframe .results .list .item .item-code {
    width: 2vw;
    height: 2vw;
    line-height: unset;
}

.main-screen-iframe .bet-bottom {
    /*padding: 2vw!important;*/
}

.main-screen-iframe .lotto12v2 .lotto12v2-h-vw .table {
    width: 25vw;
}

.main-screen-iframe .lotto12v2 .lotto12v2-h-vw .table.detail-report {
    width: 100% !important;
}

.main-screen-iframe .lo90-v1-wap .num-circle-lo90v1 {
    width: 3vw !important;
    height: 3vw !important;
    margin: 0.1vw 0.8vw !important;
    font-size: 1.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-screen-iframe .f-vw-v1 {
    font-size: 1.8vw !important;
}

.main-screen-iframe .lo90-v1-wap .post-wrapper {
    padding: 1vw 8vw !important;
}

.main-screen-iframe .lotto90 .recent-ticket {
    height: 52.5vw !important;
    overflow: hidden;
}

.main-screen-iframe .post-5d {
    margin-top: 7vw !important;
}

.main-screen-iframe .lo5d-last-result {
    max-height: unset !important;
    /*height: 100vh !important;*/
    overflow: hidden;
}

.main-screen-iframe .d-board {
    height: 40vw !important;
}

.main-screen-iframe .d-lotto12v3 .content-left-bet-code {
    width: 36vw;
    height: 31vw;
    margin-top: 0.5vw;
}

.main-screen-iframe .d-lotto12v3 {
    padding-right: 0 !important;
}

.main-screen-iframe .d-lotto12v3 .turntable .deng-img {
    /*left: 0!important;*/
}

.main-screen-iframe .d-lotto12v3 .turntable {
    margin-top: 4vw !important;
    margin-left: -3vw !important;
}

.main-screen-iframe .d-lotto12v3 .turntable .lucky-wheel {
    width: 30vw;
    height: 30vw;
}

.main-screen-iframe .d-lotto12v3 .turntable .deng-img {
    width: 25vw;
    height: 25vw;
}

.main-screen-iframe .range1 {
    width: 1.8vw;
    height: 1.8vw;
    font-size: 1vw;
}

.main-screen-iframe .column {
    height: 2.6vw;
}

.main-screen-iframe .lotto12-bet-label-color {
    font-size: 1.8vw !important;
}

.main-screen-iframe .lotto12-betting .color-items {
    padding: 0.1vw;
}

.main-screen-iframe .mr-2 {
    margin-right: unset !important;
}

.main-screen-iframe .mx-2 {
    margin-right: unset;
    margin-left: unset;
}

.main-screen-iframe .rebate-rate {
    width: 5.8vw;
    border-radius: 5px;
}

.platform-999.main-screen-iframe .rebate-rate {
    line-height: .5;
}

.main-screen-iframe .number-btn {
    margin-right: 0.3vw;
}

.main-screen-iframe .l199 .num-circle {
    font-size: 1.8vw;
    margin: 0.1vw 0.6vw !important;
    width: 3vw;
    height: 3vw;
}

.main-screen-iframe .post99-wrapper {
    padding: 1vw 6vw;
}

.full-screen-bell svg:not(:root) {
    width: 3vw;
    height: 3vw;
    margin-right: 1vw;
}

.main-screen-iframe .zodiac-img {
    width: 5vw !important;
    height: 5vw !important;
}

.main-screen-iframe .lo90-v1-drawing-board {
    padding: 3vw 1vw;
}

.main-screen-iframe .lo90-v1-drawing-board {
    height: 30vw;
    background-size: cover;
}

.main-screen-iframe .lo90-v1-wap .date-sec, .main-screen-iframe .lo90-v1-wap .ticket-sec {
    font-size: 1.2vw;
}

.main-screen-iframe .lo90-v1-drawing {
    margin-top: 3vw;
}

.main-screen-iframe .lotto90-h-vw {
    /*height: 63.46vw;*/
    overflow: hidden;
}

/*.main-screen-iframe .lotto90v1-h-vw {*/
/*    height: 45.46vw;*/
/*    overflow: scroll;*/
/*}*/

.main-screen-iframe .bet-price {
    width: 5vw;
    padding: 0.1vw;
    border: 1px solid grey;
    margin-left: .6vw;
    border-radius: 1vw;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.main-screen-iframe .btn {
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-screen-iframe .lo90-v1-l-side {
    height: 64.4vw;
    overflow-y: hidden;
}

.main-screen-iframe .lo90-v1-r-side {
    /*height: 64.4vw;*/
    overflow-y: hidden;
}

.main-screen-iframe .r-side {
    /*height: 64.4vw !important;*/
    overflow-y: hidden;
    max-height: unset !important;
}

.main-screen-iframe .lotto12v2-h-vw {
    height: 64.3vw;
}

.main-screen-iframe .bet-bottom {
    padding-top: 1.8vw !important;
}

.main-screen-iframe .w-right_lo99 {
    width: 27vw !important;
}

.main-screen-iframe .w-25-vw {
    height: 63.5vw;
}

.main-screen-iframe .l199 .r-side {
    /*height: 45.8vw !important;*/
    min-height: unset !important;
    overflow: hidden !important;
    box-shadow: unset !important;
}

.main-screen-iframe .results .list {
    overflow: hidden !important;
    /*height: unset!important;*/
}

.main-screen-iframe .lotto99 .d-board {
    height: 28vw !important;
}

.main-screen-iframe .content-left-bet {
    margin: 2vw auto;
}

.main-screen-iframe .content-left-bet .issueNo {
    margin: 2vw auto;
}

.main-screen-iframe .color1 span,
.main-screen-iframe .color2 span,
.main-screen-iframe .color3 span,
.main-screen-iframe .color4 span,
.main-screen-iframe .color5 span {
    padding: 0 3.8vw;
}

.main-screen-iframe .wrap-result-l99 {
    margin-left: -4.2vw;
}

.main-screen-iframe .rebate-rate {
    background: unset !important;
    color: white !important;
}

.main-screen-iframe .rebate-rate span {
    color: white;
    margin-top: 2vw !important;
}

.main-screen-iframe .lotto12-betting .color-items {
    top: 10vw;
}

.main-screen-iframe .lotto12-bet-label-color {
    line-height: 13vw !important;
    top: 8vw !important;
}

.main-screen-iframe .lotto12-mt-vw .pl-3,
.main-screen-iframe .lotto12-mt-vw .pr-3,
.main-screen-iframe .lotto12-mt-vw .pt-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;

}

.main-screen-iframe .btn-primary.disabled, .main-screen-iframe .btn-primary:disabled {
    cursor: not-allowed;
    background-color: #6c757d !important;
}

.main-screen-iframe .mt-8-vw {
    margin-top: 8vw;
}

.main-screen-iframe .lotto12-bet-label span {
    margin-bottom: 1.2vw;
}

.main-screen-iframe .table-recent-betting td div, .table-recent-betting td div span {
    /*font-size: 1vw!important;*/
}

.main-screen-iframe .f-22 {
    font-size: 1.8vw !important;
}

.main-screen-iframe .table-current-bet tbody td {
    font-size: 1.2vw !important;
    line-height: 24px !important;
}

.main-screen-iframe .table-current-bet tbody td span {
    font-size: 1.2vw !important;
    line-height: 24px !important;
}

.main-screen-iframe .table-recent-betting td div, .main-screen-iframe .table-recent-betting td div span {
    font-size: 1.2vw !important;
}

.main-screen-iframe .kh-font-bold, .main-screen-iframe .kh-font-bold > b {
    font-size: 1.5vw !important;
}

.main-screen-iframe .table-current-bet thead th {
    font-size: 1.2vw !important;
}

.main-screen-iframe .btn-remove-betting {
    width: 1.5vw !important;
    height: 2.5vw;
    color: red;
    background: white !important;
    border-radius: 0.1vw !important;
}

.main-screen-iframe .lotto6-v2-slot-prefix-card > h1 {
    background: unset !important;
}

.main-screen-iframe td.text-center span {
    font-size: 2vw;
}

.main-screen-iframe .w-mid_lo99 .table-wrapper {
    padding: 0.8vw;
    background-color: #F2F2F2;
}

.main-screen-iframe .lotto90-bet .num-circle {
    color: #585858;
    font-weight: bold;
}

.main-screen-iframe .my-printer-btn {
    margin-top: 0.6vw;
}

.main-screen-iframe .platform-888 .table-container .table-custom th {
    font-size: 1vw !important;
}

.main-screen-iframe .table-custom th, .main-screen-iframe .table-custom td div div {
    font-size: 1vw !important;
    line-height: 2 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
}

.main-screen-iframe .table-custom .f-result, .main-screen-iframe .table-custom td span {
    font-size: 1vw !important;
}

.main-screen-iframe .table-custom td span {
    font-size: 1vw !important;
    font-weight: bold !important;
}

.main-screen-iframe .table-custom td .btn {
    font-size: 1vw !important;
    font-weight: bold !important;
}

.main-screen-iframe .custom-checkbox .mt-1 {
    margin-top: 1vw !important;
}

.main-screen-iframe .btn-refresh-dashboard img {
    width: 1.2vw !important;
    margin-right: 1vw;
}

.platform-999.main-screen-iframe .btn-refresh-dashboard img {
    width: 2vw !important;
    margin-right: unset !important;
}

.main-screen-iframe .betting-item-card {
    left: 0 !important;
    top: 100% !important;
    opacity: 1;
}

.main-screen-iframe .draw-timer-wrap {
    height: 6vw;
}

.results .list .item_99 .list-groups {
    padding: 0.4vw 0 !important;
}


.main-screen-iframe .left-side, .main-screen-iframe .right-side {
    border: none;
}

.lo90-v1-r-side .right-side {
    border: none !important;
}

.main-screen-iframe .left-side {
    padding: 3vw !important;
}

.main-screen-iframe .table-wrapper .tb-number {
    max-width: 50vw;
}

.main-screen-iframe .lotto90 .f-15 {
    font-size: 1.2vw;
}

.p-1-vw {
    padding: 1vw !important;
}

.wrap-home-item:last-child .home-item {
    margin: 0 auto;
}

.main-screen-iframe .results .list .item {
    padding: 0.5vw 0.2vw !important;
}

.main-screen-iframe .lotto99 .f-15 {
    font-size: 1vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.platform-999.main-screen-iframe .last-result-betting {
    line-height: 4vw !important;
}